import React, { useReducer, useEffect } from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";
import ModuleDynamicFilterDropdown from "../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown";
import {
  detailsInitialState,
  detailsReducer,
} from "../../reducers/detailsReducer";
import axios from "../../../../../config/axios";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const languagePlaceholder = [{ label: "Select Language", value: "" }];
  const authorPlaceholder = [{ label: "Select Author", value: "" }];
  const yearPlaceholder = [{ label: "Select Year", value: "" }];
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(`publications/available-filters`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      console.log(error);
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i], value: item[i] });
    }

    return array;
  };
  return (
    <>
      {(appliedFilters.limit ||
        appliedFilters.q ||
        appliedFilters.status ||
        appliedFilters.language ||
        appliedFilters.year ||
        appliedFilters.author ||
        appliedFilters.relevant) && (
        <div div className=" text-right ">
          <span className="resetFilters" onClick={() => resetFilters()}>
            <i className="fas fa-times" /> Reset Filters
          </span>
        </div>
      )}
      {detailsState.data &&
        detailsState.data.availableFilters &&
        detailsState.data.availableFilters.languages &&
        detailsState.data.availableFilters.authors &&
        detailsState.data.availableFilters.years && (
          <div className="position-relative">
            <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
              <Card.Body className=" p-0 m-0">
                <Row className="mb-0">
                  {moduleConfig.hasPagination === true &&
                    moduleConfig.perPageItemsOptions &&
                    moduleConfig.perPageItemsOptions.length > 0 && (
                      <Col className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                        <ModuleRecordsPerPageDropdown
                          selectedPerPageItems={moduleConfig.perPageItems}
                          perPageItemsOptions={moduleConfig.perPageItemsOptions}
                          setFilter={setFilter}
                        />
                      </Col>
                    )}
                  <Col className="col-xl-3 col-lg-3 col-md-3  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="relevant"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={[
                        {
                          label: "Show Relevant",
                          value: "",
                        },
                        {
                          label: "Yes",
                          value: "1",
                        },
                        {
                          label: "No",
                          value: "0",
                        },
                      ]}
                    />
                  </Col>
                  <Col className="col-xl-3 col-lg-3 col-md-3  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="language"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={languagePlaceholder.concat(
                        handleFormatArray(
                          detailsState.data.availableFilters.languages
                        )
                      )}
                    />
                  </Col>{" "}
                  <Col className="col-xl-3 col-lg-3 col-md-3  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="author"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={authorPlaceholder.concat(
                        handleFormatArray(
                          detailsState.data.availableFilters.authors
                        )
                      )}
                    />
                  </Col>
                  <Col className="col-xl-3 col-lg-3 col-md-3  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="year"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={yearPlaceholder.concat(
                        handleFormatArray(
                          detailsState.data.availableFilters.years
                        )
                      )}
                    />
                  </Col>
                  {moduleConfig.hasStatusSearch === true && (
                    <Col className="col-xl-3 col-lg-3 col-md-3  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                      <ModuleStatusDropdown
                        setFilter={setFilter}
                        appliedFilters={appliedFilters}
                        statusOptions={[
                          {
                            label: "Select Status...",
                            value: "all",
                          },
                          {
                            label: "Enabled",
                            value: "1",
                          },
                          {
                            label: "Disabled",
                            value: "0",
                          },
                        ]}
                      />
                    </Col>
                  )}
                  {moduleConfig.hasSearch === true && (
                    <Col className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                      <ModuleSearchBox
                        setFilter={setFilter}
                        appliedFilters={appliedFilters}
                      />
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </div>
        )}
    </>
  );
};

export default SearchArea;
