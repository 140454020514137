import React from "react";
import { Link } from "react-router-dom";
import { Card, Table, Badge } from "react-bootstrap";
import moment from "moment";
import MailTo from "../../mix/MailTo";
import SendEmail from "../../mix/SendEmail";
import { isEmpty } from "../../../../helpers/CommonHelper";
import ModuleDisplayFieldsController from "../fieldsDisplay/ModuleDisplayFieldsController";

//import ReactFancyBox from 'react-fancybox';
//import Lightbox from 'react-image-lightbox';

const ModuleDetailsViewInTableStyle = ({ item, moduleConfig }) => {
  console.log(item);

  return (
    <section className="content">
      <Card>
        <Card.Body className="p-1">
          <Table striped bordered className="m-0">
            <tbody>
              {moduleConfig.fields &&
                moduleConfig.fields.length > 0 &&
                moduleConfig.fields.map(
                  (
                    {
                      displayName,
                      fieldName,
                      type,
                      hideOnDetails,
                      displayStyle,
                      trueValue,
                      falseValue,
                    },
                    index
                  ) => {
                    return (
                      !hideOnDetails && (
                        <tr key={index}>
                          <td>{displayName}</td>
                          <td>
                            <ModuleDisplayFieldsController
                              type={type}
                              displayStyle={displayStyle}
                              value={item[fieldName]}
                              trueValue={trueValue}
                              falseValue={falseValue}
                            />
                          </td>
                        </tr>
                      )
                    );
                  }
                )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </section>
  );
};

export default ModuleDetailsViewInTableStyle;
