import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Accordion } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import axios from "../../../config/axios";

import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";

import StatsListing from "../shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import ModuleDisplayDate from "../modules/fieldsDisplay/ModuleDisplayDate";
function FossilContent({ setParent, parent }) {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    setParent(true);
    try {
      const res = await axios.get(`fossils/stats-for-dashboard`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
      setParent(false);
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setParent(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);
  const getItems = (item) => {
    let items = [];
    if (item.saleableOptions && item.saleableOptions.code) {
      items = [
        ...items,
        { text: "Item Code", value: item.saleableOptions.code, type: "text" },
      ];
    }
    // if (item.saleableOptions && item.saleableOptions.price) {
    //   items = [...items, { text: "Price", value: item.saleableOptions.price, type: "text" }];
    // }
    if (item.saleableOptions && item.saleableOptions.oldPrice) {
      items = [
        ...items,
        {
          text: "Old Price",
          value: item.saleableOptions.oldPrice + " " + getCurrencyCode(),
          type: "text",
        },
      ];
    }

    if (item.category) {
      items = [
        ...items,
        { text: "Category", value: item.category, type: "text" },
      ];
    }
    if (item.subCategory) {
      items = [
        ...items,
        { text: "Sub Category", value: item.subCategory, type: "text" },
      ];
    }
    if (item.ageOrOriginDate) {
      items = [
        ...items,
        {
          text: "Age / Origin Date",
          value: item.ageOrOriginDate,
          type: "text",
        },
      ];
    }
    if (item.site) {
      items = [...items, { text: "Site", value: item.site, type: "text" }];
    }

    if (item.dimension) {
      items = [
        ...items,
        { text: "Dimension", value: item.dimension, type: "text" },
      ];
    }
    if (item.relatedMaterial) {
      items = [
        ...items,
        {
          text: "Related Material",
          value: item.relatedMaterial,
          type: "link",
          download: true,
          title: "Please click to download",
        },
      ];
    }
    return items;
  };

  return (
    <>
      <Row className="my-2">
        <Col>
          {/* {listingState.isFetching === true && <FullPageOverlay />}
          {console.log(listingState.data)} */}
          {listingState.hasFetchingError && (
            <Alert variant="warning mb-0 mt-1">
              OOPS!!! Something went wrong, please try again later.
            </Alert>
          )}
          {listingState.data && listingState.data.summary && (
            <>
              <Row className="my-2">
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  
                    <div class="info-box bg-gradient-pink">
                      <span class="info-box-icon">
                        <i class="fa fa-shopping-cart"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Total Fossils</span>
                        <span class="info-box-number">
                          {listingState.data.summary.total}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                 
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a
                    href="/fossils-collection"
                    title="Please click to view fossils collection"
                  >
                    <div class="info-box bg-gradient-teal">
                      <span class="info-box-icon">
                        <i class="fas fa-clock" aria-hidden="true"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Collection Fossils</span>
                        <span class="info-box-number">
                          {listingState.data.summary.collection}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a href="/fossils-saleable">
                    <div
                      class="info-box bg-gradient-info"
                      title="Please click to view saleable fossils"
                    >
                      <span class="info-box-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Saleable Fossils</span>
                        <span class="info-box-number">
                          {listingState.data.summary.Saleable}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>

              <Row className="mt-2 mb-3">
                <Col>
                  <h5 className="  text-bold">Recently Added Fossils</h5>
                </Col>
                {/* {listingState.data &&
                  listingState.data.summary &&
                  listingState.data.summary.total > 0 && (
                    <Col>
                      <Button
                        title="Please click to view all orders"
                        variant="primary"
                        className="float-end"
                        href="/fossils"
                        size="sm"
                      >
                        View all
                      </Button>
                    </Col>
                  )} */}
              </Row>
            </>
          )}
          <Row>
            <Col>
              {listingState.data.items &&
                listingState.data.items.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1">No records found.</Alert>
                )}
              {listingState.data.items &&
                listingState.data.items.length > 0 && (
                  <>
                    {listingState.data.items.map((item, index) => {
                      return (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey={index == 0 ? "0" : index}
                            key={item._id}
                          >
                            <Accordion.Header className="accordionBorder">
                              <div className="w-100 dashboardCart">
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className=" position-relative  ">
                                    <div className="fossilImage">
                                      <ReactFancyBox
                                        thumbnail={
                                          process.env.REACT_APP_BACKEND_URL +
                                          "public/" +
                                          item.image
                                        }
                                        image={
                                          process.env.REACT_APP_BACKEND_URL +
                                          "public/" +
                                          item.image
                                        }
                                        caption={`${item.genus} fossil`}
                                      />
                                    </div>
                                    {(item.status === "0" ||
                                      item.status === 0) && (
                                      <i className="fas fa-ban position-absolute ms-1 mb-2 banned2"></i>
                                    )}
                                  </div>
                                  <div className="ms-3">
                                    {item.type === "Saleable" ? (
                                      <p className="mb-1 text-bold ">
                                        <a
                                          title="Please click to view details"
                                          href={`/fossils-saleable/${item._id}`}
                                          className="hoveredText text-red"
                                        >
                                          {item.genus}
                                        </a>
                                      </p>
                                    ) : (
                                      <p className="mb-1 text-bold ">
                                        <a
                                          title="Please click to view details"
                                          href={`/fossils-collection/${item._id}`}
                                          className="hoveredText text-red"
                                        >
                                          {item.genus}
                                        </a>
                                      </p>
                                    )}

                                    <i className="mb-1 text-dark ">
                                      {item.type}
                                    </i>
                                  </div>
                                </div>
                                <div className="my-2 my-xl-0 my-lg-0 my-md-0">
                                  {item.collectionOptions && (
                                    <>
                                      {item.collectionOptions.museumQuality ===
                                        "1" ||
                                      item.collectionOptions.museumQuality ===
                                        1 ? (
                                        <span className="badge limeYellow me-1 ">
                                          Museum Quality
                                        </span>
                                      ) : null}
                                      {item.collectionOptions.collectible ===
                                        "1" ||
                                      item.collectionOptions.collectible ===
                                        1 ? (
                                        <span className="badge orange me-1 ">
                                          Collectible
                                        </span>
                                      ) : null}
                                      {item.collectionOptions
                                        .excellentPreservation === "1" ||
                                      item.collectionOptions
                                        .excellentPreservation === 1 ? (
                                        <span className="badge peach me-1 ">
                                          Excellent Preservation
                                        </span>
                                      ) : null}
                                      {item.collectionOptions
                                        .exceptionalRarity === "1" ||
                                      item.collectionOptions
                                        .exceptionalRarity === 1 ? (
                                        <span className="badge brown me-1 ">
                                          Exceptional Rarity
                                        </span>
                                      ) : null}
                                      {item.collectionOptions
                                        .fossilOfTheMonth === "1" ||
                                      item.collectionOptions
                                        .fossilOfTheMonth === 1 ? (
                                        <span className="badge magenda me-1 ">
                                          Fossil Of The Month
                                        </span>
                                      ) : null}
                                      {item.collectionOptions.newDiscovery ===
                                        "1" ||
                                      item.collectionOptions.newDiscovery ===
                                        1 ? (
                                        <span className="badge badge-info me-1 ">
                                          New Discovery{" "}
                                        </span>
                                      ) : null}
                                      {item.collectionOptions.newSpecies ===
                                        "1" ||
                                      item.collectionOptions.newSpecies ===
                                        1 ? (
                                        <span className="badge skyBlue me-1 ">
                                          New Species
                                        </span>
                                      ) : null}
                                    </>
                                  )}

                                  {item.saleableOptions && (
                                    <>
                                      {item.saleableOptions.exclusiveItem ===
                                        "1" ||
                                      item.saleableOptions.exclusiveItem ===
                                        1 ? (
                                        <span className="badge badge-info me-1">
                                          Exclusive Item
                                        </span>
                                      ) : null}
                                      {item.saleableOptions.discounted ===
                                        "1" ||
                                      item.saleableOptions.discounted === 1 ? (
                                        <span className="badge teal me-1 ">
                                          Discounted
                                        </span>
                                      ) : null}
                                      {item.saleableOptions.showOnHomePage ===
                                        "1" ||
                                      item.saleableOptions.showOnHomePage ===
                                        1 ? (
                                        <span className="badge homePageShow me-1 ">
                                          Showing on Home Page
                                        </span>
                                      ) : null}
                                      {item.saleableOptions.soldOut === "1" ||
                                      item.saleableOptions.soldOut === 1 ? (
                                        <span className="badge badge-dark me-1">
                                          Sold Out
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                  {(item.status === "0" ||
                                    item.status === 0) && (
                                    <span className="badge badge-warning me-1 text-white">
                                      Disabled
                                    </span>
                                  )}
                                  {(item.status === "1" ||
                                    item.status === 1) && (
                                    <span className="badge badge-success me-1 text-white">
                                      Enabled
                                    </span>
                                  )}
                                  {item.createdAt && (
                                    <span class="badge badge-light me-1 mt-1 text-dark">
                                      Created Date :{" "}
                                      <span class="text-secondary">
                                        <ModuleDisplayDate
                                          value={item.createdAt}
                                        />{" "}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              <StatsListing items={getItems(item)} />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    })}
                  </>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default FossilContent;
