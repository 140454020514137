import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ContentSelectionMenu from "./ContentSelectionMenu";
import { ContainerLoading } from "../loading/ContainerLoading";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";

function DashboardContent() {
  const [loading, setLoading] = useState(true);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([]);
  }, []);
  return (
    <>
      {loading === true && <ContainerLoading />}

      <Row className="">
        <Col>
          <Card className="mt-2">
            <Card.Body className="p-1">
              <ContentSelectionMenu
                activeKey="Orders"
                setParent={setLoading}
                parent={loading}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardContent;
