import React, { useState, useReducer } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import {
  formInitialState,
  formReducer,
} from "../../../../../reducers/formReducer";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormTextArea from "../../../../UI/forms/CMSFormTextArea";
import { Button } from "react-bootstrap";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import axios from "../../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import CMSFormCheckBoxes from "../../../../UI/forms/CMSFormCheckBoxes";
import { getFullName } from "../../../../../helpers/CommonHelper";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";

const PaymentAcceptModal = ({
  paymentId,
  buyerId,
  show,
  setShow,
  loadItem,
  item,
}) => {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`payments/accept-payment`, data);
      formDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      handleClose();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Action done successfully.`,
        type: "success",
      });
      resetForm();
      formDispatch({
        type: "FORM_INITIAL_STAGE",
      });
      loadItem();
    } catch (error) {
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });

      setTimeout(() => {
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  const getTenureInitialValueForForm = () => {
    if (item.purpose === "Signup") {
      let tenureArray = item.tenure.split("-");
      let array = [];
      if (tenureArray[0] === tenureArray[1]) {
        //single year

        array.push(tenureArray[0]);
        return array;
      } else {
        array.push(tenureArray[0]);
        array.push(tenureArray[1]);
        return array;
      }
    } else {
      return [];
    }
  };

  const getTenureFieldOptionsForForm = () => {
    if (item.purpose === "Signup") {
      let tenureArray = item.tenure.split("-");
      //console.log(tenureArray)

      if (tenureArray[0] === tenureArray[1]) {
        //single year
        return [{ label: tenureArray[0], value: tenureArray[0] }];
      } else {
        return [
          { label: tenureArray[0], value: tenureArray[0] },
          { label: tenureArray[1], value: tenureArray[1] },
        ];
      }
    } else {
      return [];
    }
  };

  const getValidationSchema = () => {
    return yup.object({
      comments: yup.string().nullable().required("Required").max(200),
      method: yup.string().nullable().required("Required"),
      paidAmount: yup
        .number()
        .transform((cv) => (isNaN(cv) ? undefined : cv))
        .positive()
        .integer()
        .required("Actual paid amount is required."),
    });
  };

  const getInitialValues = () => {
    let returnObj = {
      id: item.payment[0]._id,
      buyerId,
      buyer: item.buyer.fullName
        ? item.buyer.fullName
        : getFullName(item.buyer),
      orderId: item._id,
      amount: item.totalAmount,
      comments: item.adminComments,
      method: item.method,
      paidAmount: item.payment[0].paidAmount
        ? item.payment[0].paidAmount
        : item.totalAmount,
    };

    return returnObj;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={getValidationSchema()}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { resetForm }) => {
   
        onSubmit(values, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose}>
          {formState.isFormSubmitting && (
            <div class="overlay">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}
         
          {/* {(formState.isFormSubmitting) && <ContainerLoading />} */}
          <Form onSubmit={form.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Accept Payment</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {formState.hasSubmissionError && (
                <FormErrorMessage error={formState.formSubmissionError} />
              )}

              <CMSFormInput
                name="buyer"
                label="Buyer"
                required={false}
                form={form}
                disabled={true}
              />

              <CMSFormInput
                name="orderId"
                label="OrderID"
                required={false}
                form={form}
                disabled={true}
              />

              <CMSFormInput
                name="amount"
                label="Total Amount"
                required={false}
                form={form}
                disabled={true}
              />
              <CMSFormSelect
                name="method"
                label="Payment Method"
                required={true}
                form={form}
                options={[
                  { value: "Online", label: "Online" },
                  { value: "Bank Transfer", label: "Bank Transfer" },
                  { value: "Exchange Transfer", label: "Exchange Transfer" },
                  { value: "COD", label: "Cash on Delivery" },
                  { value: "CIS", label: "Cash in Shop" },
                ]}
              />
              <CMSFormInput
                name="paidAmount"
                label={item.status === "1" ? "Paid Amount" : "Payable Amount"}
                required={true}
                form={form}
                type="number"
              />

              <CMSFormTextArea
                name="comments"
                label="Comments"
                form={form}
                required={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default PaymentAcceptModal;
