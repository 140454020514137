import React from "react";
import ModuleDisplayArray from "./ModuleDisplayArray";
import ModuleDisplayBoolean from "./ModuleDisplayBoolean";

import ModuleDisplayEmail from "./ModuleDisplayEmail";
import ModuleDisplayImage from "./ModuleDisplayImage";
import ModuleDisplayPassword from "./ModuleDisplayPassword";
import ModuleDisplayPhone from "./ModuleDisplayPhone";
import ModuleDisplayRichText from "./ModuleDisplayRichText";
import ModuleDisplayText from "./ModuleDisplayText";
import ModuleDisplayURL from "./ModuleDisplayURL";
import ModuleDisplayDateTime from "./ModuleDisplayDateTime";

const ModuleDisplayFieldsController = (props) => {
  const {
    type,
    displayStyle,
    value,
    trueValue,
    falseValue,
    trueColor,
    falseColor,
  } = props;
  switch (type) {
    case "text":
    case "number":
    case "date":
      if (displayStyle === "phone") {
        return <ModuleDisplayPhone value={value} />;
      } else {
        return <ModuleDisplayText value={value} />;
      }
    case "dateTime":
      return <ModuleDisplayDateTime value={value} />;

    case "boolean":
      return (
        <ModuleDisplayBoolean
          value={value}
          trueValue={trueValue}
          falseValue={falseValue}
          trueColor={trueColor}
          falseColor={falseColor}
        />
      );

    case "url":
      return <ModuleDisplayURL value={value} />;

    case "email":
      return <ModuleDisplayEmail value={value} />;

    case "password":
      return <ModuleDisplayPassword value={value} />;

    case "array":
      return <ModuleDisplayArray value={value} />;

    case "image":
      return <ModuleDisplayImage value={value} />;

    case "richText":
      return <ModuleDisplayRichText value={value} />;

    default:
      return null;
  }
};

export default ModuleDisplayFieldsController;
