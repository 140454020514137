import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";

const PressReleasesUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-languages`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data.languages, "11111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);
  let imageRequirement = {
    allowedExtensions: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    size: 1024 * 1024 * 3,
  };
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            detailsState.data &&
            detailsState.data.languages &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup
                        .object({
                          title: yup.string().required("Required").max(70),
                          date: yup.string().required("Required"),
                          author: yup.string(),
                          source: yup.string().required("Required").max(50),
                          // topic: yup.string().max(25),
                          language: yup.string().required("Required").max(220),
                          filePagesCount: yup.string().when("file", {
                            is: (file) => file,
                            then: yup.string().required("Required"),
                          }),
                          externalLinkTitle: yup.string().when("externalLink", {
                            is: (externalLink) => externalLink,
                            then: yup.string().required("Required"),
                          }),
                          relevant: yup.string().required(),
                          status: yup.string().required("Required"),
                        })
                        .shape(
                          {
                            file: yup.mixed().when("externalLink", {
                              is: "",
                              then: yup
                                .mixed()
                                .required("Required")
                                .imageTypeCheck(
                                  imageRequirement.allowedExtensions
                                )
                                .imageSizeCheck(imageRequirement.size),
                            }),
                            externalLink: yup
                              .string()
                              .ensure()
                              .when("file", {
                                is: (file) => !file,
                                then: yup.string().url().required(),
                              }),
                          },
                          [["externalLink", "file"]]
                        )}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { resetForm }) => {
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        onSubmit(formData);
                        console.log(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {console.log(form.errors)}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <CMSFormInput
                            name="title"
                            label="Title"
                            required={true}
                            form={form}
                          />
                          <CMSFormDate
                            name="date"
                            label="Date"
                            minDate={new Date("2000-1-1")}
                            maxDate={new Date("2040-12-28")}
                            value={new Date(form.values["date"])}
                            form={form}
                            required={true}
                          />

                          <CMSFormInput
                            name="author"
                            label="Author"
                            form={form}
                          />
                          <CMSFormInput
                            name="source"
                            label="Source"
                            required={true}
                            form={form}
                          />
                          {/* <CMSFormInput
                            name="topic"
                            label="Topic"
                            form={form}
                          /> */}

                          <CMSFormSelect
                            name="language"
                            label="Language"
                            required={true}
                            form={form}
                            options={
                              detailsState.data && detailsState.data.languages
                            }
                          />
                          <CMSFormFile
                            name="file"
                            label="File"
                            required={form.values.externalLink ? false : true}
                            form={form}
                          />
                          {form.values.file && (
                            <CMSFormInput
                              name="filePagesCount"
                              label="File Pages Count"
                              required={true}
                              form={form}
                            />
                          )}
                          <CMSFormInput
                            name="externalLink"
                            label="External Link"
                            required={form.values.file ? false : true}
                            form={form}
                          />

                          <CMSFormInput
                            name="externalLinkTitle"
                            label="External Link Title"
                            required={
                              form.values.externalLink &&
                              form.values.externalLink.length > 1
                                ? true
                                : false
                            }
                            form={form}
                          />

                          {console.log(form.values)}
                          <CMSFormSelect
                            name="relevant"
                            label="Relevant ?"
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { label: "Select Status...", value: "" },
                              { label: "Enabled", value: "1" },
                              { label: "Disabled", value: "0" },
                            ]}
                          />
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default PressReleasesUpdate;
