import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Carts';
moduleConfig.singleItemTitle = 'Cart';
moduleConfig.url = 'carts';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};


moduleConfig.bulkActions = [
    {
        displayName: "Archive Selected",
        value: "Archive"
    },
    {
        displayName: "Restore Selected",
        value: "Restore"
    }
];


export default moduleConfig;