import React from 'react';
import { Card } from 'react-bootstrap';
import { Formik, Form } from "formik"

import FormErrorMessage from '../../errorMessages/FormErrorMessage';
import CMSFormController from '../../forms/CMSFormController';

const ModuleUpdateView = ({ loading, fields, initialValues, validationSchema, onSubmit, formSubmissionError, moduleConfig }) => {

    //console.log("Error in ModuleUpdateView =>>>> ", formSubmissionError);
    return <section className="content">
        <Card>
            <Card.Body >

                {loading.isSubmitting && (
                    <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={async (values) => {

                        if (moduleConfig.hasFile === true) {
                            const formData = new FormData();
                            for (let name in values) {
                                if (Array.isArray(values[name])) {
                                    formData.append(name, JSON.stringify(values[name]));
                                } else {
                                    formData.append(name, values[name]);
                                }
                            }
                            if (formData.image) {
                                formData.image = formData.image === "null" ? null : formData.image;
                            }

                            onSubmit(formData)
                        } else {
                            onSubmit(values)
                        }

                    }}
                >
                    {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                            {console.log(form.values)}
                            {(formSubmissionError) &&
                                <FormErrorMessage error={formSubmissionError} />
                            }

                            {
                                (fields && fields.length > 0) &&
                                <>
                                    {fields.map(({ displayName, fieldName, type, control, options, hideOnUpdate, required, ...rest }, index) => {

                                        return (
                                            (!hideOnUpdate)
                                            && <CMSFormController
                                                key={index}
                                                control={control}
                                                type={type}
                                                required={required}
                                                label={displayName}
                                                name={fieldName}
                                                form={form}
                                                options={options}
                                                {...rest}
                                            />
                                        )
                                    })}
                                </>
                            }



                            <button className="btn btn-primary" type="submit">Submit</button>
                        </Form>
                    )}
                </Formik>




            </Card.Body>
        </Card>
    </section>;
};

export default ModuleUpdateView;
