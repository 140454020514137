import React from "react";
import { Tabs, Tab, Alert } from "react-bootstrap";


import ContactUsContent from "./ContactUsContent";


import PaymentContent from "./PaymentContent";
import { ContainerLoading } from "../loading/ContainerLoading";

import SubscribersContent from "./SubscribersContent";
import TestimonialContent from "./TestimonialContent";
import CartContent from "./CartContent";
import OrderContent from "./OrderContent";
import FossilContent from "./FossilContent";
function ContentSelectionMenu({ activeKey, setParent, parent }) {
  return (
    <>
      <Tabs defaultActiveKey={activeKey} id="uncontrolled-tab-example">
        <Tab eventKey="Orders" title="Orders" className="p-2">
          <OrderContent setParent={setParent} parent={parent} />
        </Tab>
        <Tab eventKey="Carts" title="Carts" className="p-2">
          <CartContent setParent={setParent} parent={parent} />
        </Tab>
        <Tab eventKey="Fossils" title="Fossils" className="p-2">
          <FossilContent setParent={setParent} parent={parent} />
        </Tab>
        <Tab eventKey="Payment" title="Payments" className="p-2">
          <PaymentContent setParent={setParent} parent={parent} />
        </Tab>
        <Tab eventKey="Newsletter" title="Newsletter" className="p-2">
          <SubscribersContent setParent={setParent} parent={parent} />
        </Tab>
        <Tab eventKey="Testimonials" title="Testimonials" className="p-2">
          <TestimonialContent setParent={setParent} parent={parent} />
        </Tab>
        <Tab eventKey="Contact Us" title="Contact Us" className="p-2">
          <ContactUsContent setParent={setParent} parent={parent} />
        </Tab>
      </Tabs>
    </>
  );
}

export default ContentSelectionMenu;
