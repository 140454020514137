import React from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayRichText from "../../../../UI/modules/fieldsDisplay/ModuleDisplayRichText";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import parse from "html-react-parser";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import { getFullName } from "../../../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getBuyer = () => {
    let items = [];

    if (item.buyer.fullName) {
      items = [
        ...items,
        {
          title: "Full Name",
          value: item.buyer.fullName,
          type: "text",
          url: `/buyers/${item.buyerId}`,
        },
      ];
    }

    return items;
  };
  const getBuyerEmail = () => {
    let items = [];
    if (item.buyer && item.buyer.email) {
      items = [
        ...items,
        { text: "Email", value: item.buyer.email, type: "email" },
      ];
    }

    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      <i className="fa fa-shopping-cart position-relative cartIcon "></i>
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-center justify-content-center ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {" "}
                      {item.cartItems.length} Item(s) in cart
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools endColumn">
                <div className=" text-right  mt-xl-2 mt-lg-2 mt-md-2">
                  {/* {(item.showOnHomePage === "1" ||
                    item.showOnHomePage === 1) && (
                    <span className="badge homePageShow me-1 text-white">
                      Showing on Home Page
                    </span>
                  )}
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span className="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span> */}
                  {(item.paymentFailed === "1" || item.paymentFailed === 1) && (
                    <span className="badge red me-1 text-white">
                      Online Payment Failed
                    </span>
                  )}
                  {item.paymentMethod && (
                    <span className="badge badge-success me-1 text-white">
                      Online Payment Selected
                    </span>
                  )}
                  {item.createdAt && (
                    <span className="badge badge-light me-1 text-dark">
                      Created Date :{" "}
                      <span className="text-secondary">
                        <ModuleDisplayDate value={item.createdAt} />{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              {/* <div className="d-flex mb-2">
                <StatsListing
                  heading="Buyer"
                  clickable={true}
                  items={getBuyer()}
                />
                <StatsListing items={getBuyerEmail()} />
              </div> */}
              <Row>
                <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                  <div className="info-box bgCream">
                    <span className="info-box-icon">
                      <Link
                        to={`/buyers/${item.buyerId}`}
                        title="Please click to view buyer details"
                      >
                        <i className="fas fa-user text-dark"></i>
                      </Link>
                    </span>

                    <div className="info-box-content">
                      <Link
                        to={`/buyers/${item.buyerId}`}
                        title="Please click to view buyer details"
                      >
                        <span className="info-box-number text-dark">Buyer</span>
                      </Link>
                      <div className="progress">
                        <div className="progress-bar"></div>
                      </div>
                      {console.log(item.buyer)}
                      <span className="info-box-text">
                        {" "}
                        <Link
                          to={`/buyers/${item.buyerId}`}
                          title="Please click to view buyer details"
                          className="text-red text-bold mt-1"
                        >
                          <span>{item.buyer && item.buyer.fullName}</span>
                        </Link>
                      </span>
                      <i className="info-box-text ">
                        {" "}
                        <ModuleDisplayEmail
                          value={item.buyer && item.buyer.email}
                          text="dark"
                        />
                      </i>
                      <span className="info-box-text">
                        {" "}
                        <ModuleDisplayPhone
                          value={item.buyer && item.buyer.phone}
                          text="dark"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              {item.cartItems && item.cartItems.length > 0 && (
                <h6 className="text-bold ms-1 mb-3 mt-2">
                  Item(s) added in the cart
                </h6>
              )}

              <Row>
                {item.cartItems &&
                  item.cartItems.length > 0 &&
                  item.cartItems.map((item, index) => {
                    return (
                      <Col xl={4} lg={6} md={6} sm={6} xs={12}>
                        <div className="info-box borderCream">
                          <span className="info-box-icon">
                            <div className="smallImage">
                              <ReactFancyBox
                                thumbnail={
                                  process.env.REACT_APP_BACKEND_URL +
                                  "public/" +
                                  item.image
                                }
                                image={
                                  process.env.REACT_APP_BACKEND_URL +
                                  "public/" +
                                  item.image
                                }
                                caption={item.genus}
                              />
                            </div>
                          </span>
                          <div className="info-box-content ps-4">
                            <Link
                              to={`/fossils-saleable/${item._id}`}
                              title="Please click to view fossil details"
                            >
                              <h6 className="info-box-number mb-0 text-red">
                                {item.genus}
                              </h6>
                            </Link>

                            <span className="info-box-text">
                              {item.category}
                            </span>
                            <i className="info-box-text text-primaryCream">
                              {item.subCategory}
                            </i>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
