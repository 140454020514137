import React, { useReducer, useEffect, useContext } from "react";

import { Alert, Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Store as toast } from "react-notifications-component";
import DashboardLayout from "../../../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import moduleConfig from "../../moduleConfig";
import ModuleHeader from "../../../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../../../config/axios";
import { createInitialState, createReducer } from "../../reducers/createReducer";
import { BreadcrumbContext } from "../../../../../contexts/BreadcrumbContext";
import { toastNotification } from "../../../../../config/toastNotification";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const AddCollectionDetails = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  let { id } = useParams();
  const onSubmit = async (data) => {
    //console.log(data);

    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `${moduleConfig.url}/collection-attributes/${id}`,
        data
      ); //
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Collection details added successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const handleCount = (values) => {
    var count = 0;
    console.log(values.collectible === "1" ? "yes" : "no");
    if (values.collectible === "1") {
      count = count + 1;
    }
    if (values.excellentPreservation && values.excellentPreservation === "1") {
      count = count + 1;
    }

    if (values.exceptionalRarity === "1") {
      count = count + 1;
    }

    if (values.fossilOfTheMonth === "1") {
      count = count + 1;
    }
    if (values.museumQuality === "1") {
      count = count + 1;
    }
    if (values.newDiscovery === "1") {
      count = count + 1;
    }
    if (values.newSpecies === "1") {
      count = count + 1;
    }

    return count;
  };

  useEffect(() => {
    ///  loadData()
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);

    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);

  return (
    <DashboardLayout>
       {console.log(createState.data)}
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {createState.status !== "fetching" &&(
              <>
                <ModuleHeader
                  moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                  moduleUrl={moduleConfig.url}
                ></ModuleHeader>

                <section className="content">
                  <Card>
                    <Card.Body>
                      {createState.isLoading && (
                        <div className="overlay position-absolute containerLoading">
                          <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                      )}
                      <Formik
                        initialValues={{
                          collectible: "0",
                          excellentPreservation: "0",
                          exceptionalRarity: "0",
                          fossilOfTheMonth: "0",
                          museumQuality: "0",
                          newDiscovery: "0",
                          newSpecies: "0",
                        }}
                        validationSchema={yup.object({
                          collectible: yup.string(),
                          excellentPreservation: yup.string(),
                          exceptionalRarity: yup.string(),
                          fossilOfTheMonth: yup.string(),
                          museumQuality: yup.string(),
                          newDiscovery: yup.string(),
                          newSpecies: yup.string(),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values, { resetForm }) => {
                          onSubmit(values);
                          console.log(values);
                        }}
                      >
                        {(form) => (
                          <Form onSubmit={form.handleSubmit}>
                            {createState.error && (
                              <FormErrorMessage error={createState.error} />
                            )}
                        
                            <CMSFormSelect
                              name="collectible"
                              label="Collectible"
                              form={form}
                              disabled={
                                form.values.collectible === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />
                            <CMSFormSelect
                              name="excellentPreservation"
                              label="Excellent Preservation"
                              disabled={
                                form.values.excellentPreservation === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              form={form}
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />

                            <CMSFormSelect
                              name="exceptionalRarity"
                              label="Exceptional Rarity"
                              form={form}
                              disabled={
                                form.values.exceptionalRarity === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />
                            <CMSFormSelect
                              name="fossilOfTheMonth"
                              label="Fossil Of The Month"
                              disabled={
                                form.values.fossilOfTheMonth === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              form={form}
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />
                            <CMSFormSelect
                              name="museumQuality"
                              label="Museum Quality"
                              disabled={
                                form.values.museumQuality === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              form={form}
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />
                            <CMSFormSelect
                              name="newDiscovery"
                              label="New Discovery"
                              disabled={
                                form.values.newDiscovery === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              form={form}
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />

                            <CMSFormSelect
                              name="newSpecies"
                              label="New Species"
                              disabled={
                                form.values.newSpecies === "0" &&
                                handleCount(form.values) >= 2
                                  ? true
                                  : false
                              }
                              form={form}
                              options={[
                                { label: "Yes", value: "1" },
                                { label: "No", value: "0" },
                              ]}
                            />

                            <button
                              type="submit"
                              onClick={() => form.handleSubmit}
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </section>
              </>
            )}
        </>
      )}
    </DashboardLayout>
  );
};

export default AddCollectionDetails;
