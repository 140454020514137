import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Banners';
moduleConfig.singleItemTitle = 'Banner';
moduleConfig.url = 'banners';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};


moduleConfig.bulkActions = [
    {
        displayName: "Enable Selected",
        value: "enabled"
    },
    {
        displayName: "Disable Selected",
        value: "disabled"
    }
];


export default moduleConfig;