import React, { useState, useReducer, useEffect } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import moduleConfig from "../../moduleConfig";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import GalleryItem from "./GalleryPicture";
import ReactFancyBox from "react-fancybox";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import AddPagePicture from "./AddPagePicture";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";
import {
  detailsInitialState,
  detailsReducer,
} from "../../reducers/detailsReducer";
import parse from "html-react-parser";
const Details = ({ items, pictures, detailsDispatch, loadItem, loading ,id}) => {
  const { confirm } = useConfirm();
  const [show, setShow] = useState(false);
  const [galleryState, galleryDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const confirmAndRemovePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to remove this picture?`
    );

    if (isConfirmed) {
      await removePicture(pictureId);
    }
  };

  const removePicture = async (pictureId) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.delete(`page-gallery/${pictureId}`, {
        albumId:id,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndArchivePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to archive this media?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "0");
    }
  };

  const confirmAndRestorePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to restore this media?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "1");
    }
  };

  const changeStatusPicture = async (pictureId, status) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`page-gallery/change-status`, {
        id: pictureId,
        status,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media ${
          status === "1" ? "restored" : "archived"
        } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };
  const loadGallery = async () => {
    if (id) {
      galleryDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`page-gallery/${id}`); //
        galleryDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data, "154646");
      } catch (error) {
        galleryDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadGallery();
  }, [id]);
 

  return (
    <>
      <Row>
        <Col>
          <Card className="borderCream">
            <Card.Header className="ps-1 pe-2 py-0">
              <div className="d-flex  my-2 mx-2">
                <h5 className="text-bold">Page Gallery </h5> &nbsp; &nbsp;{" "}
                <Button
                  title="Please click to add media"
                  variant="primary"
                  size="sm"
                  className="rounded-circle roundButton"
                  onClick={() => setShow(true)}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-2 pb-0 pt-0">
              <Row className="circleMembersList">
                {items && items.length > 0 ? (
                  <>
                    {items.map((picture, index) => {
                      return (
                        <Col
                          key={index}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          className="pt-1"
                        >
                          <GalleryItem
                            picture={picture}
                            loadItem={loadItem}
                            confirmAndRemovePicture={confirmAndRemovePicture}
                            confirmAndArchivePicture={confirmAndArchivePicture}
                            confirmAndRestorePicture={confirmAndRestorePicture}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="warning" className="mb-0">
                    No records found.
                  </Alert>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddPagePicture
        show={show}
        setShow={setShow}
        id={id}
        loadItem={loadItem}
      />
    </>
  );
};

export default Details;
