import React from 'react'
import ApplicationRoutes from './config/ApplicationRoutes';
import AuthContextProvider from "./contexts/AuthContext";
import ConfirmContextProvider from "./contexts/ConfirmContext";
import BreadcrumbContextProvider from "./contexts/BreadcrumbContext";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'react-fancybox/lib/fancybox.css'
//import 'react-image-lightbox/style.css';
import './App.css';
import logo from './images/ahead-hash-icon-black.png'
import ConfirmDialog from './components/UI/mix/confirmDialog';
import "./helpers/yupAdditionalValidations";

function App() {



  return (

    <AuthContextProvider>
      <BreadcrumbContextProvider>
        <ConfirmContextProvider>

          <div className="wrapper">
            <ReactNotifications />
            <div className="preloader flex-column justify-content-center align-items-center">
              <img className="animation__shake" src={logo} alt="Logo" />
            </div>


            <ApplicationRoutes />


          </div>
          <ConfirmDialog />
        </ConfirmContextProvider>
      </BreadcrumbContextProvider>
    </AuthContextProvider>

  );
}

export default App;
