import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { getFullName, isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormTextArea from "../../UI/forms/CMSFormTextArea";
import CMSFormCheckBoxes from "../../UI/forms/CMSFormCheckBoxes";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

const PaymentUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`payments/update-payment`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/details/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };

  const getValidationSchema = () => {
    if (updateState.data.item && updateState.data.item._id) {
      return yup.object({
        comments: yup.string().max(200),
        paidAmount: yup
          .number()
          .transform((cv) => (isNaN(cv) ? undefined : cv))

          .integer()
          .required(
            updateState.data.item.status === "1"
              ? "Actual paid amount is required."
              : "Actual payable amount is required."
          ),
      });
    } else {
      return {};
    }
  };

  useEffect(() => {
    loadItem();
    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      console.log(updateState.data.item);
      let obj = {
        id: updateState.data.item._id,
        buyerId: updateState.data.item.buyerId,
        buyer: getFullName(updateState.data.item.buyer),
        orderId: updateState.data.item.orderId,
        totalAmount: updateState.data.item.amount,
        method: updateState.data.item.method,
        comments: updateState.data.item.adminComments
          ? updateState.data.item.adminComments
          : "",
        paidAmount: updateState.data.item.paidAmount
          ? updateState.data.item.paidAmount
          : updateState.data.item.amount,
      };

      setFormInitialValues(obj);
    }
  }, [updateState.data]);

  return (
    <DashboardLayout>
      {Object.keys(formInitialValues).length <= 0 && updateState.isLoading && (
        <ContainerLoading />
      )}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={getValidationSchema()}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={async (values) => {
                        console.log(values);
                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}
                          {form.errors && console.log(form.errors)}

                          <CMSFormInput
                            name="buyer"
                            label="Buyer"
                            required={false}
                            form={form}
                            disabled={true}
                          />

                          <CMSFormInput
                            name="orderId"
                            label="OrderId"
                            required={false}
                            form={form}
                            disabled={true}
                          />

                          <CMSFormInput
                            name="totalAmount"
                            label="Total Amount"
                            required={false}
                            form={form}
                            disabled={true}
                          />

                          <CMSFormSelect
                            name="method"
                            label="Payment Method"
                            required={false}
                            form={form}
                            options={[
                              { value: "Online", label: "Online" },
                              {
                                value: "Bank Transfer",
                                label: "Bank Transfer",
                              },
                              {
                                value: "Exchange Transfer",
                                label: "Exchange Transfer",
                              },
                              { value: "Cash on Delivery", label: "Cash on Delivery" },
                              { value: "Cash in Shop", label: "Cash in Shop" },
                            ]}
                          />

                          <CMSFormInput
                            name="paidAmount"
                            label={
                              updateState.data.item.status === "1"
                                ? "Paid Amount"
                                : "Payable Amount"
                            }
                            disabled={
                              updateState.data.item.status === "1"
                                ? true
                                : false
                            }
                            required={true}
                            form={form}
                            type="number"
                          />

                          <CMSFormTextArea
                            name="comments"
                            label="Comments"
                            form={form}
                          />

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default PaymentUpdate;
