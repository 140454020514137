import React, { useReducer, useEffect, useContext } from "react";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig";
import { detailsReducer, detailsInitialState } from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";

import GalleryPictures from "../../UI/shared/gallery/GalleryPictures";
import RecentComments from "../../UI/shared/recentComments/RecentComments";
import RecentLikes from "../../UI/shared/recentLikes/RecentLikes";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  details: {
    label: "Details",
  },
};

const FossilsView = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const navigate = useNavigate();
  let { id } = useParams();
  const { confirm } = useConfirm();
  const loadItem = async () => {
    if (id) {
      detailsDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.details]);
  }, [id]);

  const confirmAndDisabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to disable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id);
    }
  };

  const confirmAndDelete = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to delete this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await deleteItem(id);
    }
  };


  const makeItUnsold = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to make it available again for sale?`
    );

    if (isConfirmed) {
      await unsoldItem(id);
    }
  };

  const unsoldItem = async (id) => {
    console.log("In unsoldItem Function");
    detailsDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/unsold-item`, {
        id,
        unsold: true,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Fossil available for sale again.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };


  const deleteItem = async (id) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.delete(`${moduleConfig.url}/${id}`);
      loadItem();
      // listingDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      navigate(`/fossils`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} deleted successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndEnabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to enable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id, true);
    }
  };

  const changeStatus = async (id, undo = false) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/change-status`, {
        id,
        status: undo === true ? "1" : "0",
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} ${undo ? "enabled" : "disabled"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <DashboardLayout>
      {detailsState.isFetching ? (
        <ContainerLoading />
      ) : (
        <>
          {detailsState.hasError ? (
            <BlockErrorMessage error={detailsState.error} />
          ) : (
            <>
              {detailsState.data.item && (
                <>
                  <ModuleHeader
                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                    moduleUrl={moduleConfig.url}
                  ></ModuleHeader>

                  <div className="border mb-1 p-1 bg-light text-white rounded">
                    <Link
                      title={`Update ${moduleConfig.singleItemTitle}`}
                      to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`}
                      className="btn btn-primary  border me-1"
                    >
                      <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
                      <span className="text-white">Update</span>
                    </Link>

                    {(detailsState.data.item.status === "1" ||
                      detailsState.data.item.status === 1) && (
                        <Button
                          className="me-1"
                          variant="primary"
                          title={`Disable ${moduleConfig.singleItemTitle}`}
                          onClick={() =>
                            confirmAndDisabled(detailsState.data.item._id)
                          }
                        >
                          <i className="fa fa-archive me-1" aria-hidden="true"></i>

                          <span className="text-white">Disable</span>
                        </Button>
                      )}

                    {(detailsState.data.item.status === "0" ||
                      detailsState.data.item.status === 0) && (
                        <Button
                          className="me-1"
                          variant="primary"
                          title={`Enable ${moduleConfig.singleItemTitle}`}
                          onClick={() =>
                            confirmAndEnabled(detailsState.data.item._id)
                          }
                        >
                          <i className="fa fa-arrow-up"> </i>{" "}
                          <span className="text-white">Enable</span>
                        </Button>
                      )}
                    <Button
                      className="me-1"
                      variant="primary"
                      title={`Delete ${moduleConfig.singleItemTitle}`}
                      onClick={() =>
                        confirmAndDelete(detailsState.data.item._id)
                      }
                    >
                      <i className="fas fa-trash"> </i>{" "}
                      <span className="text-white">Delete</span>
                    </Button>
                    {detailsState.data.item.type === "Collection" && detailsState.data.item.collectionOptions && (
                      <Link
                        to={`/fossils/collection-attributes/${detailsState.data.item._id}`}
                        className="me-1 btn btn-primary"
                        title={`Update Collection Details`}
                      >
                        <i className="fas fa-cogs text-white"> </i>{" "}
                        <span className="text-white ms-1">
                          Update Collection Details
                        </span>
                      </Link>
                    )}
                    {detailsState.data.item.type === "Collection" && !detailsState.data.item.collectionOptions && (
                      <Link
                        to={`/fossils/add-collection-attributes/${detailsState.data.item._id}`}
                        className="me-1 btn btn-primary"
                        title={`Update Collection Details`}
                      >
                        <i className="fas fa-cogs text-white"> </i>{" "}
                        <span className="text-white ms-1">
                          Add Collection Details
                        </span>
                      </Link>
                    )}
                    {detailsState.data.item.type === "Saleable" && !detailsState.data.item.saleableOptions && (
                      <Link
                        to={`/fossils/add-saleable-attributes/${detailsState.data.item._id}`}
                        className="me-1 btn btn-primary"
                        title={`Update Saleable Details`}
                      >
                        <i className="fas fa-cogs text-white"> </i>{" "}
                        <span className="text-white ms-1">
                          Add Saleable Details
                        </span>
                      </Link>
                    )}
                    {detailsState.data.item.type === "Saleable" && detailsState.data.item.saleableOptions && (
                      <Link
                        to={`/fossils/saleable-attributes/${detailsState.data.item._id}`}
                        className="me-1 btn btn-primary"
                        title={`Update Saleable Details`}
                      >
                        <i className="fas fa-cogs text-white"> </i>{" "}
                        <span className="text-white ms-1">
                          Update Saleable Details
                        </span>
                      </Link>
                    )}



                    {(detailsState.data.item.type === "Saleable" && detailsState.data.item.saleableOptions && detailsState.data.item.saleableOptions.soldOut === "1") && (


                      <Button
                        className="me-1"
                        variant="success"
                        title={`Make it Available Again`}
                        onClick={() =>
                          makeItUnsold(detailsState.data.item._id)
                        }
                      >
                        <i className="fas fa-arrow-up"> </i>{" "}
                        <span className="text-white">Make it Available Again</span>
                      </Button>



                    )}

                  </div>

                  <Details
                    item={detailsState.data.item}
                    moduleConfig={moduleConfig}
                    loadItem={loadItem}
                    detailsDispatch={detailsDispatch}
                    loading={detailsState.isFetching}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default FossilsView;
