import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const ModuleDynamicFilterDropdown = ({ filterField, setFilter, appliedFilters, options }) => {
    const [searchStatus, setSearchStatus] = useState('all')
    useEffect(() => {
        setSearchStatus(((appliedFilters[filterField]) ? appliedFilters[filterField] : 'all'))
    }, [appliedFilters])
    return <>
        <Form.Group className="m-0 p-0">
            <Form.Select
                value={searchStatus}
                onChange={(e) => {
                    console.log(e.target.value)
                    setFilter(filterField, e.target.value);
                }}
            >
                {
                    (options && options.length > 0) && (
                        options.map(({ label, value }, index) => {
                            return <option value={value} key={index}>{label}</option>
                        })
                    )
                }

            </Form.Select>
        </Form.Group>
    </>
};

export default ModuleDynamicFilterDropdown;
