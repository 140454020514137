import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";
const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];

    if (item.altitude) {
      items = [
        ...items,
        { text: "Altitude", value: item.altitude, type: "text" },
      ];
    }

    if (item.note) {
      items = [...items, { text: "Note", value: item.note, type: "text" }];
    }
    if (item.period) {
      items = [...items, { text: "Period", value: item.period, type: "text" }];
    }

    return items;
  };

  return (
    <Card className="item mb-2 borderCream ">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}

            <div className="ms-1 me-2">
              <i className="fas fa-map mt-1 position-relative cartIcon">
                {(item.status === "0" || item.status === 0) && (
                  <i className="fas fa-ban position-absolute banned4"></i>
                )}
              </i>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center ms-1 mt-1">
              <span className="m-0 text-bold memberName text-red">
                {item.title}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                {item.type === "Saleable" ? (
                  <span className="membershipId text-boldItalic text-info mt-1">
                    {item.type}
                  </span>
                ) : (
                  <span className="membershipId text-boldItalic mt-1">
                    {item.type}
                  </span>
                )}
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools endColumn mb-1 pt-1">
          <div>
            <span className="badge badge-light me-1 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Enabled"
                  falseValue="Disabled"
                />{" "}
              </span>
            </span>
            {item.createdAt && (
              <span class="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {/* {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )} */}
            <span
              title={`Delete ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="px-1 py-0">
        <Row>
          <Col className="mt-1 mb-1 ">
            <StatsListing items={getItems()} />
            <StatsListing
              heading="Stats"
              clickable={true}
              items={[
                {
                  title: "Pictures Count",
                  value: item.stats.picturesCount,
                  url: `/${moduleConfig.url}/${item._id}`,
                },
                {
                  title: "Videos Count",
                  value: item.stats.videosCount,
                  url: `/${moduleConfig.url}/${item._id}`,
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
