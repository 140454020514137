import React from 'react'
import SendEmail from '../../mix/SendEmail'



const ModuleDisplayEmail = ({ value,text,displayClass }) => {
    return (
        <SendEmail email={value} text={text} displayClass={displayClass} >{value}</SendEmail>
    )
}

export default ModuleDisplayEmail