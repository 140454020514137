import React from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayRichText from "../../../../UI/modules/fieldsDisplay/ModuleDisplayRichText";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import parse from "html-react-parser";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import {
  getCurrencyCode,
  getFullName,
} from "../../../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getBuyerEmail = () => {
    let items = [];
    if (item.email) {
      items = [...items, { text: "Email", value: item.email, type: "email" }];
    }
    if (item.phone) {
      items = [...items, { text: "Phone", value: item.phone, type: "phone" }];
    }
    if (item.country) {
      items = [
        ...items,
        { text: "Country", value: item.country, type: "text" },
      ];
    }
    return items;
  };
  return (
    <>
      {(item.hasCart === "1" || item.hasCart === 1) && (
        <div className="border mb-1 p-1 bg-light text-white rounded">
          <Link
            title={`Please click to view cart`}
            to={`/carts/${item.cartId}`}
            className="btn btn-primary  border me-1"
          >
            <i className="fas fa-shopping-cart text-white me-1"></i>{" "}
            <span className="text-white">View Cart</span>
          </Link>
        </div>
      )}

      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Body className="pt-0 pb-2 px-2">
              <div className="card-tools endColumn">
                <div className=" text-right  mt-xl-1 mt-lg-1 mt-md-1">
                  {(item.hasCart === "1" || item.hasCart === 1) && (
                    <Link
                      to={`/carts/${item.cartId}`}
                      className="pt-2 listingActionIcon"
                      title={`View cart`}
                    >
                      <span className="badge red me-1 text-white">
                        Has Cart
                      </span>
                    </Link>
                  )}
                  {item.createdAt && (
                    <span className="badge badge-light me-1 text-dark">
                      Created Date :{" "}
                      <span className="text-secondary">
                        <ModuleDisplayDate value={item.createdAt} />{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
              {/* <StatsListing items={getBuyerEmail()} /> */}
              <Row>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div className="info-box bgCream">
                    <span className="info-box-icon">
                      <i className="fas fa-user text-dark"></i>
                    </span>

                    <div className="info-box-content">
                      <span className="info-box-number text-dark">
                        Buyer Details
                      </span>
                      <div className="progress">
                        <div className="progress-bar"></div>
                      </div>
                      <span className="info-box-text">
                        {" "}
                      
                          <span className="text-red text-bold mt-1">
                            {item.firstName} {item.lastName}
                          </span>
                    
                      </span>
                      <i className="info-box-text ">
                        {" "}
                        <ModuleDisplayEmail value={item.email} text="dark" />
                      </i>
                      <span className="info-box-text">
                        {" "}
                        <ModuleDisplayPhone value={item.phone} text="dark" />
                      </span>
                      <span className="info-box-text"> {item.country}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              {item.orders && item.orders.length > 0 && (
                <h5 className="text-bold ms-1 mt-3 mb-4">
                  Total Orders ({item.ordersCount})
                </h5>
              )}

              <Row>
                {item.orders &&
                  item.orders.length > 0 &&
                  item.orders.map((item, index) => {
                    return (
                      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                        <div
                          className={`small-box 
                             borderCream bg-white
                         `}
                        >
                          <div className="card-tools end">
                            <div className=" text-right  mt-0 ms-1">
                              {item.orderStatus && (
                                <span className="text-secondary">
                                  <ModuleDisplayBoolean
                                    value={item.orderStatus}
                                    trueValue="Completed"
                                    falseValue="Processing"
                                  />{" "}
                                </span>
                              )}
                              {item.createdAt && (
                                <span className="badge badge-light me-1 text-dark ">
                                  Created Date :{" "}
                                  <span className="text-secondary">
                                    <ModuleDisplayDate value={item.createdAt} />{" "}
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="inner">
                            {/* <h3>{item.orderId}</h3> */}

                            {item.totalAmount &&
                              item.totalAmount.$numberDecimal && (
                                <h3 className="text-red">
                                  {item.totalAmount.$numberDecimal}{" "}
                                  {getCurrencyCode()}
                                </h3>
                              )}
                            <p className="text-dark text-bold mb-0">
                              {item.orderId}
                            </p>
                            <i className="text-primaryCream ">
                              {item.checkoutType === "Online Payment"
                                ? item.checkoutType
                                : item.checkoutType + " Checkout"}
                            </i>
                            <br />
                            {item.deliveryMethod && (
                              <p className="mb-0">{item.deliveryMethod}</p>
                            )}

                            <i className="mb-0 text-dark text-purple">
                              Total Product(s) ordered-{" "}
                              <span className="text-bold  text-purple">
                                {item.numberOfProducts}
                              </span>
                            </i>
                          </div>
                          <div className="icon ">
                            {item.orderStatus === "1" ? (
                              <i className="fas fa-check mt-3 green"></i>
                            ) : (
                              <i className="fas fa-clock mt-3"></i>
                            )}
                          </div>
                          <a
                            href={`/orders/${item._id}`}
                            className="small-box-footer"
                          >
                            View More Details{" "}
                            <i className="fas fa-arrow-circle-right ms-2"></i>
                          </a>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
