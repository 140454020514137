import React, { useState, useContext, useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";
import CMSFormRichText from "../../UI/forms/CMSFormRichText";
import CMSFormImage from "../../UI/forms/CMSFormImage";

const FossilsCollectionUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [sitesState, sitesDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [subCategoryState, subCategoryDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );

  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: "Fossils Collection",
      url: `/fossils-collection`,
    },
    details: {
      label: "Details",
      url: `/fossils-collection/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`fossils/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      //navigate(`/fossils-collection`);
      navigate(-1);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`fossils/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data, "11111");
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-fossil-categories`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "11111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  const loadSites = async () => {
    try {
      const res = await axios.get(`general/get-fossil-sites`);
      sitesDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

      console.log(res.data, "33333");
    } catch (error) {
      sitesDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  const loadSubCategoryData = async (data) => {
    try {
      const res = await axios.post(`general/get-fossil-sub-categories`, data);
      subCategoryDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "7777");
    } catch (error) {
      subCategoryDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
    loadSites();
  }, []);
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      console.log(updateState.data.item, "4646");
      loadSubCategoryData({ category: updateState.data.item.category });
      let obj = {
        relatedMaterial: updateState.data.item.relatedMaterial
          ? updateState.data.item.relatedMaterial
          : "",
        id: updateState.data.item._id,
        genus: updateState.data.item.genus ? updateState.data.item.genus : "",
        type: updateState.data.item.type ? updateState.data.item.type : "",
        category: updateState.data.item.category
          ? updateState.data.item.category
          : "",
        subCategory: updateState.data.item.subCategory
          ? updateState.data.item.subCategory
          : "",
        site: updateState.data.item.site ? updateState.data.item.site : "",
        image: updateState.data.item.image ? updateState.data.item.image : "",
        description: updateState.data.item.description
          ? updateState.data.item.description
          : "",
        ageOrOriginDate: updateState.data.item.ageOrOriginDate
          ? updateState.data.item.ageOrOriginDate
          : "",
        dimension: updateState.data.item.dimension
          ? updateState.data.item.dimension
          : "",
        matrix: updateState.data.item.matrix
          ? updateState.data.item.matrix
          : "",
        status: updateState.data.item.status
          ? updateState.data.item.status
          : "0",
      };

      setFormInitialValues(obj);
      //};
    }
  }, [updateState.data]);
  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  let fileRequirement = {
    allowedExtensions: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    size: 1024 * 1024 * 2,
  };
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update Basic Details of Fossil`}
            moduleUrl={moduleConfig.url}
          >
            <Link
              to={`/fossils-collection/collection-attributes/${updateState.data.item && updateState.data.item._id
                }`}
              className="btn btn-primary"
              title="Update Collection Details"
            >
              <i className="fas fa-plus-square text-white"> </i>{" "}
              <span className="text-white ms-1">Update Collection Details</span>
            </Link>
          </ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div className="overlay position-absolute containerLoading">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    {detailsState.data &&
                      detailsState.data.categories &&
                      subCategoryState.data &&
                      subCategoryState.data.subCategories &&
                      sitesState.data &&
                      sitesState.data.sites && (
                        <Formik
                          initialValues={formInitialValues}
                          validationSchema={yup.object({
                            genus: yup.string().required("Required"),
                            type: yup.string().required("Required"),
                            category: yup
                              .string()
                              .required("Required")
                              .test("", "", function (value) {
                                if (value) {
                                  console.log(value, "1111");
                                  loadSubCategoryData({ category: value });
                                  return true;
                                } else {
                                  return true;
                                }
                              }),
                            subCategory: yup.string(),
                            site: yup.string().required("Required"),
                            image: yup
                              .mixed()
                              .required("Required")
                              .imageTypeCheck(
                                imageRequirement.allowedExtensions
                              )
                              .imageSizeCheck(imageRequirement.size),

                            description: yup.string().required("Required"),
                            ageOrOriginDate: yup.string(),
                            dimension: yup.string(),
                            matrix: yup.string(),
                            relatedMaterial: yup
                              .mixed()
                              .nullable()
                              .imageTypeCheck(fileRequirement.allowedExtensions)
                              .imageSizeCheck(fileRequirement.size),
                            status: yup.string().required("Required"),
                          })}
                          enableReinitialize={true}
                          validateOnChange={true}
                          validateOnBlur={true}
                          onSubmit={(values, { resetForm }) => {
                            const formData = new FormData();
                            for (let name in values) {
                              if (Array.isArray(values[name])) {
                                formData.append(
                                  name,
                                  JSON.stringify(values[name])
                                );
                              } else {
                                formData.append(name, values[name]);
                              }
                            }
                            onSubmit(formData);
                            console.log(formData);
                          }}
                        >
                          {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                              {updateState.error && (
                                <FormErrorMessage error={updateState.error} />
                              )}

                              {/* {console.log(form.errors)} */}
                              <CMSFormInput
                                name="genus"
                                label="Genus"
                                required={true}
                                form={form}
                              />

                              <CMSFormInput
                                name="type"
                                label="Type"
                                disabled={true}
                                required={true}
                                form={form}
                              />

                              <CMSFormSelect
                                name="category"
                                label="Category"
                                required={true}
                                form={form}
                                options={detailsState.data.categories}
                              />

                              {subCategoryState.data &&
                                subCategoryState.data.subCategories && (
                                  <CMSFormSelect
                                    name="subCategory"
                                    label="Sub Category"
                                    form={form}
                                    options={
                                      subCategoryState.data &&
                                      subCategoryState.data.subCategories
                                    }
                                  />
                                )}

                              <CMSFormSelect
                                name="site"
                                label="Site"
                                required={true}
                                form={form}
                                options={sitesState.data.sites}
                              />
                              <CMSFormImage
                                label="Image"
                                name="image"
                                info="Recommended size 280*280"
                                form={form}
                                required={true}
                                aspect={1 / 1}
                                outputSize={imageRequirement.dimension}
                              />
                              <CMSFormRichText
                                label="Description"
                                name="description"
                                form={form}
                                required={true}
                              />
                              <CMSFormInput
                                name="ageOrOriginDate"
                                label="Age / Origin Date"
                                form={form}
                              />
                              <CMSFormInput
                                name="dimension"
                                label="Dimension"
                                form={form}
                              />

                              <CMSFormInput
                                name="matrix"
                                label="Matrix"
                                form={form}
                              />

                              <CMSFormFile
                                name="relatedMaterial"
                                label="Related Material"
                                form={form}
                              />

                              <CMSFormSelect
                                name="status"
                                label="Status"
                                required={true}
                                form={form}
                                options={[
                                  { label: "Select Status...", value: "" },
                                  { label: "Enabled", value: "1" },
                                  { label: "Disabled", value: "0" },
                                ]}
                              />

                              <button
                                type="submit"
                                onClick={() => form.handleSubmit}
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </Form>
                          )}
                        </Formik>
                      )}
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default FossilsCollectionUpdate;
