import React, { useState } from 'react';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const ModuleRecordsPerPageDropdown = ({ selectedPerPageItems, perPageItemsOptions, setFilter }) => {
    const [perPageItems, setPerPageItemsOptions] = useState(selectedPerPageItems)

    console.log("perPageItemsOptions", perPageItemsOptions, selectedPerPageItems)

    useEffect(() => {
        setPerPageItemsOptions(selectedPerPageItems)
    }, [selectedPerPageItems])
    //console.log(selectedPerPageItems);
    return <>
        <Form.Group className="listing-count-options m-0 p-0">
            {/* <Form.Label>Recrods Per Page</Form.Label> */}
            <Form.Select
                value={perPageItems}
                onChange={(e) => {
                    console.log(e.target.value)
                    setPerPageItemsOptions(e.target.value)
                    setFilter('limit', e.target.value);
                }}
            >
                {perPageItemsOptions.map(option => (
                    <option key={option} value={option} >{option} Records</option>
                ))}
            </Form.Select>
        </Form.Group>
    </>
};

export default ModuleRecordsPerPageDropdown;
