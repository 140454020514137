import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Accordion } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import axios from "../../../config/axios";

import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";

import StatsListing from "../shared/infoListing/StatsListing";
import moduleConfig from "../../modules/orders/moduleConfig";
import ModuleDisplayDate from "../modules/fieldsDisplay/ModuleDisplayDate";
function OrderContent({ setParent, parent }) {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    setParent(true);
    try {
      const res = await axios.get(`${moduleConfig.url}/stats-for-dashboard`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setParent(false);
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setParent(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);
  const getItems = (item) => {
    let items = [];

    if (item.numberOfProducts) {
      items = [
        ...items,
        { text: "Number Of Products", value: item.numberOfProducts },
      ];
    }
    if (item.deliveryMethod) {
      items = [
        ...items,
        { text: "Delivery Method", value: item.deliveryMethod, type: "text" },
      ];
    }
    return items;
  };

  const getBuyer = (item) => {
    let items = [];

    if (item.buyer && item.buyer.fullName) {
      items = [
        ...items,
        {
          title: "Full Name",
          value: item.buyer.fullName,
          type: "text",
          url: `/buyers/${item.buyerId}`,
        },
      ];
    }

    return items;
  };
  const getBuyerEmail = (item) => {
    let items = [];
    if (item.buyer && item.buyer.email) {
      items = [
        ...items,
        { text: "Email", value: item.buyer.email, type: "email" },
      ];
    }
    if (item.buyer && item.buyer.phone) {
      items = [
        ...items,
        {
          text: "Phone",
          value: item.buyer.phone,
          type: "phone",
        },
      ];
    }

    return items;
  };
  return (
    <>
      <Row className="my-2">
        <Col>
          {/* {listingState.isFetching === true && <FullPageOverlay />}
          {console.log(listingState.data)} */}
          {listingState.hasFetchingError && (
            <Alert variant="warning mb-0 mt-1">
              OOPS!!! Something went wrong, please try again later.
            </Alert>
          )}
          {listingState.data && listingState.data.summary && (
            <>
              <Row className="my-2">
                <Col xl={3} lg={3} md={3} sm={3} xs={12}>
                  <a href="/orders" title="Please click to view all orders">
                    <div class="info-box bg-gradient-blue">
                      <span class="info-box-icon">
                        <i class="fa fa-shopping-cart"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Total Orders</span>
                        <span class="info-box-number">
                          {listingState.data.summary.total}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={12}>
                  <a
                    href="/orders?orderStatus=0"
                    title="Please click to view pending orders"
                  >
                    <div class="info-box bg-gradient-yellow">
                      <span class="info-box-icon">
                        <i class="fas fa-clock" aria-hidden="true"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Pending Orders</span>
                        <span class="info-box-number">
                          {listingState.data.summary.pending}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={12}>
                  <a href="/orders?orderStatus=1">
                    <div
                      class="info-box bg-gradient-success"
                      title="Please click to view completed orders"
                    >
                      <span class="info-box-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Completed Orders</span>
                        <span class="info-box-number">
                          {listingState.data.summary.paid}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} xs={12}>
                  <a href="/orders?orderStatus=1">
                    <div
                      class="info-box bg-gradient-danger"
                      title="Please click to view completed orders"
                    >
                      <span class="info-box-icon">
                        <i class="fa fa-times"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Cancelled Orders</span>
                        <span class="info-box-number">
                          {listingState.data.summary.cancel}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>

              <Row className="mt-2 mb-3">
                <Col>
                  <h5 className="  text-bold">Recent Order(s)</h5>
                </Col>
                {listingState.data &&
                  listingState.data.summary &&
                  listingState.data.summary.total > 0 && (
                    <Col>
                      <Button
                        title="Please click to view all orders"
                        variant="primary"
                        className="float-end"
                        href="/orders"
                        size="sm"
                      >
                        View all
                      </Button>
                    </Col>
                  )}
              </Row>
            </>
          )}
          <Row>
            <Col>
              {listingState.data.items &&
                listingState.data.items.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1">No records found.</Alert>
                )}
              {listingState.data.items &&
                listingState.data.items.length > 0 && (
                  <>
                    {listingState.data.items.map((item, index) => {
                      return (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey={index == 0 ? "0" : index}
                            key={item._id}
                          >
                            <Accordion.Header className="accordionBorder">
                              <div className="w-100 dashboardCart">
                                <div className="d-flex align-items-center justify-content-center">
                                  <div>
                                    <p className="mb-1 text-bold ">
                                      <a
                                        title="Please click to view details"
                                        href={`/orders/${item._id}`}
                                        className="hoveredText"
                                      >
                                        {item.orderId}
                                      </a>
                                    </p>
                                    <i className="mb-1 text-red ">
                                      {item.checkoutType === "Online Payment"
                                        ? item.checkoutType
                                        : item.checkoutType + " Checkout"}
                                    </i>
                                  </div>
                                  {console.log(item)}
                                  <div>
                                    <h4 className="text-bold text-primaryCream ms-1">
                                      ({item.totalAmount}
                                      {getCurrencyCode()})
                                    </h4>
                                  </div>
                                </div>
                                <div className="my-2 my-xl-0 my-lg-0 my-md-0">
                                  {(item.orderStatus === "0" ||
                                    item.orderStatus === 0) && (
                                      <span className="badge badge-warning me-1 text-dark">
                                        Processing
                                      </span>
                                    )}
                                  {(item.orderStatus === "1" ||
                                    item.orderStatus === 1) && (
                                      <span className="badge badge-success me-1 text-white">
                                        Completed
                                      </span>
                                    )}
                                  {(item.orderStatus === "2" ||
                                    item.orderStatus === 2) && (
                                      <span className="badge badge-danger me-1 text-white">
                                        Cancelled
                                      </span>
                                    )}
                                  {item.createdAt && (
                                    <span class="badge badge-light me-1 mt-1 text-dark">
                                      Order Submission Date :{" "}
                                      <span class="text-secondary">
                                        <ModuleDisplayDate
                                          value={item.createdAt}
                                        />{" "}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              {item.orderStatus === "2" && (
                                <Alert
                                  key="danger"
                                  variant="danger"
                                  className="px-2 py-0 mb-0 mt-1"
                                >
                                  <b>
                                    Cancellation Reason :{" "}
                                    <i className="fs-8 text-normal">
                                      {" "}
                                      {item.adminComments}{" "}
                                    </i>
                                  </b>
                                </Alert>
                              )}
                              <StatsListing items={getItems(item)} />

                              <div className="buyerFlex">
                                <StatsListing
                                  heading="Buyer"
                                  className="d-flex"
                                  clickable={true}
                                  items={getBuyer(item)}
                                />
                                <StatsListing items={getBuyerEmail(item)} />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    })}
                  </>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default OrderContent;
