import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";
import CMSFormImage from "../../UI/forms/CMSFormImage";
import CMSFormTextArea from "../../UI/forms/CMSFormTextArea";
import CMSFormRichText from "../../UI/forms/CMSFormRichText";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const FossilsCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [sitesState, sitesDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [subCategoryState, subCategoryDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
    });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadSubCategoryData = async (data) => {
    try {
      const res = await axios.post(`general/get-fossil-sub-categories`, data);
      subCategoryDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "7777");
    } catch (error) {
      subCategoryDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-fossil-categories`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "11111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  const loadSites = async () => {
    try {
      const res = await axios.get(`general/get-fossil-sites`);
      sitesDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

      console.log(res.data, "33333");
    } catch (error) {
      sitesDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
    loadSites();
  }, []);

  useEffect(() => {
    ///  loadData()
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);

    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);
  let imageRequirement = {
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {createState.status !== "fetching" &&
            detailsState.data &&
            detailsState.data.categories && (
              <>
                <ModuleHeader
                  moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                  moduleUrl={moduleConfig.url}
                ></ModuleHeader>

                <section className="content">
                  <Card>
                    <Card.Body>
                      {createState.isLoading && (
                        <div className="overlay position-absolute containerLoading">
                          <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                      )}
                      <Formik
                        initialValues={{
                          genus: "",
                          type: "",
                          category: "",
                          subCategory: "",
                          site: "",
                          image: "",
                          description: "",
                          ageOrOriginDate: "",
                          dimension: "",
                          relatedMaterial: "",
                          status: "0",
                        }}
                        validationSchema={yup.object({
                          genus: yup.string().required("Required"),
                          type: yup.string().required("Required"),
                          category: yup
                            .string()
                            .required("Required")
                            .test("", "subcategory", function (value) {
                              if (value) {
                                console.log(value, "1111");
                                loadSubCategoryData({ category: value });
                                return true;
                              } else {
                                return true;
                              }
                            }),
                          subCategory: yup.string(),
                          site: yup.string().required("Required"),
                          image: yup
                            .mixed()
                            .required("Required")
                            .imageTypeCheck(imageRequirement.allowedExtensions)
                            .imageSizeCheck(imageRequirement.size),

                          description: yup.string().required("Required"),
                          ageOrOriginDate: yup.string(),
                          dimension: yup.string(),
                          relatedMaterial: yup
                            .mixed()
                            .nullable()
                            .test(
                              "type",
                              "Please upload a file of format pdf ,doc or docx",
                              function (value) {
                                if (value == "undefined" || value) {
                                  console.log(value.type, "1111");
                                  return (
                                    value &&
                                    (value.type === "application/pdf" ||
                                      value.type === "application/msword" ||
                                      value.type ===
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                                  );
                                } else {
                                  return true;
                                }
                              }
                            )
                            .test(
                              "size",
                              "File exceeded size of 2mb",
                              function (value) {
                                if (value == "undefined" || value) {
                                  console.log(value.size);
                                  return value && value.size <= 1024 * 1024;
                                } else {
                                  return true;
                                }
                              }
                            ),
                          status: yup.string().required("Required"),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values, { resetForm }) => {
                          const formData = new FormData();
                          for (let name in values) {
                            if (Array.isArray(values[name])) {
                              formData.append(
                                name,
                                JSON.stringify(values[name])
                              );
                            } else {
                              formData.append(name, values[name]);
                            }
                          }
                          onSubmit(formData);
                          console.log(formData);
                        }}
                      >
                        {(form) => (
                          <Form onSubmit={form.handleSubmit}>
                            {createState.error && (
                              <FormErrorMessage error={createState.error} />
                            )}

                            {console.log(form.errors)}
                            <CMSFormInput
                              name="genus"
                              label="Genus"
                              required={true}
                              form={form}
                            />

                            <CMSFormSelect
                              name="type"
                              label="Type"
                              form={form}
                              required={true}
                              options={[
                                { label: "Collection", value: "Collection" },
                                { label: "Saleable", value: "Saleable" },
                              ]}
                            />

                            <CMSFormSelect
                              name="category"
                              label="Category"
                              required={true}
                              form={form}
                              options={detailsState.data.categories}
                            />
                            {form.values.category && (
                              <>
                                {subCategoryState.data && subCategoryState.data.subCategories &&(
                                  <CMSFormSelect
                                    name="subCategory"
                                    label="Sub Category"
                                    form={form}
                                    
                                    options={
                                      subCategoryState.data &&
                                      subCategoryState.data.subCategories
                                    }
                                  />
                                )}
                              </>
                            )}
                            <CMSFormSelect
                              name="site"
                              label="Site"
                              form={form}
                              required={true}
                              options={sitesState.data.sites}
                            />
                            <CMSFormImage
                              label="Image"
                              name="image"
                              info="Recommended dimensions 280*280"
                              form={form}
                              required={true}
                              aspect={1 / 1}
                              outputSize={imageRequirement.dimension}
                            />
                            <CMSFormRichText
                              label="Description"
                              name="description"
                              form={form}
                              required={true}
                            />
                            <CMSFormInput
                              name="ageOrOriginDate"
                              label="Age / Origin Date"
                          
                              form={form}
                            />
                            <CMSFormInput
                              name="dimension"
                              label="Dimension"
                          
                              form={form}
                            />
                            <CMSFormFile
                              name="relatedMaterial"
                              label="Related Material"
                              form={form}
                            />
                            <CMSFormSelect
                              name="status"
                              label="Status"
                              required={true}
                              form={form}
                              options={[
                                { label: "Select Status...", value: "" },
                                { label: "Enabled", value: "1" },
                                { label: "Disabled", value: "0" },
                              ]}
                            />

                            <button
                              type="submit"
                              onClick={() => form.handleSubmit}
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </section>
              </>
            )}
        </>
      )}
    </DashboardLayout>
  );
};

export default FossilsCreate;
