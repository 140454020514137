import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";
import CMSFormRichText from "../../UI/forms/CMSFormRichText";
import CMSFormImage from "../../UI/forms/CMSFormImage";

const FossilsSiteUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );

  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/details/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        console.log(error)
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };

  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      console.log(updateState.data.item, "4646");
      let obj = {
        title: updateState.data.item.title ? updateState.data.item.title : "",
        id: updateState.data.item._id,
        altitude: updateState.data.item.altitude
          ? updateState.data.item.altitude
          : "",
        note: updateState.data.item.note ? updateState.data.item.note : "",
        period: updateState.data.item.period
          ? updateState.data.item.period
          : "",
        description: updateState.data.item.description
          ? updateState.data.item.description
          : "",
        image: updateState.data.item.image ? updateState.data.item.image : "",

        sortOrder: updateState.data.item.sortOrder
          ? updateState.data.item.sortOrder
          : "99",
        status: updateState.data.item.status
          ? updateState.data.item.status
          : "0",
      };

      setFormInitialValues(obj);
      //};
    }
  }, [updateState.data]);
  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  let fileRequirement = {
    allowedExtensions: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    size: 1024 * 1024 * 2,
  };
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div className="overlay position-absolute containerLoading">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                     
                        <Formik
                          initialValues={formInitialValues}
                          validationSchema={yup.object({
                            title: yup.string().required("Required"),
                            altitude: yup.string(),
                            image: yup
                              .mixed()
                              .required("Required")
                              .imageTypeCheck(imageRequirement.allowedExtensions)
                              .imageSizeCheck(imageRequirement.size),
                            description: yup.string().required("Required"),
                            note: yup.string(),
                            period: yup.string(),
                            sortOrder: yup.string().required("Required"),
                            status: yup.string().required("Required"),
                          })}
                          enableReinitialize={true}
                          validateOnChange={true}
                          validateOnBlur={true}
                          onSubmit={(values, { resetForm }) => {
                            const formData = new FormData();
                            for (let name in values) {
                              if (Array.isArray(values[name])) {
                                formData.append(
                                  name,
                                  JSON.stringify(values[name])
                                );
                              } else {
                                formData.append(name, values[name]);
                              }
                            }
                            onSubmit(formData);
                            console.log(formData);
                          }}
                        >
                          {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                              {updateState.error && (
                                <FormErrorMessage error={updateState.error} />
                              )}

                              <CMSFormInput
                                name="title"
                                label="Title"
                                required={true}
                                form={form}
                              />
                              <CMSFormInput
                                name="altitude"
                                label="Altitude"
                               
                                form={form}
                              />
                              <CMSFormInput
                                name="note"
                                label="Note"
                            
                                form={form}
                              />
                              <CMSFormInput
                                name="period"
                                label="Period"
                                form={form}
                              />
                              <CMSFormImage
                                label="Image"
                                name="image"
                                info="Recommended dimensions 913*678"
                                form={form}
                                required={true}
                                aspect={1 / 1}
                                outputSize={imageRequirement.dimension}
                              />
                              <CMSFormRichText
                                name="description"
                                label="Description"
                                required={true}
                                form={form}
                                
                              />
                              <CMSFormInput
                                name="sortOrder"
                                label="Sort Order"
                                required={true}
                                form={form}
                              />
                              <CMSFormSelect
                                name="status"
                                label="Status"
                                required={true}
                                form={form}
                                options={[
                                  { label: "Select Status...", value: "" },
                                  { label: "Enabled", value: "1" },
                                  { label: "Disabled", value: "0" },
                                ]}
                              />

                              <button
                                type="submit"
                                onClick={() => form.handleSubmit}
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </Form>
                          )}
                        </Formik>
                     
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default FossilsSiteUpdate;
