import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";

const MediaGalleryUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  //console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);

  let imageRequirement = {
    dimension: {
      width: 700,
      height: 700,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        title: yup.string().required("Required").max(170,"Please enter at most 170 characters"),
                        date: yup.string().required("Required"),
                        image: yup
                          .mixed()
                          .required("Please upload image.")
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values, { resetForm }) => {
                        //console.log(values);
                        ///onSubmit(values, resetForm)

                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        //console.log(formData);
                        onSubmit(formData, resetForm);
                        console.log(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {/* {console.log(form)} */}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}
                          <CMSFormInput
                            name="title"
                            label="Title"
                            required={true}
                            form={form}
                          />
                           <CMSFormDate
                            name="date"
                            label="Date"
                            minDate={new Date("2000-1-1")}
                            maxDate={new Date("2040-12-28")}
                            value={new Date(form.values["date"])}
                            form={form}
                            required={true}
                          />

                          <CMSFormImageWithCrop
                            label="image"
                            name="image"
                            info="Recommended dimensions 700*700"
                            form={form}
                            required={true}
                            aspect={1 / 1}
                            outputSize={imageRequirement.dimension}
                          />
                          <div className="form-group ">
                            <label
                              htmlFor="status"
                              className={` ${form.errors["status"] ? "is-invalidText" : ""
                                }`}
                            >
                              Status
                            </label>
                            <Field
                              as="select"
                              id="status"
                              name="status"
                              className={`form-control ${form.errors["status"] ? "is-invalid" : ""
                                }`}
                            >
                              {/* <option value="EmailVerificationRequired">Email Verification Required</option> */}
                              <option value="0">Archived</option>
                              <option value="1">Active</option>
                            </Field>
                            {form.errors["status"] !== undefined && (
                              <span
                                id="status-error"
                                class="error invalid-feedback"
                              >
                                {form.errors["status"]}
                              </span>
                            )}
                          </div>

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default MediaGalleryUpdate;
