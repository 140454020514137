import React from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import {
  getCurrencyCode,
  getFullName,
} from "../../../../../helpers/CommonHelper";

const Details = ({ item, moduleConfig }) => {
  const fields = moduleConfig.fields;
  return (
    <>
      <Card className="item  mb-2">
        <Card.Header className="ps-1 pe-2 py-0">
          <Card.Title className="m-0 p-0 ">
            <div className="d-flex ImageSectionSpacing">
              <div className="position-relative ms-1 me-2">
                <div className="ms-1 me-2">
                  <i
                    className={`fas fa-${
                      item.status === "1"
                        ? "thumbs-up text-success"
                        : "thumbs-down text-warning"
                    }  position-relative usericon `}
                  ></i>
                </div>
              </div>
              <div className="d-flex flex-column ms-1">
                <span className="m-0 text-bold memberName ">
                  <Link
                    to={`/buyers/${item.buyer._id}`}
                    className="text-red"
                    title="Please click to view buyer details"
                    variant="link"
                  >
                    {getFullName(item.buyer)}
                  </Link>
                </span>
                <span className="font-italic font-small font-weight-normal text-dark fs-8  ">
                  {item.buyerId}
                </span>
              </div>
            </div>
          </Card.Title>
          {console.log(item)}
          <div className="card-tools">
            <div className=" text-right  mt-1">
              <span className="badge badge-light me-1 text-dark">
                Entry Date: <span class="text-secondary">{item.createdAt}</span>
              </span>
            </div>
          </div>
        </Card.Header>

        <Card.Body className="p-2">
          <table class="m-0 table table-striped table-bordered">
            <tbody>
              <tr>
                <td>Payment Status</td>
                <td>
                  <ModuleDisplayBoolean
                    trueValue="Done"
                    falseValue="Pending"
                    value={item.status}
                  />
                </td>
              </tr>

              {item.transactionId && (
                <tr>
                  <td>Transaction ID</td>
                  <td>{item.transactionId}</td>
                </tr>
              )}
              <tr>
                <td>Order ID</td>
                <td>{item.orderId}</td>
              </tr>
              {item.cartId && (
                <tr>
                  <td>cart ID</td>
                  <td>{item.cartId}</td>
                </tr>
              )}

              {item.buyer && item.buyer.firstName && (
                <tr>
                  <td>Full Name</td>
                  <td> {item.buyer ? getFullName(item.buyer) : ""}</td>
                </tr>
              )}
              {item.method && (
                <tr>
                  <td>Payment Method</td>
                  <td>
                    {" "}
                    {item.method === "COD"
                      ? "Cash on Delivery"
                      : item.method === "CIS"
                      ? "Cash in Store"
                      : item.method}
                  </td>
                </tr>
              )}

              <tr>
                <td>
                  {item.status === "1" ? "Paid Amount" : "Payable Amount"}
                </td>
                <td>
                  <b>{item.paidAmount + " " + getCurrencyCode()} </b>
                </td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{item.amount + " " + getCurrencyCode()} </td>
              </tr>

              {item.status === "1" && (
                <tr>
                  <td>Payment Date</td>
                  <td>{item.date}</td>
                </tr>
              )}

              {item.updatedAt && (
                <tr>
                  <td>Updated Date</td>
                  <td>{item.updatedAt}</td>
                </tr>
              )}

              {item.adminComments && (
                <tr>
                  <td>Admin Comments</td>
                  <td>{item.adminComments}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Details;
