import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";
import ModuleDynamicFilterDropdown from "../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  //console.log(appliedFilters);
  return (
    <>
      {(appliedFilters.limit ||
        appliedFilters.q ||
        appliedFilters.orderStatus ||
        appliedFilters.paymentStatus ||
        appliedFilters.checkoutType ||
        appliedFilters.source) && (
          <div div className=" text-right">
            <span className="resetFilters" onClick={() => resetFilters()}>
              <i className="fas fa-times" /> Reset Filters
            </span>
          </div>
        )}

      <div className="position-relative">
        <Card className="listingPageHeader p-0 m-0 p-1 mt-1 mb-2">
          <Card.Body className=" p-0 m-0">
            <Row className="mb-0">
              {moduleConfig.hasPagination === true &&
                moduleConfig.perPageItemsOptions &&
                moduleConfig.perPageItemsOptions.length > 0 && (
                  <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                    <ModuleRecordsPerPageDropdown
                      selectedPerPageItems={moduleConfig.perPageItems}
                      perPageItemsOptions={moduleConfig.perPageItemsOptions}
                      setFilter={setFilter}
                    />
                  </Col>
                )}

              <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                <ModuleDynamicFilterDropdown
                  filterField="orderStatus"
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                  options={[
                    {
                      label: "Select Order Status...",
                      value: "all",
                    },
                    {
                      label: "Completed",
                      value: "1",
                    },
                    {
                      label: "Cancelled",
                      value: "2",
                    },
                    {
                      label: "Processing",
                      value: "0",
                    },
                  ]}
                />
              </Col>

              <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                <ModuleDynamicFilterDropdown
                  filterField="paymentStatus"
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                  options={[
                    {
                      label: "Select Payment Status...",
                      value: "all",
                    },
                    {
                      label: "Paid",
                      value: "1",
                    },
                    {
                      label: "Pending",
                      value: "0",
                    },
                  ]}
                />
              </Col>

              <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                <ModuleDynamicFilterDropdown
                  filterField="checkoutType"
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                  options={[
                    {
                      label: "Select Checkout Type...",
                      value: "all",
                    },
                    {
                      label: "Online Payment",
                      value: "Online Payment",
                    },
                    {
                      label: "WhatsApp",
                      value: "WhatsApp",
                    },
                  ]}
                />
              </Col>
              {moduleConfig.hasSearch === true && (
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                  <ModuleSearchBox
                    setFilter={setFilter}
                    appliedFilters={appliedFilters}
                  />
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default SearchArea;
