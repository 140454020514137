import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import {
  getAllNumbersBetween,
  handleFormatArray,
} from "../../../helpers/CommonHelper";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const PublicationsCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );

  // Return today's date and time
  var currentTime = new Date();
  var year = currentTime.getFullYear();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-languages`); 
      createDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data.languages, "11111");
    } catch (error) {
      createDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);

    // createDispatch({
    //   type: "FETCH_SUCCESS",
    //   payload: [],
    // });
  }, []);
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        title: "",
                        day: "",
                        month: "",
                        year: "",
                        author: "",
                        source: "",
                        topic: "",
                        language: "",
                        file: "",
                        filePagesCount: "",
                        externalLink: "",
                        externalLinkTitle: "",
                        relevant: "0",
                        status: "0",
                      }}
                      validationSchema={yup
                        .object({
                          title: yup.string().required("Required").max(70),
                          day: yup.string().notRequired(),
                          month: yup.string().when("day", {
                            is: (day) => day,
                            then: yup.string().required(),
                          }),
                          year: yup.string().required("Required"),
                          author: yup.string(),
                          source: yup.string().max(50),
                          topic: yup.string().max(25),
                          language: yup.string().required("Required").max(220),

                          filePagesCount: yup.string().when("file", {
                            is: (file) => file,
                            then: yup.string().required("Required"),
                          }),

                          externalLinkTitle: yup.string().when("externalLink", {
                            is: (externalLink) => externalLink,
                            then: yup.string().required("Required"),
                          }),
                          relevant: yup.string().required(),
                          status: yup.string().required("Required"),
                        })
                        .shape(
                          {
                            file: yup.mixed().when("externalLink", {
                              is: "",
                              then: yup
                                .mixed()
                                .required("Required")
                                .test(
                                  "type",
                                  "Please upload a file of format pdf or docx",
                                  function (value) {
                                    if (value == "undefined" || value) {
                                      console.log(value.type, "1111");
                                      return (
                                        value &&
                                        (value.type === "application/pdf" ||
                                          value.type ===
                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                                      );
                                    } else {
                                      return true;
                                    }
                                  }
                                )
                                .test(
                                  "size",
                                  "File exceeded size of 2mb",
                                  function (value) {
                                    if (value == "undefined" || value) {
                                      console.log(value.size);
                                      return value && value.size <= 1024 * 1024;
                                    } else {
                                      return true;
                                    }
                                  }
                                ),
                            }),
                            externalLink: yup
                              .string()
                              .ensure()
                              .when("file", {
                                is: (file) => !file,
                                then: yup.string().url().required(),
                              }),
                          },
                          [["externalLink", "file"]]
                        )}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { resetForm }) => {
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        onSubmit(formData);
                        console.log(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          {/* {console.log(form.errors)} */}
                          <CMSFormInput
                            name="title"
                            label="Title"
                            required={true}
                            form={form}
                          />
                          <CMSFormSelect
                            name="day"
                            label="Day"
                            form={form}
                            options={[
                              { label: "1", value: "1" },
                              { label: "2", value: "2" },
                              { label: "3", value: "3" },
                              { label: "4", value: "4" },
                              { label: "5", value: "5" },
                              { label: "6", value: "6" },
                              { label: "7", value: "7" },
                              { label: "8", value: "8" },
                              { label: "9", value: "9" },
                              { label: "10", value: "10" },
                              { label: "11", value: "11" },
                              { label: "12", value: "12" },
                              { label: "13", value: "13" },
                              { label: "14", value: "14" },
                              { label: "15", value: "15" },
                              { label: "16", value: "16" },
                              { label: "17", value: "17" },
                              { label: "18", value: "18" },
                              { label: "19", value: "19" },
                              { label: "20", value: "20" },
                              { label: "21", value: "21" },
                              { label: "22", value: "22" },
                              { label: "23", value: "23" },
                              { label: "24", value: "24" },
                              { label: "25", value: "25" },
                              { label: "26", value: "26" },
                              { label: "27", value: "27" },
                              { label: "28", value: "28" },
                              { label: "29", value: "29" },
                              { label: "30", value: "30" },
                              { label: "31", value: "31" },
                            ]}
                          />
                          <CMSFormSelect
                            name="month"
                            label="Month"
                            required={form.values.day ? true : false}
                            form={form}
                            options={[
                              { label: "January", value: "January" },
                              { label: "February", value: "February" },
                              { label: "March", value: "March" },
                              { label: "April", value: "April" },
                              { label: "May", value: "May" },
                              { label: "June", value: "June" },
                              { label: "July", value: "July" },
                              { label: "August", value: "August" },
                              { label: "September", value: "September" },
                              { label: "October", value: "October" },
                              { label: "November", value: "November" },
                              { label: "December", value: "December" },
                            ]}
                          />
                          <CMSFormSelect
                            name="year"
                            label="Year"
                            required={true}
                            form={form}
                            options={handleFormatArray(
                              getAllNumbersBetween(year, year - 50)
                            )}
                          />

                          <CMSFormInput
                            name="author"
                            label="Author"
                            form={form}
                          />
                          <CMSFormInput
                            name="source"
                            label="Source"
                            form={form}
                          />
                          <CMSFormInput
                            name="topic"
                            label="Topic"
                            form={form}
                          />
                          <CMSFormSelect
                            name="language"
                            label="Language"
                            options={createState.data.languages}
                            required={true}
                            form={form}
                          />
                          <CMSFormFile
                            name="file"
                            label="File"
                            required={form.values.externalLink ? false : true}
                            form={form}
                          />
                          {form.values.file && (
                            <CMSFormInput
                              name="filePagesCount"
                              label="File Pages Count"
                              required={true}
                              form={form}
                            />
                          )}
                          <CMSFormInput
                            name="externalLink"
                            label="External Link"
                            required={form.values.file ? false : true}
                            form={form}
                          />

                          <CMSFormInput
                            name="externalLinkTitle"
                            label="External Link Title"
                            required={
                              form.values.externalLink.length > 1 ? true : false
                            }
                            form={form}
                          />

                          {console.log(form.values)}
                          <CMSFormSelect
                            name="relevant"
                            label="Relevant?"
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { label: "Select Status...", value: "" },
                              { label: "Enabled", value: "1" },
                              { label: "Disabled", value: "0" },
                            ]}
                          />

                          <button
                            type="submit"
                            onClick={() => form.handleSubmit}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default PublicationsCreate;
