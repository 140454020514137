import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig";
import * as Yup from "yup";
let moduleConfig = {};
moduleConfig = {
  ...moduleDefaultConfig,
};

moduleConfig.hasStatusSearch = false;

moduleConfig.title = "Contact Us Submissions";
moduleConfig.singleItemTitle = "Contact Us";
moduleConfig.url = "contact-us";

moduleConfig.buttons = {
  create: false,
  view: true,
  update: false,
  delete: true,
};

moduleConfig.bulkActions = [
  {
    displayName: "Delete Selected",
    value: "Delete",
  },
];

moduleConfig.fields = [
  {
    displayName: "ID",
    fieldName: "_id",
    type: "text",
    control: "hidden",
  },
  {
    displayName: "First Name",
    fieldName: "firstName",
    type: "text",
    control: "input",
    showOnListing: true,
    sortable: true,
  },
  {
    displayName: "Last Name",
    fieldName: "lastName",
    type: "text",
    control: "input",
    showOnListing: true,
    sortable: true,
  },
  {
    displayName: "Email",
    fieldName: "email",
    type: "email",
    control: "input",
    showOnListing: true,
    sortable: true,
  },

  {
    displayName: "Mobile",
    fieldName: "phone",
    type: "text",
    control: "input",
    showOnListing: true,
    sortable: true,
    displayStyle: "phone",
  },
  {
    displayName: "Submission Date & Time",
    fieldName: "createdAt",
    type: "dateTime",
    showOnListing: true,
    sortable: true,
    displayStyle: "text",
  },
  {
    displayName: "Message",
    fieldName: "message",
    type: "text",
    control: "input",

  },
  {
    displayName: "Submission Date & Time",
    fieldName: "createdAt",
    type: "dateTime",
},
];
moduleConfig.initialValues = {};
moduleConfig.validationSchema = Yup.object({});

export default moduleConfig;
