import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig";
let moduleConfig = {};
moduleConfig = {
  ...moduleDefaultConfig,
};

moduleConfig.title = "News & Events";
moduleConfig.singleItemTitle = "News / Event";
moduleConfig.url = "news-events";
moduleConfig.moduleType = {
  listing: "block",
  view: "block",
};

moduleConfig.bulkActions = [
  {
    displayName: "Enable Selected",
    value: "enabled",
  },
  {
    displayName: "Disable Selected",
    value: "disabled",
  },
];

export default moduleConfig;
