import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { getFullName } from "../../../../../helpers/CommonHelper";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];

    // if (item.cartItemsCount) {
    //   items = [
    //     ...items,
    //     { text: "Total item(s) in cart", value: item.cartItemsCount },
    //   ];
    // }
    // if (item.paymentMethod) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Payment Method",
    //       value: item.paymentMethod,
    //       type: "text",
    //     },
    //   ];
    // }

    return items;
  };
  const getBuyer = () => {
    let items = [];

    if (item.buyer && item.buyer.fullName) {
      items = [
        ...items,
        {
          title: "Full Name",
          value: item.buyer.fullName,
          type: "text",
          url: `/buyers/${item.buyerId}`,
        },
      ];
    }

    return items;
  };
  const getBuyerEmail = () => {
    let items = [];
    if (item.buyer && item.buyer.email) {
      items = [
        ...items,
        { text: "Email", value: item.buyer.email, type: "email" },
      ];
    }

    return items;
  };
  return (
    <Card className="item mb-2">
      {console.log(item)}
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}

            <div className="ms-1 me-2">
              <i className="fa fa-shopping-cart pt-1 position-relative cartIcon "></i>
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center ms-1">
              <span className="m-0 text-bold text-red">
                {" "}
                {/* <a href={`/buyers/${item._id}`} className="hoveredText">
                  {item.buyer.fullName ? item.buyer.fullName : getFullName(item.buyer)}
                </a> */}
                {item.cartItemsCount} Item(s) in cart
              </span>

              {/* <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId text-bold">{item.cartItemsCount} Item(s) in cart</span>
              </span> */}
            </div>
          </div>
        </Card.Title>

        <div className="card-tools endColumn mb-1 pt-1">
          <div>
            {(item.paymentFailed === "1" || item.paymentFailed === 1) && (
              <span className="badge red me-1 text-white">Payment Failed</span>
            )}
            {item.paymentMethod && (
              <span className="badge badge-success me-1 text-white">
                Online Payment Selected
              </span>
            )}

            {item.createdAt && (
              <span className="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span className="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>

            {/* <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disabled ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enabled ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
            <span
              title={`Enabled ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span> */}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="px-1 py-0">
        <Row>
          <Col className="mt-1 mb-1 ">
            <StatsListing items={getItems()} />
            <div className="d-flex">
              <StatsListing
                heading="Buyer"
                clickable={true}
                items={getBuyer()}
              />
              <StatsListing items={getBuyerEmail()} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
