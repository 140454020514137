import React, { useReducer, useEffect, useContext } from "react";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig";
import {
  detailsReducer,
  detailsInitialState,
} from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";

import GalleryPictures from "../../UI/shared/gallery/GalleryPictures";
import RecentComments from "../../UI/shared/recentComments/RecentComments";
import RecentLikes from "../../UI/shared/recentLikes/RecentLikes";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  details: {
    label: "Details",
  },
};

const BannersView = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const navigate = useNavigate()
  let { id } = useParams();
  const { confirm } = useConfirm();
  const loadItem = async () => {
    if (id) {
      detailsDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.details]);
  }, [id]);

  const confirmAndDisabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to disable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id);
    }
  };
  const deleteItem = async (id) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.delete(`${moduleConfig.url}/${id}`);
      loadItem();
      // listingDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      navigate(`/banners`)
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} deleted successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndEnabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to enable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id, true);
    }
  };

  const changeStatus = async (id, undo = false) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/change-status`, {
        id,
        status: undo === true ? "1" : "0",
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} ${
          undo ? "enabled" : "disabled"
        } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <DashboardLayout>
      {detailsState.isFetching ? (
        <ContainerLoading />
      ) : (
        <>
          {detailsState.hasError ? (
            <BlockErrorMessage error={detailsState.error} />
          ) : (
            <>
              {detailsState.data.item && (
                <>
                  <ModuleHeader
                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                    moduleUrl={moduleConfig.url}
                  ></ModuleHeader>

                  <div className="border mb-1 p-1 bg-light text-white rounded">
                    <Link
                      title={`Update ${moduleConfig.singleItemTitle}`}
                      to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`}
                      className="btn btn-primary  border me-1"
                    >
                      <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
                      <span className="text-white">Update</span>
                    </Link>

                
                    <Button
                      className="me-1"
                      variant="primary"
                      title={`Delete ${moduleConfig.singleItemTitle}`}
                      onClick={() =>
                        deleteItem(detailsState.data.item._id)
                      }
                    >
                    <i className="fas fa-trash"> </i>{" "}
                      <span className="text-white">Delete</span>
                    </Button>
                  </div>

                  <Details
                    item={detailsState.data.item}
                    moduleConfig={moduleConfig}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default BannersView;
