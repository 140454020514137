import React, { createContext, useEffect, useReducer } from "react";
import { loginAPI, logoutAPI } from '../apis/authAPIs'
import { userAuthInitialState, authReducer } from "../reducers/authReducer";






export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
    const [userAuthState, userAuthDispatch] = useReducer(authReducer, userAuthInitialState);


    // useEffect(() => {
    //     console.log("useEffect of AuthContext");
    //     let user = localStorage.getItem('user');
    //     console.log('localStorage user', user);

    //     if (user && user !== undefined && user !== 'undefined') {
    //         user = JSON.parse(user)
    //     }
    //     if (user && user.token) {
    //         userAuthDispatch({
    //             type: 'LOGIN_SUCCESS',
    //             payload: {
    //                 user: user
    //             }
    //         })
    //     }
    // }, []);


    const login = async (data) => {
        console.log('login function called');
        userAuthDispatch({
            type: 'LOGIN_REQUEST'
        })
        const apiResult = await loginAPI(data);
        console.log('apiResult', apiResult)
        if (apiResult.status && apiResult.data.user && apiResult.data.user.token) {
            userAuthDispatch({
                type: "LOGIN_SUCCESS",
                payload: { user: apiResult.data.user }
            })
            return true;
        } else {
            userAuthDispatch({
                type: "LOGIN_FAILURE",
                payload: apiResult.error
            })

            setTimeout(() => {
                userAuthDispatch({
                    type: "LOGIN_RESET"
                })
            }, 4000);
            return false;
        }
    };

    const logout = async () => {
        userAuthDispatch({
            type: 'LOGOUT_REQUEST'
        })
        const apiResult = await logoutAPI();
        console.log("apiResult", apiResult);
        if (apiResult.status) {
            userAuthDispatch({ type: "LOGOUT_SUCCESS" })
        } else {
            userAuthDispatch({ type: "LOGOUT_FAILURE" })
        }
    };

    const getBearerTokenConfig = () => {
        return {
            headers: {
                Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
            },
        }
    }


    return (<AuthContext.Provider value={{
        login,
        logout,
        userAuthState,
        getBearerTokenConfig
    }}>
        {props.children}
    </AuthContext.Provider>);
};



export default AuthContextProvider;
