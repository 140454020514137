import React, { useState, useContext, useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import { Store as toast } from "react-notifications-component";
import { BreadcrumbContext } from "../../../../../contexts/BreadcrumbContext";
import axios from "../../../../../config/axios";
import { toastNotification } from "../../../../../config/toastNotification";
import DashboardLayout from "../../../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";

import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import {
  detailsInitialState,
  detailsReducer,
} from "../../reducers/detailsReducer";
import {
  updateInitialState,
  updateReducer,
} from "../../reducers/updateReducer";
import moduleConfig from "../../moduleConfig";
import CMSFormRichText from "../../../../UI/forms/CMSFormRichText";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import CMSFormImage from "../../../../UI/forms/CMSFormImage";
import CMSFormFile from "../../../../UI/forms/CMSFormFile";
import { isEmpty } from "../../../../../helpers/CommonHelper";
import ModuleHeader from "../../../../UI/modules/views/partials/common/ModuleHeader";
import CMSFormSelectChange from "../../../../UI/forms/CMSFormSelectChange";
import { createInitialState, createReducer } from "../../reducers/createReducer";

const CollectionDetails = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );

  const [count, setCount] = useState(0);
  const [formInitialValues, setFormInitialValues] = useState({});
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/fossils-collection`,
    },
    details: {
      label: "Details",
      url: `/fossils-collection/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `fossils/collection-attributes/${id}`,
        data
      ); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      //navigate(`/fossils-collection`);
      navigate(-1);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const handleCount = (values) => {
    var count = 0;
    console.log(values.collectible === "1" ? "yes" : "no");
    if (values.collectible === "1") {
      count = count + 1;
    }
    if (values.excellentPreservation && values.excellentPreservation === "1") {
      count = count + 1;
    }

    if (values.exceptionalRarity === "1") {
      count = count + 1;
    }

    if (values.fossilOfTheMonth === "1") {
      count = count + 1;
    }
    if (values.museumQuality === "1") {
      count = count + 1;
    }
    if (values.newDiscovery === "1") {
      count = count + 1;
    }
    if (values.newSpecies === "1") {
      count = count + 1;
    }

    return count;
  };
  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`fossils/collection-attributes/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data);
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };

  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      let obj = {
        collectible: updateState.data.item.collectible
          ? updateState.data.item.collectible
          : "0",
        excellentPreservation: updateState.data.item.excellentPreservation
          ? updateState.data.item.excellentPreservation
          : "0",
        exceptionalRarity: updateState.data.item.exceptionalRarity
          ? updateState.data.item.exceptionalRarity
          : "0",
        fossilOfTheMonth: updateState.data.item.fossilOfTheMonth
          ? updateState.data.item.fossilOfTheMonth
          : "0",
        museumQuality: updateState.data.item.museumQuality
          ? updateState.data.item.museumQuality
          : "0",
        newDiscovery: updateState.data.item.newDiscovery
          ? updateState.data.item.newDiscovery
          : "0",
        newSpecies: updateState.data.item.newSpecies
          ? updateState.data.item.newSpecies
          : "0",
      };
      console.log(updateState.data.item);
      setFormInitialValues(obj);
      //};
    }
  }, [updateState.data]);

  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update Collection Details`}
            moduleUrl={moduleConfig.url}
          >
            <Link to={`/fossils-collection/update/${id}`} className="btn btn-primary" title={`Update Basic Fossil Details`}>
              <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
              <span className="text-white">Update Basic Details</span>
            </Link>
          </ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}

                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        collectible: yup.string(),
                        excellentPreservation: yup.string(),
                        exceptionalRarity: yup.string(),
                        fossilOfTheMonth: yup.string(),
                        museumQuality: yup.string(),
                        newDiscovery: yup.string(),
                        newSpecies: yup.string(),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { resetForm }) => {
                        onSubmit(values);
                        console.log(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <CMSFormSelect
                            name="collectible"
                            label="Collectible"
                            form={form}
                            disabled={
                              form.values.collectible === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          <CMSFormSelect
                            name="excellentPreservation"
                            label="Excellent Preservation"
                            disabled={
                              form.values.excellentPreservation === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <CMSFormSelect
                            name="exceptionalRarity"
                            label="Exceptional Rarity"
                            form={form}
                            disabled={
                              form.values.exceptionalRarity === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          <CMSFormSelect
                            name="fossilOfTheMonth"
                            label="Fossil Of The Month"
                            disabled={
                              form.values.fossilOfTheMonth === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          <CMSFormSelect
                            name="museumQuality"
                            label="Museum Quality"
                            disabled={
                              form.values.museumQuality === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          <CMSFormSelect
                            name="newDiscovery"
                            label="New Discovery"
                            disabled={
                              form.values.newDiscovery === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <CMSFormSelect
                            name="newSpecies"
                            label="New Species"
                            disabled={
                              form.values.newSpecies === "0" &&
                                handleCount(form.values) >= 2
                                ? true
                                : false
                            }
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <button
                            type="submit"
                            onClick={() => form.handleSubmit}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default CollectionDetails;
