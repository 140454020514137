import React, { useState, useReducer } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";

import AddGalleryItem from "./AddGalleryPicture";
import GalleryItem from "./GalleryPicture";
import ReactFancyBox from "react-fancybox";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";

const Details = ({ item, pictures, detailsDispatch, loadItem, loading }) => {
  const { confirm } = useConfirm();
  const [show, setShow] = useState(false);

  const confirmAndRemovePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to remove this picture?`
    );

    if (isConfirmed) {
      await removePicture(pictureId);
    }
  };

  const removePicture = async (pictureId) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.delete(`media-gallery-items/${pictureId}`, {
        albumId: item._id,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndArchivePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to archive this media?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "0");
    }
  };

  const confirmAndRestorePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to restore this media?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "1");
    }
  };

  const changeStatusPicture = async (pictureId, status) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`media-gallery-items/change-status`, {
        id: pictureId,
        status,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media ${
          status === "1" ? "restored" : "archived"
        } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="ms-1 me-2">
                    <i className="fas fa-folder-open position-relative cartIcon ">
                      {(item.status === "0" || item.status === 0) && (
                        <i className="fas fa-ban position-absolute ms-1 banned3"></i>
                      )}
                    </i>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {item.title}
                    </span>
                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">{item.date}</span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-1 mt-xl-2 mt-lg-2 mt-md-2 pt-xl-1 pt-lg-1 pt-md-1 pt-0u">
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Active"
                        falseValue="Archived"
                      />{" "}
                    </span>
                  </span>
                  <span className="badge badge-light me-1 text-dark">
                  Created Date :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDate value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>
          
            <Card.Body className="px-2 pt-1 pb-0">
              {/* <StatsListing
                items={[
                  {
                    text: "Total media(s)",
                    value: item.items.length,
                  },
                ]}
              /> */}
              <Card bg="light" style={{ width: '14rem' }}>
                <Card.Img
                  variant="top"
                  src={
                    process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                  }
                />
                   <div className="galleryImageBg">
                <ReactFancyBox
                  thumbnail={
                    process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                  }
                  image={
                    process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                  }
                  caption="Album Cover"
                />
              </div>
                <Card.Footer>
                  <h5 className="text-dark">Total Media(s) : <span className="text-bold text-pink">{item.items.length}</span> </h5>
                </Card.Footer>
              </Card>

           

              {loading && (
                <div class="overlay position-absolute containerLoading">
                  <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                </div>
              )}
            </Card.Body>
          </Card>

          <Card className="borderCream">
            <Card.Header className="ps-1 pe-2 py-0">
              <div className="d-flex  my-2 mx-2">
                <h5 className="text-bold">Media(s) </h5> &nbsp; &nbsp;{" "}
                <Button title="Please click to add media"
                  variant="primary"
                  size="sm"
                  className="rounded-circle roundButton"
                  onClick={() => setShow(true)}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-2 pb-0 pt-1">
              <Row className="circleMembersList">
                {item.items && item.items.length > 0 ? (
                  <>
                    {item.items.map((picture, index) => {
                      return (
                        <Col key={index} xs={12} sm={6} md={4} lg={3}>
                          <GalleryItem
                            picture={picture}
                            loadItem={loadItem}
                            confirmAndRemovePicture={confirmAndRemovePicture}
                            confirmAndArchivePicture={confirmAndArchivePicture}
                            confirmAndRestorePicture={confirmAndRestorePicture}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="warning" className="mb-0">
                    No records found.
                  </Alert>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddGalleryItem
        show={show}
        setShow={setShow}
        albumId={item._id}
        loadItem={loadItem}
      />
    </>
  );
};

export default Details;
