import React, { useReducer, useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import moduleConfig from "../../moduleConfig";
import axios from "../../../../../config/axios";
import {
  addGalleryInitialState,
  addGalleryItemReducer,
} from "../../reducers/addGalleryItemReducer";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import CMSFormImageWithCrop from "../../../../UI/forms/CMSFormImageWithCrop";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import {
  updateInitialState,
  updateReducer,
} from "../../reducers/updateReducer";

const UpdateGalleryItem = ({ show, setShow, loadItem, ItemId }) => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formInitialValues, setFormInitialValues] = useState({});
  // useEffect(() => {
  //   updateDispatch({
  //     type: "FETCH_SUCCESS",
  //     payload: {},
  //   });
  // }, [show]);
  const getItem = async () => {
    if (ItemId) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });
      console.log("called", moduleConfig.url);
      try {
        const res = await axios.get(`fossil-site-gallery/get-item/${ItemId}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data);
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  const onSubmit = async (data, resetForm) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(
        `fossil-site-gallery/update-item/${ItemId}`,
        data
      );

      resetForm();
      handleClose();
      updateDispatch({
        type: "FORM_INITIAL_STAGE",
      });
      console.log(res.data);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media added successfully.`,
        type: "success",
      });
      loadItem();
    } catch (error) {
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    getItem();
  }, [ItemId]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);

  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={yup
        .object({
          title: yup
            .string()
            .required("Required")
            .max(70, "Caption should contain at most 70 characters"),
          type: yup.string().required("Required"),
          sortOrder: yup.string().required("Required"),
          status: yup.string().required("Required"),
        })
        .shape(
          {
            image: yup.mixed().when("video", {
              is: "",
              then: yup
                .mixed()
                .required("Please upload image.")
                .imageTypeCheck(imageRequirement.allowedExtensions)
                .imageSizeCheck(imageRequirement.size)
                .imageDimensionCheck(imageRequirement.dimension),
            }),
            video: yup
              .string()
              .ensure()
              .when("image", {
                is: (image) => !image,
                then: yup.string().url().required("Required"),
              }),
          },
          [["image", "video"]]
        )}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (values, { resetForm }) => {
        const formData = new FormData();
        for (let name in values) {
          if (Array.isArray(values[name])) {
            formData.append(name, JSON.stringify(values[name]));
          } else {
            formData.append(name, values[name]);
          }
        }
        console.log(formData);
        console.log(ItemId);
        onSubmit(formData, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose} size="lg">
          {/* {console.log(form.errors)} */}
          {updateState.isLoading && (
            <div class="overlay ">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {updateState.error && updateState.status === "fetchFailed" ? (
            <BlockErrorMessage error={updateState.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Update Media Gallery Item </Modal.Title>
                </Modal.Header>
                {updateState.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Picture added to gallery successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>
                      {updateState.error && (
                        <FormErrorMessage error={updateState.error} />
                      )}
                      <CMSFormInput
                        name="title"
                        label="Caption"
                        form={form}
                        required={true}
                      />
                      <CMSFormSelect
                        name="type"
                        label="Type"
                        required={true}
                        form={form}
                        options={[
                          { value: "image", label: "Image" },
                          {
                            value: "video",
                            label: "Video",
                          },
                        ]}
                      />
                      {form.values.type === "video" && (
                        <CMSFormInput
                          name="video"
                          label="Video URL"
                          info="Recommended to add iframe URL for video"
                          form={form}
                          // helpMessage={{ message: "Please add iframe URL for video" }}
                          required={true}
                        />
                      )}
                      {form.values.type === "image" && (
                        <CMSFormImageWithCrop
                          label="Image"
                          name="image"
                          info="Recommended dimensions 500*500"
                          required={true}
                          form={form}
                          aspect={4 / 3}
                          outputSize={imageRequirement.dimension}
                        />
                      )}
                      <CMSFormInput
                        name="sortOrder"
                        label="Sort Order"
                        form={form}
                        required={true}
                      />
                      <CMSFormSelect
                        name="status"
                        label="Status"
                        required={true}
                        form={form}
                        options={[
                          { value: "1", label: "Active" },
                          {
                            value: "0",
                            label: "Archived",
                          },
                        ]}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default UpdateGalleryItem;
