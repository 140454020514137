import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormTextArea from "../../UI/forms/CMSFormTextArea";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";

const TestimonialUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);
  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-person-titles`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data.titles, "11111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 && detailsState.data && detailsState.data.titles &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    {console.log(formInitialValues)}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        title: yup.string(),
                        firstName: yup
                          .string()
                          .required("Please enter your first name"),
                        lastName: yup
                          .string()
                          .required("Please enter your last name"),
                        email: yup
                          .string()
                          .email()
                          .required("Please enter your email"),
                        // name: yup.string().required("Please enter your  name"),
                        designation: yup.string(),
                        company: yup.string(),
                        message: yup
                          .string()
                          .required("Please enter a message")
                          .max(300, "maximum 300 characters allowed"),
                        image: yup
                          .mixed()
                          .nullable()
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                        showOnHomePage: yup.string(),
                        status: yup.string(),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { resetForm }) => {
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        onSubmit(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {console.log(form.errors)}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          {/* <CMSFormSelect
                            name="title"
                            label="Title"
                            form={form}
                            options={detailsState.data.titles}
                          /> */}
                          <CMSFormInput
                            name="firstName"
                            label="First Name"
                            required={true}
                            form={form}
                          />

                          <CMSFormInput
                            name="lastName"
                            label="Last Name "
                            required={true}
                            form={form}
                          />
                          <CMSFormInput
                            name="email"
                            label="Email"
                            required={true}
                            form={form}
                          />
                          <CMSFormInput
                            name="company"
                            label="Company"
                            form={form}
                          />
                          <CMSFormInput
                            name="designation"
                            label="Designation"
                            form={form}
                          />

                          <CMSFormImageWithCrop
                            label="Image"
                            name="image"
                            form={form}
                            aspect={1 / 1}
                            outputSize={imageRequirement.dimension}
                          />
                          <CMSFormTextArea
                            name="message"
                            label="Message"
                            form={form}
                            required={true}
                            maxLength={300}
                          />

                          <CMSFormSelect
                            name="showOnHomePage"
                            label="Show on Homepage ?"
                            form={form}
                            options={[

                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          <CMSFormSelect
                            name="status"
                            label="Status"
                            form={form}
                            options={[
                              { label: "Select Status...", value: "" },
                              { label: "Enabled", value: "1" },
                              { label: "Disabled", value: "0" },
                            ]}
                          />
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default TestimonialUpdate;
