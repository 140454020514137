import React from 'react'
import { Alert, Card, Tab } from 'react-bootstrap'
import LookupValueItem from './LookupValueItem'
import AddLookup from './AddLookup'

import { arrayMoveImmutable } from 'array-move';
import SortableList from './SortableList';
const TabContent = ({ lookupState, submitLookupValue, deleteItem, submitNewOrder }) => {
    //console.log(lookups);

    const onSortEnd = (event, lookupId, items) => {

        let newItems = arrayMoveImmutable(items, event.oldIndex, event.newIndex);
        newItems = newItems.map((item, index) => {
            return {
                ...item,
                sortOrder: index + 1
            }
        })
        submitNewOrder(lookupId, newItems)
    };

    return (lookupState && lookupState.data && lookupState.data.lookups && lookupState.data.lookups.length > 0) ?
        <Card>
            <Card.Body className="m-0 p-0 ">
                <Tab.Content>
                    {lookupState.data.lookups.map((lookup, index) => {
                        return <Tab.Pane key={index} eventKey={index}>
                            <h4 className="px-4 py-2 text-red text-bold">{lookup.name}</h4>


                            {(lookup.items && lookup.items.length > 0) && (
                                <SortableList items={lookup.items} deleteItem={deleteItem} onSortEnd={(e) => onSortEnd(e, lookup._id, lookup.items)} />
                            )}

                            {(!(lookup._id === '63da0b6c88894488527aae61' && lookup.items.length >= 3)) && (
                                <AddLookup lookupId={lookup._id} submitLookupValue={submitLookupValue} lookupState={lookupState} />
                            )}

                            {((lookup._id === '63da0b6c88894488527aae61' && lookup.items.length >= 3)) && (
                                <Alert variant="warning" className="m-0">Maximum 3 entries allowed for this section.</Alert>
                            )}

                        </Tab.Pane>
                    })}
                </Tab.Content>
            </Card.Body>
        </Card>
        : null
}

export default TabContent