import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";
import CMSFormRichText from "../../UI/forms/CMSFormRichText";
import CMSFormImage from "../../UI/forms/CMSFormImage";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const NewsEventsCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  let imageRequirement = {
    dimension: {
      width: 586,
      height: 440,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  // const loadData = async () => {
  //     try {
  //         const res = await axios.get(`members/create-data`)//
  //         createDispatch({
  //             type: "FETCH_SUCCESS",
  //             payload: res.data
  //         });
  //     } catch (error) {
  //         createDispatch({
  //             type: "FETCH_FAILURE",
  //             payload: "Date loading failed."
  //         });
  //     }
  // }
  useEffect(() => {
    ///  loadData()
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);

    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        title: "",
                        date: "",
                        shortDescription: "",
                        description: "",
                        image: "",
                        showOnHomePage: "",
                        status: "0",
                      }}
                      validationSchema={yup.object({
                        title: yup.string().required("Required").max(70,"Title must be at most 70 characters"),
                        date: yup.string().required("Required"),
                        shortDescription: yup.string().required("Required").max(100,"Short description must be at most 100 characters"),
                        description: yup.string().required("Required"),
                        image: yup
                        .mixed()
                        .required("Please upload image.")
                        .imageTypeCheck(imageRequirement.allowedExtensions)
                        .imageSizeCheck(imageRequirement.size),
                        showOnHomePage: yup.string().required("Required"),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { resetForm }) => {
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        onSubmit(formData);
                        console.log(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          {/* {console.log(form.errors)} */}
                          <CMSFormInput
                            name="title"
                            label="Title"
                            required={true}
                            form={form}
                          />
                          <CMSFormDate
                            name="date"
                            label="Date"
                            minDate={new Date("2000-1-1")}
                            maxDate={new Date("2040-12-28")}
                            form={form}
                            required={true}
                          />

                          <CMSFormInput
                            name="shortDescription"
                            label="Short Description"
                            form={form}
                            required={true}
                          />
                          <CMSFormRichText
                            name="description"
                            label="Description"
                            form={form}
                            required={true}
                          />

                          <CMSFormImage
                            label="Image"
                            name="image"
                            info="Recommended dimensions 586*440"
                            form={form}
                            required={true}
                            
                          />
                            <CMSFormSelect
                            name="showOnHomePage"
                            label="Show On Home Page ?"
                            required={true}
                            form={form}
                            options={[
                              // { label: "Select Status...", value: "" },
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { label: "Select Status...", value: "" },
                              { label: "Enabled", value: "1" },
                              { label: "Disabled", value: "0" },
                            ]}
                          />

                          <button
                            type="submit"
                            onClick={() => form.handleSubmit}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default NewsEventsCreate;
