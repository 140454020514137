import React, { useState, useReducer } from "react";
import Button from "react-bootstrap/Button";
import { Form, Formik } from "formik";
import { formReducer, formInitialState } from "../../../reducers/formReducer";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import FormErrorMessage from "../errorMessages/FormErrorMessage";
import CMSFormInput from "../forms/CMSFormInput";
import CMSFormTextArea from "../forms/CMSFormTextArea";
import BlockSuccessMessage from "../errorMessages/BlockSuccessMessage";
import CMSFormMultiSelect from "../forms/CMSFormMultiSelect";

function SendEmailToAll({ buttonTitle, apiURL }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);

  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${apiURL}/send-email-to-all`, data);

      //console.log(res)
      if (res && res.status && res.status === 201) {
        resetForm();
        formDispatch({
          type: "FORM_SUBMISSION_SUCCESS",
          payload: res.data,
        });
        handleClose();
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `Emails queued successfully.`,
          type: "success",
        });
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      } else {
        formDispatch({
          type: "FORM_SUBMISSION_FAILED",
          payload: "Email sending failed.",
        });
        setTimeout(() => {
          formDispatch({
            type: "FORM_INITIAL_STAGE",
          });
        }, 5000);
      }
    } catch (error) {
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  return (
    <>
      <span className="w-100 h-100" onClick={handleShow}>{buttonTitle}</span>
      <Formik
        initialValues={{
          sendEmailToAll: "1",
          subject: "",
          message: "",
        }}
        validationSchema={yup.object({
          sendEmailToAll: yup.string().required("Required"),
          subject: yup.string().required("Required").max(100),
          message: yup.string().required("Required"),
        })}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { resetForm }) => {
          onSubmit(values, resetForm);
          console.log(values);
        }}
      >
        {(form) => (
          <Modal show={show} onHide={handleClose}>
            {formState.isFormSubmitting && (
              <div class="overlay">
                <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
              </div>
            )}
            <Form >
              <Modal.Header closeButton>
                <Modal.Title>Send Email To All</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {formState.hasSubmissionError && (
                  <FormErrorMessage error={formState.formSubmissionError} />
                )}

                {/* <CMSFormInput
                      name="email"
                      type="email"
                      label="Email"
                      value={email}
                      form={form}
                      disabled
                    /> */}
                {/* <CMSFormDisplaySelected
                      name="members"
                      label="All Members"
                      placeholder=""
                      form={form}
                      options={['aaa@aaa.com', 'abc@abc.com', 'ac@abc.com', 'ac@abc.com', 'a@abc.com', 'abc@abc.com', 'abc@abc.com']}
                      displayValue="fullName"
                    /> */}
                <CMSFormInput
                  name="subject"
                  type="text"
                  label="Subject"
                  form={form}
                />

                <CMSFormTextArea name="message" label="Message" form={form} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit" onClick={form.handleSubmit}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
}

export default SendEmailToAll;
