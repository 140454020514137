import React from "react";

const ModuleDisplayPhone = ({ value, text }) => {
  return (
    <a className={`${text === "dark" ? "text-dark" :text === "secondary" ? "text-secondary" : ""}`} href={`tel:${value}`}>
      {" "}
      {value}{" "}
    </a>
  );
};

export default ModuleDisplayPhone;
