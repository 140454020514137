import React from "react";
import { Badge } from "react-bootstrap";
const ModuleDisplayBoolean = ({
  value,
  trueValue = "Yes",
  falseValue = "No",
  trueColor = "success",
  falseColor = "warning",
}) => {
  return (
    <>
      {value === "1" || value === 1 ? (
        <Badge bg={trueColor}>{trueValue}</Badge>
      ) : (
        <Badge bg={falseColor} text="dark">
          {falseValue}
        </Badge>
      )}
    </>
  );
};

export default ModuleDisplayBoolean;
