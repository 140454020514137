import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";

const BannersUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-languages`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data.languages, "11111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      delete updateState.data.item.linkText;
      setFormInitialValues(updateState.data.item);
      console.log(updateState.data.item);
      //};
    }
  }, [updateState.data]);
  let imageRequirement = {
    dimension: {
      width: 1366,
      height: 648,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  let videoRequirement = {
    dimension: {
      width: 1366,
      height: 648,
    },
    allowedExtensions: ["video/mp4"],
    size: 1024 * 1024 * 20,
  };
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            detailsState.data &&
            detailsState.data.languages &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        title: yup
                          .string()
                          .required("Required")
                          .max(
                            50,
                            "Title should contain at most 50 characters"
                          ),
                        mobileTitle: yup
                          .string()

                          .max(
                            35,
                            "Title should contain at most 35 characters"
                          ),
                        linkTitle: yup
                          .string()
                          .max(
                            35,
                            "link title should contain at most 35 characters"
                          ),
                        type: yup.string().required("Required"),
                        image: yup.mixed().when("type", {
                          is: "image",
                          then: yup
                            .mixed()
                            .required("Please upload image.")
                            .imageTypeCheck(imageRequirement.allowedExtensions)
                            .imageSizeCheck(imageRequirement.size)
                            .imageDimensionCheck(imageRequirement.dimension),
                        }),
                        video: yup.mixed().when("type", {
                          is: "video",
                          then: yup
                            .mixed()
                            .required("Please upload video.")
                            .videoTypeCheck(videoRequirement.allowedExtensions)
                            .imageSizeCheck(videoRequirement.size),
                        }),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={async (values, { resetForm }) => {
                        var newData = values;
                        if (values.type === "video") {
                          newData = { ...values, image: "" };
                        } else if (values.type === "image") {
                          newData = { ...values, video: "" };
                        }
                        const formData = new FormData();
                        for (let name in newData) {
                          if (Array.isArray(newData[name])) {
                            formData.append(
                              name,
                              JSON.stringify(newData[name])
                            );
                          } else {
                            formData.append(name, newData[name]);
                          }
                        }
                        console.log(formData);
                        console.log(newData);
                        onSubmit(formData, resetForm);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {console.log(form.errors)}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}
                          <CMSFormInput
                            name="title"
                            label="Title"
                            required={true}
                            form={form}
                          />

                          <CMSFormInput
                            name="mobileTitle"
                            label="Title For Mobile View"
                            required={true}
                            form={form}
                          />


                          <CMSFormSelect
                            name="type"
                            label="Type"
                            required={true}
                            form={form}
                            options={[
                              { value: "image", label: "Image" },
                              {
                                value: "video",
                                label: "Video",
                              },
                            ]}
                          />
                          {form.values.type === "image" && (
                            <CMSFormImageWithCrop
                              label="Image"
                              name="image"
                              info="Recommended dimensions 1366*648"
                              required={true}
                              form={form}
                              aspect={683 / 324}
                              outputSize={imageRequirement.dimension}
                            />
                          )}
                          {form.values.type === "video" && (
                            <CMSFormFile
                              name="video"
                              label="Video"
                              info="Recommended dimensions 1366*648"
                              required={true}
                              form={form}
                            />
                          )}
                          <CMSFormInput
                            name="link"
                            label="Link"
                            required={false}
                            form={form}
                          />
                          <CMSFormInput
                            name="linkTitle"
                            label="Link Title"
                            required={false}
                            form={form}
                          />
                          <CMSFormInput
                            name="sortOrder"
                            label="Sort Order"
                            required={false}
                            form={form}
                          />

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { label: "Select Status...", value: "" },
                              { label: "Enabled", value: "1" },
                              { label: "Disabled", value: "0" },
                            ]}
                          />

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default BannersUpdate;
