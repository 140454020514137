import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import fossilIcon from "../../../../../images/fossil.jpg";
import ReactFancyBox from "react-fancybox";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";
const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];
    if (item.saleableOptions && item.saleableOptions.code) {
      items = [
        ...items,
        { text: "Item Code", value: item.saleableOptions.code, type: "text" },
      ];
    }
    // if (item.saleableOptions && item.saleableOptions.price) {
    //   items = [...items, { text: "Price", value: item.saleableOptions.price, type: "text" }];
    // }
    if (item.saleableOptions && item.saleableOptions.oldPrice) {
      items = [
        ...items,
        {
          text: "Old Price",
          value: item.saleableOptions.oldPrice + " " + getCurrencyCode(),
          type: "text",
        },
      ];
    }

    if (item.category) {
      items = [
        ...items,
        { text: "Category", value: item.category, type: "text" },
      ];
    }
    if (item.subCategory) {
      items = [
        ...items,
        { text: "Sub Category", value: item.subCategory, type: "text" },
      ];
    }
    if (item.ageOrOriginDate) {
      items = [
        ...items,
        {
          text: "Age / Origin Date",
          value: item.ageOrOriginDate,
          type: "text",
        },
      ];
    }
    if (item.site) {
      items = [...items, { text: "Site", value: item.site, type: "text" }];
    }

    if (item.dimension) {
      items = [
        ...items,
        { text: "Dimension", value: item.dimension, type: "text" },
      ];
    }
    if (item.relatedMaterial) {
      items = [
        ...items,
        {
          text: "Related Material",
          value: item.relatedMaterial,
          type: "link",
          download: true,
          title: "Please click to download",
        },
      ];
    }
    // if (item.oldId) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Old Id",
    //       value: item.oldId,
    //       type: "text",
    //     },
    //   ];
    // }

    return items;
  };

  return (
    <Card className="item mb-2 borderCream ">
   
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div>

            <div className="d-flex flex-column ms-1 mt-1">
              <span className="m-0 text-bold memberName text-red">
                {item.title}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId text-boldItalic text-info mt-1">
                  {item.category}
                </span>
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools endColumn mb-1 pt-1">
          <div>
            <span className="badge badge-light me-1 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Enabled"
                  falseValue="Disabled"
                />{" "}
              </span>
            </span>
            {item.createdAt && (
              <span class="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
         

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {/* {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )} */}
            <span
              title={`Delete ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};

export default Item;
