import React, { useState, useReducer, useEffect } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import moduleConfig from "../../moduleConfig";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import GalleryItem from "./GalleryPicture";
import ReactFancyBox from "react-fancybox";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import AddFossilPicture from "./AddFossilPicture";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";
import { detailsInitialState, detailsReducer } from "../../reducers/detailsReducer";
import parse from "html-react-parser"
const Details = ({ item, pictures, detailsDispatch, loadItem, loading }) => {
  const { confirm } = useConfirm();
  const [show, setShow] = useState(false);
  const [galleryState, galleryDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const confirmAndRemovePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to remove this picture?`
    );

    if (isConfirmed) {
      await removePicture(pictureId);
    }
  };

  const removePicture = async (pictureId) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.delete(`fossil-gallery/${pictureId}`, {
        albumId: item._id,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndArchivePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to archive this media?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "0");
    }
  };

  const confirmAndRestorePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to restore this media?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "1");
    }
  };

  const changeStatusPicture = async (pictureId, status) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`fossil-gallery/change-status`, {
        id: pictureId,
        status,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media ${status === "1" ? "restored" : "archived"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };
  const loadGallery = async () => {
    if (item._id) {
      galleryDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`fossil-gallery/${item._id}`); //
        galleryDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data, "154646");
      } catch (error) {
        galleryDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadGallery();
  }, [item._id]);
  const getItems = () => {
    let items = [];
    if (item.type === "Saleable") {
      if (item.saleableOptions && item.saleableOptions.code) {
        items = [
          ...items,
          { text: "Item Code", value: item.saleableOptions.code, type: "text" },
        ];
      }
      // if (item.saleableOptions && item.saleableOptions.price) {
      //   items = [...items, { text: "Price", value: item.saleableOptions.price, type: "text" }];
      // }
      if (item.saleableOptions && item.saleableOptions.oldPrice) {
        items = [
          ...items,
          {
            text: "Old Price",
            value: item.saleableOptions.oldPrice + " " + getCurrencyCode(),
            type: "text",
          },
        ];
      }

    }
    if (item.category) {
      items = [
        ...items,
        { text: "Category", value: item.category, type: "text" },
      ];
    }
    if (item.subCategory) {
      items = [
        ...items,
        { text: "Sub Category", value: item.subCategory, type: "text" },
      ];
    }
    if (item.ageOrOriginDate) {
      items = [
        ...items,
        {
          text: "Age / Origin Date",
          value: item.ageOrOriginDate,
          type: "text",
        },
      ];
    }
    if (item.site) {
      items = [...items, { text: "Site", value: item.site, type: "text" }];
    }

    if (item.dimension) {
      items = [
        ...items,
        { text: "Dimension", value: item.dimension, type: "text" },
      ];
    }
    if (item.matrix) {
      items = [
        ...items,
        { text: "Matrix", value: item.matrix, type: "text" },
      ];
    }
    if (item.relatedMaterial) {
      items = [
        ...items,
        {
          text: "Related Material",
          value: process.env.REACT_APP_BACKEND_URL + 'public/' + item.relatedMaterial,
          type: "link",
          download: true,
          title: "Please click to download",
        },
      ];
    }
    // if (item.oldId) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Old Id",
    //       value: item.oldId,
    //       type: "text",
    //     },
    //   ];
    // }

    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="ms-1 me-2">
                    <div className=" position-relative  ">
                      <div className="fossilImage">
                        <ReactFancyBox
                          thumbnail={
                            process.env.REACT_APP_BACKEND_URL +
                            "public/" +
                            item.image
                          }
                          image={
                            process.env.REACT_APP_BACKEND_URL +
                            "public/" +
                            item.image
                          }
                          caption={`${item.genus} fossil`}
                        />
                      </div>
                      {(item.status === "0" || item.status === 0) && (
                        <i className="fas fa-ban position-absolute ms-1 mb-2 banned2"></i>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column ms-1 mt-1">
                    <span className="m-0 text-bold memberName text-red">
                      {item.genus}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      {item.type === "Saleable" ? (
                        <span className="membershipId text-boldItalic text-info mt-1">
                          {item.type}
                        </span>
                      ) : (
                        <span className="membershipId text-boldItalic mt-1">
                          {item.type}
                        </span>
                      )}
                    </span>
                  </div>
                  {item.saleableOptions && item.saleableOptions.price && (
                    <div className="d-flex flex-column  ms-1">
                      <h4 className="my-0 me-0 ms-1 text-bold text-primaryCream">
                        {" "}
                        ({item.saleableOptions.price} {getCurrencyCode()})
                      </h4>
                    </div>
                  )}
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-1 mt-xl-2 mt-lg-2 mt-md-2 pt-xl-1 pt-lg-1 pt-md-1 pt-0u">
                  {console.log(item.collectionOptions)}
                  {item.type == "Collection" && item.collectionOptions && (
                    <>
                      {item.collectionOptions.museumQuality === "1" ||
                        item.collectionOptions.museumQuality === 1 ? (
                        <span className="badge limeYellow me-1 ">Museum Quality</span>
                      ) : null}
                      {item.collectionOptions.collectible === "1" ||
                        item.collectionOptions.collectible === 1 ? (
                        <span className="badge orange me-1 ">Collectible</span>
                      ) : null}
                      {item.collectionOptions.excellentPreservation === "1" ||
                        item.collectionOptions.excellentPreservation === 1 ? (
                        <span className="badge peach me-1 ">
                          Excellent Preservation
                        </span>
                      ) : null}
                      {item.collectionOptions.exceptionalRarity === "1" ||
                        item.collectionOptions.exceptionalRarity === 1 ? (
                        <span className="badge brown me-1 ">Exceptional Rarity</span>
                      ) : null}
                      {item.collectionOptions.fossilOfTheMonth === "1" ||
                        item.collectionOptions.fossilOfTheMonth === 1 ? (
                        <span className="badge magenda me-1 ">
                          Fossil Of The Month
                        </span>
                      ) : null}
                      {item.collectionOptions.newDiscovery === "1" ||
                        item.collectionOptions.newDiscovery === 1 ? (
                        <span className="badge badge-info me-1 ">New Discovery </span>
                      ) : null}
                      {item.collectionOptions.newSpecies === "1" ||
                        item.collectionOptions.newSpecies === 1 ? (
                        <span className="badge skyBlue me-1 ">New Species</span>
                      ) : null}
                    </>
                  )}

                  {item.type == "Saleable" && item.saleableOptions && (
                    <>
                      {item.saleableOptions.exclusiveItem === "1" ||
                        item.saleableOptions.exclusiveItem === 1 ? (
                        <span className="badge badge-info me-1">Exclusive Item</span>
                      ) : null}
                      {item.saleableOptions.discounted > 0 ||
                        item.saleableOptions.discounted > 0 ? (
                        <span className="badge teal me-1 ">Discounted</span>
                      ) : null}
                      {item.saleableOptions.showOnHomePage === "1" ||
                        item.saleableOptions.showOnHomePage === 1 ? (
                        <span className="badge homePageShow me-1 ">
                          Showing on Home Page
                        </span>
                      ) : null}
                      {item.saleableOptions.soldOut === "1" ||
                        item.saleableOptions.soldOut === 1 ? (
                        <span className="badge badge-dark me-1">Sold Out</span>
                      ) : null}
                    </>
                  )}
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span>
                  {item.createdAt && (
                    <span class="badge badge-light me-1 text-dark">
                      Created Date :{" "}
                      <span class="text-secondary">
                        <ModuleDisplayDate value={item.createdAt} />{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </Card.Header>
            {console.log(item)}
            <Card.Body className="px-2 pt-1 pb-0">
              <Row>
                <Col className="mt-1 mb-1 ">
                  <StatsListing items={getItems()} />
                  {item.description && (
                    <>
                      <Alert
                        className="lightGray"
                        variant="secondary mb-0 pb-1 px-1 pt-0 messageWidth"
                      >
                        <span className="m-0 text-bold text-dark font-small">
                          Description:
                        </span>
                        <p className="font-small me-1 text-dark mb-0">
                          {parse(item.description)}
                        </p>
                      </Alert>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="borderCream">
            <Card.Header className="ps-1 pe-2 py-0">
              <div className="d-flex  my-2 mx-2">
                <h5 className="text-bold">Fossil Gallery </h5> &nbsp; &nbsp;{" "}
                <Button
                  title="Please click to add media"
                  variant="primary"
                  size="sm"
                  className="rounded-circle roundButton"
                  onClick={() => setShow(true)}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="px-2 pb-0 pt-0">
              <Row className="circleMembersList">
                {galleryState.data.items && galleryState.data.items.length > 0 ? (
                  <>
                    {galleryState.data.items.map((picture, index) => {
                      return (
                        <Col
                          key={index}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          className="pt-1"
                        >
                          <GalleryItem
                            picture={picture}
                            loadItem={loadItem}
                            confirmAndRemovePicture={confirmAndRemovePicture}
                            confirmAndArchivePicture={confirmAndArchivePicture}
                            confirmAndRestorePicture={confirmAndRestorePicture}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="warning" className="mb-0">
                    No records found.
                  </Alert>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddFossilPicture
        show={show}
        setShow={setShow}
        fossilId={item._id}
        loadItem={loadItem}
      />
    </>
  );
};

export default Details;
