import React, { useReducer, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig"
import { detailsReducer, detailsInitialState } from "./reducers/detailsReducer";
import Details from "./partials/galleryPicture/Details";
import { Button, Col, Row } from "react-bootstrap";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../config/toastNotification'
import RecentComments from "../../UI/shared/recentComments/RecentComments";
import RecentLikes from "../../UI/shared/recentLikes/RecentLikes";


let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "Details",
    }
};

const MediaGalleryDetails = () => {
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [detailsState, detailsDispatch] = useReducer(detailsReducer, detailsInitialState)
    const { confirm } = useConfirm();
    let { id } = useParams();

    const loadItem = async () => {
        if (id) {
            detailsDispatch({
                type: "FETCH_REQUEST"
            });
            try {
                const res = await axios.get(`media-gallery-items/${id}`)//
                detailsDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data
                });
                console.log(res.data)
            } catch (error) {
                detailsDispatch({
                    type: "FETCH_FAILURE",
                    payload: error
                });
            }
        }
    }
    const confirmAndArchivePicture = async (pictureId) => {
        const isConfirmed = await confirm(`Do you really want to archive this picture?`);

        if (isConfirmed) {
            await changeStatusPicture(pictureId, "0")
        }
    }

    const confirmAndRestorePicture = async (pictureId) => {
        const isConfirmed = await confirm(`Do you really want to restore this picture?`);

        if (isConfirmed) {
            await changeStatusPicture(pictureId, "1")
        }
    }

    const changeStatusPicture = async (pictureId, status) => {

        console.log("In block Item Function");
        detailsDispatch({
            type: "BLOCK_REQUEST"
        });

        try {
            const res = await axios.post(`gallery-pictures/change-status/${pictureId}`, { status, albumId: detailsState.data.item.albumId })
            loadItem();
            // detailsDispatch({
            //     type: "DELETE_SUCCESS"
            // });
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Picture ${(status === "1") ? "restored" : "archived"} successfully.`,
                type: "success",
            });
        } catch (error) {
            detailsDispatch({
                type: "BLOCK_FAILURE",
                payload: error
            });

            setTimeout(
                () => {
                    detailsDispatch({
                        type: "REMOVE_ERROR"
                    })
                }
                , 5000)
        }
    }

    useEffect(() => {
        loadItem();
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details
        ])
    }, [id])



    return (<DashboardLayout >


        {(detailsState.isFetching)
            ? <ContainerLoading />
            : <>


                {(detailsState.hasError)

                    ? <BlockErrorMessage error={detailsState.error} />
                    : <>

                        {
                            (detailsState.data.item) &&
                            <>

                                <ModuleHeader
                                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                                    moduleUrl={moduleConfig.url}
                                ></ModuleHeader>

                                <div className="border mb-1 p-1 bg-light text-white rounded">


                                    {(detailsState.data.item.status === "1" || detailsState.data.item.status === 1) &&
                                        <Link title="Archive Item" onClick={() => confirmAndArchivePicture(detailsState.data.item._id)} className="btn btn-primary  border me-1" >
                                            <i className="fas fa-trash text-white me-1"></i> <span className="text-white">Archive</span>
                                        </Link>
                                    }

                                    {(detailsState.data.item.status === "0" || detailsState.data.item.status === 0) &&
                                        <Link title="Restore Item" onClick={() => confirmAndRestorePicture(detailsState.data.item._id)} className="btn btn-primary  border me-1" >
                                            <i className="fas fa-arrow-up text-white me-1"></i> <span className="text-white">Restore</span>
                                        </Link>
                                    }
                                </div>

                                {/* <Details
                                    picture={detailsState.data.item}
                                /> */}


                                {/* <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <RecentComments
                                            moduleUrl="gallery-picture-comments"
                                            parentId={detailsState.data.item._id}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <RecentLikes
                                            moduleUrl="gallery-picture-likes"
                                            parentId={detailsState.data.item._id}
                                        />
                                    </Col>
                                </Row> */}

                            </>
                        }


                    </>
                }
            </>
        }
    </DashboardLayout>);
}

export default MediaGalleryDetails;


