import React, { useState, useReducer, useEffect, useContext } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import queryString from "query-string";
import moduleConfig from "./moduleConfig";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import useConfirm from "../../../HOC/custom/useConfirm";
import ListingFooter from "./partials/listing/ListingFooter";
import SearchArea from "./partials/listing/SearchArea";
import { listingReducer, listingInitialState } from "./reducers/listingReducer";
import ModuleDisplayBoolean from "../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDate from "../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import AddPaymentRequestModal from "./partials/listing/AddPaymentRequestModal";
import ModuleDisplayEmail from "../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";


const PaymentRequestListing = () => {
  let { id } = useParams();

  const [showAddPaymentRequestModal, setShowAddPaymentRequestModal] = useState(false);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState(
    queryString.parse(search)
  );
  if (appliedFilters.limit) {
    moduleConfig.perPageItems = appliedFilters.limit;
  }

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
  };

  let history = useNavigate();

  const setFilter = (name, value) => {
    console.log(`setFilter called with ${name}, ${value}`);
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    if (value) {
      newAppliedFilters[name] = value;
    } else {
      delete newAppliedFilters[name];
    }

    if (name !== "page") {
      delete newAppliedFilters["page"];
    }

    if (name === "limit" || name === "page") {
      setIsCheck([]);
      setIsCheckAll(false);
    }

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };
  const { confirm } = useConfirm();
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`${moduleConfig.url}${search}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const confirmAndSend = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to send the payment request link?`
    );

    if (isConfirmed) {
      console.log("send")
      onSendLink(id);
    }
  };
  const onSendLink = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.get(`payment-requests/send-email/${data}`); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
     
      // setShowAddPaymentRequestModal(false);
      if (res.data.emailSent === true) {
        console.log(res.data)
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `Email sent successfully.`,
          type: "success",
        });

        loadItems();
      }
      if (res.data.emailSent === false) {

        toast.addNotification({
          ...toastNotification,
          title: "Error!",
          message: `Something went wrong!Try again later.`,
          type: "danger",
        });
      }
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);
      toast.addNotification({
        ...toastNotification,
        title: "Error!",
        message: `Something went wrong!Try again later.`,
        type: "danger",
      });
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      // setShowAddPaymentRequestModal(false);
      // loadItems();
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const resetFilters = () => {
    //console.log(`resetFilters called`)
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    moduleConfig.perPageItems = 10;
    delete newAppliedFilters["limit"];
    delete newAppliedFilters["status"];
    delete newAppliedFilters["q"];
    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    //console.log(newAppliedFilters);
    setAppliedFilters(newAppliedFilters);
  };


  useEffect(() => {
    loadItems();
  }, [search]);

  useEffect(() => {
    setBreadcrumb([breadcrumbs.listing]);
  }, []);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    if (listingState.data.items && listingState.data.items.length) {
      setIsCheck(listingState.data.items.map((li) => li._id));
    }

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckBoxClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    setIsCheckAll(false);
  };

  const download = (type) => {
    axios
      .post(`/payment-requests/download`, {
        type,
        ids: isCheck,
      })
      .then(function (response) {
        console.log(response);

        let blob = new Blob([response.data], { type: "text/csv" }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = type + "-payment-requests.csv";
        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      });
  };
  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
      payload: '',
    });
  }, [])

  return (
    <DashboardLayout>
      {/* {listingState.isLoading && <ContainerLoading />} */}
      {/* {updateState.isLoading && <ContainerLoading />} */}
      {listingState.status !== "fetching" && (
        <>
          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : (
            <>
              <ModuleHeader
                moduleTitle={moduleConfig.title}
                moduleUrl={moduleConfig.url}
              >
              </ModuleHeader>

              {updateState.isLoading && <ContainerLoading />}

              <div className="border mb-1 p-1 bg-light text-white rounded text-right">
                <Link
                  title="Request Payment"
                  onClick={() =>
                    setShowAddPaymentRequestModal(true)
                  }
                  className="btn btn-primary  border me-1"
                >

                  <i className="fas fa-dollar-sign text-white "></i>{" "}
                  <span className="text-white">Request Payment</span>
                </Link>

                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 && (
                    <Dropdown className="btn ">
                      <Dropdown.Toggle
                        variant="primary"
                        className="border"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-download"> </i> Download &nbsp;
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => download("all")}>
                          All Payments
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => download("paid")}>
                          Paid Payments
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => download("pending")}>
                          Pending Payments
                        </Dropdown.Item>
                        <Dropdown.Item
                          disabled={
                            isCheck && isCheck.length > 0 ? false : true
                          }
                          onClick={() => download("selected")}
                        >
                          Selected Item(s)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}



              </div>

              <div className="listingPage">
                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 ? (
                  <>
                    {/* Search Area */}
                    <SearchArea
                      moduleConfig={moduleConfig}
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      resetFilters={resetFilters}
                    />

                    {/* Listing */}
                    <section className="content listing">
                      {listingState.error && (
                        <FormErrorMessage error={listingState.error} />
                      )}

                   

                      {!listingState.data.items ||
                        listingState.data.items.length <= 0 ? (
                        <Alert variant="warning">
                          No records found with selected filters.{" "}
                          <Alert.Link href="#" onClick={() => resetFilters()}>
                            <i className="fas fa-times" /> Reset Filters
                          </Alert.Link>
                          .
                        </Alert>
                      ) : (
                        <>
                          {listingState.data.items &&
                            listingState.data.items.length && (
                              <>
                                <div className="registrationTable m-0 p-0 mt-2 overflow-auto">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr>
                                        <th>
                                          <input
                                            className="selectAll"
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            onChange={handleSelectAll}
                                            checked={isCheckAll}
                                          />
                                        </th>
                                        <th>#</th>
                                        <th>Email</th>

                                        <th className="text-center">Amount</th>

                                        <th className="text-center">
                                          Payment Status
                                        </th>
                                        <th>Payment Date</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listingState.data.items.map(
                                        (item, index) => {
                                          return (
                                            <tr index={index}>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  className="selectAll"
                                                  id={item._id}
                                                  name={item._id}
                                                  onChange={handleCheckBoxClick}
                                                  checked={isCheck.includes(
                                                    item._id
                                                  )}
                                                />
                                              </td>
                                              <td>{index + 1}</td>
                                              <td>
                                                <ModuleDisplayEmail value={item.email} />
                                              </td>

                                              <td className="text-center">
                                                {" "}
                                                {item.amount} {getCurrencyCode()}
                                              </td>

                                              <td className="text-center">
                                                <ModuleDisplayBoolean
                                                  value={item.paymentStatus}
                                                  trueValue="Paid"
                                                  falseValue="Pending"
                                                />
                                              </td>
                                              <td>
                                                <ModuleDisplayDate
                                                  value={item.paymentDate}
                                                />
                                              </td>
                                              <td
                                                className={`text-center record-actions  MoreItem`}
                                              >
                                                {item.paymentStatus === "0" ?
                                                  <Button onClick={() => confirmAndSend(item._id)} className={item.emailSentOnce === "1" ?"btn btn-xs btn-secondary":"btn btn-xs btn-primary "}>{item.emailSentOnce === "1" ? "Resend Link" : "Send Link"}</Button> : "-"}

                                              </td>
                                              <td
                                                className={`text-center record-actions  MoreItem`}
                                              >
                                                <Link
                                                  title="View Item"
                                                  to={`/${moduleConfig.url}/${item._id}`}
                                                  className="btn btn-outline-secondary btn-sm"
                                                >
                                                  <i className="fas fa-eye"></i>
                                                </Link>

                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </section>

                    <ListingFooter
                      listingState={listingState}
                      moduleConfig={moduleConfig}
                      isCheck={isCheck}
                      setFilter={setFilter}
                    />
                  </>
                ) : (
                  <Alert variant="warning">No records found.</Alert>
                )}
              </div>


              <AddPaymentRequestModal
                show={showAddPaymentRequestModal}
                setShow={setShowAddPaymentRequestModal}
                loadItems={loadItems}
                onSendLink={onSendLink}
                updateStateValue={updateState}
              />

            </>
          )}
        </>
      )
      }
    </DashboardLayout >
  );
};

export default PaymentRequestListing;
