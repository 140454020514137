import React, { useReducer, useEffect } from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";
import ModuleDynamicFilterDropdown from "../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown";
import axios from "../../../../../config/axios";
import {
  detailsInitialState,
  detailsReducer,
} from "../../../publications/reducers/detailsReducer";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const sitesPlaceholder = [{ label: "Select Sites", value: "" }];
  const categoryPlaceholder = [{ label: "Select Category", value: "" }];
  const yearPlaceholder = [{ label: "Select Year", value: "" }];
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(`fossils/available-filters`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      console.log(error);
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i], value: item[i] });
    }

    return array;
  };
  return (
    <>
      {(appliedFilters.limit ||
        appliedFilters.q ||appliedFilters.tag ||
        appliedFilters.category ||
        appliedFilters.site ||
        appliedFilters.status) && (
        <div div className=" text-right ">
          <span className="resetFilters" onClick={() => resetFilters()}>
            <i className="fas fa-times" /> Reset Filters
          </span>
        </div>
      )}
      {detailsState.data && detailsState.data.availableFilters && (
        <div className="position-relative">
          <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
            <Card.Body className=" p-0 m-0">
              <Row className="mb-0">
                {moduleConfig.hasPagination === true &&
                  moduleConfig.perPageItemsOptions &&
                  moduleConfig.perPageItemsOptions.length > 0 && (
                    <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                      <ModuleRecordsPerPageDropdown
                        selectedPerPageItems={moduleConfig.perPageItems}
                        perPageItemsOptions={moduleConfig.perPageItemsOptions}
                        setFilter={setFilter}
                      />
                    </Col>
                  )}
                <Col className="col-xl-4 col-lg-4 col-md-4  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                  <ModuleDynamicFilterDropdown
                    filterField="tag"
                    setFilter={setFilter}
                    appliedFilters={appliedFilters}
                    options={[
                      {
                        label: "Select Tag",
                        value: "",
                      },
                      {
                        label: "Collectible",
                        value: "collectible",
                      },
                      {
                        label: "Excellent Preservation",
                        value: "excellentPreservation",
                      },
                      {
                        label: "Exceptional Rarity",
                        value: "exceptionalRarity",
                      },
                      {
                        label: "Fossil Of The Month",
                        value: "fossilOfTheMonth",
                      },
                      {
                        label: "Museum Quality",
                        value: "museumQuality",
                      },
                      {
                        label: "New Discovery",
                        value: "newDiscovery",
                      },
                      {
                        label: "New Species",
                        value: "newSpecies",
                      },
                    ]}
                  />
                </Col>
                {detailsState.data.availableFilters.categories && (
                  <Col className="col-xl-4 col-lg-4 col-md-4  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="category"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={categoryPlaceholder.concat(
                        handleFormatArray(
                          detailsState.data.availableFilters.categories
                        )
                      )}
                    />
                  </Col>
                )}
                {detailsState.data.availableFilters.sites && (
                  <Col className="col-xl-4 col-lg-4 col-md-4  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="site"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={sitesPlaceholder.concat(
                        handleFormatArray(
                          detailsState.data.availableFilters.sites
                        )
                      )}
                    />
                  </Col>
                )}

                {detailsState.data.availableFilters.years && (
                  <Col className="col-xl-4 col-lg-4 col-md-4  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                    <ModuleDynamicFilterDropdown
                      filterField="year"
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      options={yearPlaceholder.concat(
                        handleFormatArray(
                          detailsState.data.availableFilters.years
                        )
                      )}
                    />
                  </Col>
                )}
                {moduleConfig.hasStatusSearch === true && (
                  <Col className="col-xl-4 col-lg-4 col-md-4  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                    <ModuleStatusDropdown
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      statusOptions={[
                        {
                          label: "Select Status...",
                          value: "all",
                        },
                        {
                          label: "Enabled",
                          value: "1",
                        },
                        {
                          label: "Disabled",
                          value: "0",
                        },
                      ]}
                    />
                  </Col>
                )}
                {moduleConfig.hasSearch === true && (
                  <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                    <ModuleSearchBox
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default SearchArea;
