import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig";
let moduleConfig = {};
moduleConfig = {
  ...moduleDefaultConfig,
};

moduleConfig.title = "Testimonials";
moduleConfig.singleItemTitle = "Testimonial";
moduleConfig.url = "testimonials";
moduleConfig.moduleType = {
  listing: "block",
  view: "block",
};

moduleConfig.bulkActions = [
  {
    displayName: "Enable Selected",
    value: "enabled",
  },
  {
    displayName: "Disable Selected",
    value: "disabled",
  },
  {
    displayName: "Delete Selected",
    value: "delete",
  },
];

export default moduleConfig;
