import React, { useReducer, useEffect } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import {
  formInitialState,
  formReducer,
} from "../../../../../reducers/formReducer";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormTextArea from "../../../../UI/forms/CMSFormTextArea";
import { Button } from "react-bootstrap";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import axios from "../../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import moduleConfig from "../../moduleConfig";

const OrderStatusModal = ({ show, setShow, loadItem, item, id, status }) => {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/change-status`, data);
      formDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      handleClose();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Action done successfully.`,
        type: "success",
      });
      resetForm();
      formDispatch({
        type: "FORM_INITIAL_STAGE",
      });
      loadItem();
    } catch (error) {
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });

      setTimeout(() => {
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  const getValidationSchemaCancel = () => {
    return yup.object({
      adminComments: yup.string().required("Required").max(200),
    });
  };
  const getValidationSchemaComplete = () => {
    return yup.object({
      adminComments: yup.string().nullable().max(200),
    });
  };

  const getInitialValues = () => {
    let returnObj = {
      id: id,
      status: status.toString(),
      adminComments: item.adminComments ? item.adminComments : "",
    };

    return returnObj;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={
        status === "1" || status === 1
          ? getValidationSchemaComplete()
          : getValidationSchemaCancel()
      }
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(values, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose}>
          {formState.isFormSubmitting && (
            <div class="overlay">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {/* {(formState.isFormSubmitting) && <ContainerLoading />} */}
          <Form onSubmit={form.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {status === "1" || status === 1
                  ? "Complete Order"
                  : "Cancel Order"}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {formState.hasSubmissionError && (
                <FormErrorMessage error={formState.formSubmissionError} />
              )}

              <CMSFormTextArea
                name="adminComments"
                label="Admin Comments"
                form={form}
                required={status === "1" || status === 1 ? false : true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default OrderStatusModal;
