import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CMSFormInput = (props) => {
  const { name, label, form, info,disabled ,required, helpMessage, ...rest } = props;

  return (
    <div className="form-group ">
      {/* Created Useable Component */}
      {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${
                  form.touched[name] && form.errors[name]
                    ? "is-invalidText"
                    : ""
                }`}
              >
                {" "}
                {label} {info ? <i className="smallInfo">( {info} )</i> : null}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${
                form.touched[name] && form.errors[name] ? "is-invalidText" : ""
              }`}
            >
              {" "}
              {label} {info ? <i className="smallInfo">( {info} )</i> : null}
            </label>
          )}
        </>
      )}

      {/* Created Useable Component */}
      {helpMessage && helpMessage.message && (
        <OverlayTrigger
          trigger="hover"
          key={`info-popup-${name}`}
          placement="right"
          overlay={
            <Popover id={`popover-positioned-${name}`}>
              {helpMessage.heading && (
                <Popover.Header as="h3">{helpMessage.heading}</Popover.Header>
              )}

              <Popover.Body>{helpMessage.message}</Popover.Body>
            </Popover>
          }
        >
          <i className="fa fa-info-circle ml-2 infoIcon"></i>
        </OverlayTrigger>
      )}

      <Field
        name={name} disabled={disabled}
        className={`form-control ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
        {...rest}
      />

      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormInput;
