import React, { useReducer, useEffect, useContext } from "react";

import { Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Store as toast } from "react-notifications-component";
import DashboardLayout from "../../../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import moduleConfig from "../../moduleConfig";
import ModuleHeader from "../../../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../../../config/axios";
import {
  createInitialState,
  createReducer,
} from "../../reducers/createReducer";
import { BreadcrumbContext } from "../../../../../contexts/BreadcrumbContext";
import { toastNotification } from "../../../../../config/toastNotification";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const AddSaleableDetails = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  let { id } = useParams();
  const onSubmit = async (data) => {
    //console.log(data);

    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `${moduleConfig.url}/saleable-attributes/${id}`,
        data
      ); //
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `saleable details added successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  useEffect(() => {
    ///  loadData()
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);

    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);

  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div className="overlay position-absolute containerLoading">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        code: "",
                        exclusiveItem: "0",
                        discounted: "0",
                        oldPrice: "0",
                        price: "",
                        showOnHomePage: "0",
                      }}
                      validationSchema={yup.object({
                        exclusiveItem: yup.string(),
                        discounted: yup.string(),
                        code: yup.string().required("Required"),
                        price: yup.number().required("Required"),
                        oldPrice: yup.string().nullable().when("discounted", {
                          is: (discounted) => discounted === "1",
                          then: yup.string().nullable().required("Required"),
                        }),
                        showOnHomePage: yup.string(),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { resetForm }) => {
                        if (values.discounted !== "1") {
                          delete values.discounted;
                        }
                        onSubmit(values);
                        console.log(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}
                          <FormErrorMessage error={form.errors} />
                          <CMSFormInput
                            name="code"
                            label="Item code"
                            required={true}
                            form={form}
                          />
                          <CMSFormInput
                            name="price"
                            label="Price"
                            required={true}
                            form={form}
                          />
                          <CMSFormSelect
                            name="discounted"
                            label="Discounted"
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />
                          
                          {form.values.discounted === "1" && (
                          <CMSFormInput
                            name="oldPrice"
                            label="OldPrice"
                            required={
                              form.values.discounted === "1" ? true : false
                            }
                            form={form}
                          />
                        )}
                          <CMSFormSelect
                            name="exclusiveItem"
                            label="Exclusive Item"
                            form={form}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <CMSFormSelect
                            name="showOnHomePage"
                            label="Show On Home Page"
                            form={form}
                            required={true}
                            options={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                          />

                          <button
                            type="submit"
                            onClick={() => form.handleSubmit}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default AddSaleableDetails;
