import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";
import ModuleFilterDropdown from "./ModuleFilterDropdown";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  //console.log(appliedFilters);
  return (
    <>
      {(appliedFilters.limit ||
        appliedFilters.q ||appliedFilters.method ||
        appliedFilters.status ||
        appliedFilters.purpose ||
        appliedFilters.type) && (
          <div div className=" text-right">
            <span className="resetFilters" onClick={() => resetFilters()}>
              <i className="fas fa-times" /> Reset Filters
            </span>
          </div>
        )}
      <Card className="listingPageHeader p-0 m-0 p-1 mt-1 mb-1">
        <Card.Body className=" p-0 m-0">
          <Row className="mb-1">
            {moduleConfig.hasPagination === true &&
              moduleConfig.perPageItemsOptions &&
              moduleConfig.perPageItemsOptions.length > 0 && (
                <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                  <ModuleRecordsPerPageDropdown
                    selectedPerPageItems={moduleConfig.perPageItems}
                    perPageItemsOptions={moduleConfig.perPageItemsOptions}
                    setFilter={setFilter}
                  />
                </Col>
              )}

            <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
              <ModuleFilterDropdown
                name="method"
                setFilter={setFilter}
                appliedFilters={appliedFilters}
                options={[
                  {
                    label: "Select Method...",
                    value: "all",
                  },

                  { value: "Online", label: "Online" },
                  { value: "Bank Transfer", label: "Bank Transfer" },
                  { value: "Exchange Transfer", label: "Exchange Transfer" },
                  { value: "Cash on Delivery", label: "Cash on Delivery" },
                  { value: "Cash in Shop", label: "Cash in Shop" },
                ]}
              />
            </Col>

            {moduleConfig.hasStatusSearch === true && (
              <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                <ModuleStatusDropdown
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                  statusOptions={[
                    {
                      label: "Select Payment Status...",
                      value: "all",
                    },
                    {
                      label: "Pending",
                      value: "0",
                    },
                    {
                      label: "Paid",
                      value: "1",
                    },
                  ]}
                />
              </Col>
            )}
            {moduleConfig.hasSearch === true && (
              <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12  pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                <ModuleSearchBox
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SearchArea;
