import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";
const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];
    if (item.type === "Saleable") {
      if (item.saleableOptions && item.saleableOptions.code) {
        items = [
          ...items,
          { text: "Item Code", value: item.saleableOptions.code, type: "text" },
        ];
      }
      // if (item.saleableOptions && item.saleableOptions.price) {
      //   items = [...items, { text: "Price", value: item.saleableOptions.price, type: "text" }];
      // }
      if (item.saleableOptions && item.saleableOptions.oldPrice) {
        items = [
          ...items,
          {
            text: "Old Price",
            value: item.saleableOptions.oldPrice + " " + getCurrencyCode(),
            type: "text",
          },
        ];
      }
    }

    if (item.category) {
      items = [
        ...items,
        { text: "Category", value: item.category, type: "text" },
      ];
    }
    if (item.subCategory) {
      items = [
        ...items,
        { text: "Sub Category", value: item.subCategory, type: "text" },
      ];
    }
    if (item.ageOrOriginDate) {
      items = [
        ...items,
        {
          text: "Age / Origin Date",
          value: item.ageOrOriginDate,
          type: "text",
        },
      ];
    }
    if (item.site) {
      items = [...items, { text: "Site", value: item.site, type: "text" }];
    }

    if (item.dimension) {
      items = [
        ...items,
        { text: "Dimension", value: item.dimension, type: "text" },
      ];
    }
    if (item.matrix) {
      items = [
        ...items,
        { text: "Matrix", value: item.matrix, type: "text" },
      ];
    }
    if (item.relatedMaterial) {
      items = [
        ...items,
        {
          text: "Related Material",
          value:
            process.env.REACT_APP_BACKEND_URL +
            "public/" +
            item.relatedMaterial,
          type: "link",
          download: true,
          title: "Please click to download",
        },
      ];
    }
    // if (item.oldId) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Old Id",
    //       value: item.oldId,
    //       type: "text",
    //     },
    //   ];
    // }

    return items;
  };

  return (
    <Card className="item mb-2 borderCream ">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}

            <div className="ms-1 me-2">
              <div className=" position-relative  ">
                <div className="fossilImage">
                  <ReactFancyBox
                    thumbnail={
                      process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                    }
                    image={
                      process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                    }
                    caption={`${item.genus} fossil`}
                  />
                </div>
                {(item.status === "0" || item.status === 0) && (
                  <i className="fas fa-ban position-absolute ms-1 mb-2 banned2"></i>
                )}
              </div>
            </div>

            <div className="d-flex flex-column ms-1 mt-1">
              <span className="m-0 text-bold memberName text-red">
                {item.genus}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                {item.type === "Saleable" ? (
                  <span className="membershipId text-boldItalic text-info mt-1">
                    {item.type}
                  </span>
                ) : (
                  <span className="membershipId text-boldItalic mt-1">
                    {item.type}
                  </span>
                )}
              </span>
            </div>
            {item.saleableOptions && item.saleableOptions.price && (
              <div className="d-flex flex-column  ms-1">
                <h4 className="my-0 me-0 ms-1 text-bold text-primaryCream">
                  {" "}
                  ({item.saleableOptions.price} {getCurrencyCode()})
                </h4>
              </div>
            )}
          </div>
        </Card.Title>

        <div class="card-tools endColumn mb-1 pt-1">
          <div>
            {item.type == "Collection" && item.collectionOptions && (
              <>
                {item.collectionOptions.museumQuality === "1" ||
                  item.collectionOptions.museumQuality === 1 ? (
                  <span className="badge limeYellow me-1 ">Museum Quality</span>
                ) : null}
                {item.collectionOptions.collectible === "1" ||
                  item.collectionOptions.collectible === 1 ? (
                  <span className="badge orange me-1 ">Collectible</span>
                ) : null}
                {item.collectionOptions.excellentPreservation === "1" ||
                  item.collectionOptions.excellentPreservation === 1 ? (
                  <span className="badge peach me-1 ">
                    Excellent Preservation
                  </span>
                ) : null}
                {item.collectionOptions.exceptionalRarity === "1" ||
                  item.collectionOptions.exceptionalRarity === 1 ? (
                  <span className="badge brown me-1 ">Exceptional Rarity</span>
                ) : null}
                {item.collectionOptions.fossilOfTheMonth === "1" ||
                  item.collectionOptions.fossilOfTheMonth === 1 ? (
                  <span className="badge magenda me-1 ">
                    Fossil Of The Month
                  </span>
                ) : null}
                {item.collectionOptions.newDiscovery === "1" ||
                  item.collectionOptions.newDiscovery === 1 ? (
                  <span className="badge badge-info me-1 ">New Discovery </span>
                ) : null}
                {item.collectionOptions.newSpecies === "1" ||
                  item.collectionOptions.newSpecies === 1 ? (
                  <span className="badge skyBlue me-1 ">New Species</span>
                ) : null}
              </>
            )}

            {item.type == "Saleable" && item.saleableOptions && (
              <>
                {item.saleableOptions.exclusiveItem === "1" ||
                  item.saleableOptions.exclusiveItem === 1 ? (
                  <span className="badge badge-info me-1">Exclusive Item</span>
                ) : null}
                {item.saleableOptions.discounted > 0 ||
                  item.saleableOptions.discounted > 0 ? (
                  <span className="badge teal me-1 ">Discounted</span>
                ) : null}
                {item.saleableOptions.showOnHomePage === "1" ||
                  item.saleableOptions.showOnHomePage === 1 ? (
                  <span className="badge homePageShow me-1 ">
                    Showing on Home Page
                  </span>
                ) : null}
                {item.saleableOptions.soldOut === "1" ||
                  item.saleableOptions.soldOut === 1 ? (
                  <span className="badge badge-dark me-1">Sold Out</span>
                ) : null}
              </>
            )}

            <span className="badge badge-light me-1 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Enabled"
                  falseValue="Disabled"
                />{" "}
              </span>
            </span>
            {item.createdAt && (
              <span class="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/fossils-saleable/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>

            {item.type === "Saleable" && !item.saleableOptions && (
              <Link
                to={`/fossils-saleable/add-saleable-attributes/${item._id}`}
                className="btn btn-outline-secondary  listingActionIcon"
                title={`Add Saleable Details`}
              >
                <i className="fas fa-dollar-sign "> </i>{" "}
              </Link>
            )}
            {item.type === "Saleable" && item.saleableOptions && (
              <Link
                to={`/fossils-saleable/saleable-attributes/${item._id}`}
                className="btn btn-outline-secondary  listingActionIcon"
                title={`Update Saleable Details`}
              >
                <i className="fas fa-dollar-sign "> </i>{" "}

              </Link>
            )}

            <Link
              to={`/fossils-saleable/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update Basic Fossil Details`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
            <span
              title={`Delete ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="px-1 py-0">
        <Row>
          <Col className="mt-1 mb-1 ">
            <StatsListing items={getItems()} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
