import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayRichText from "../../../../UI/modules/fieldsDisplay/ModuleDisplayRichText";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";

const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getItems = () => {
    let items = [];

    if (item.source && item.source != " ") {
      items = [...items, { text: "Source", value: item.source, type: "text" }];
    }
    if (item.topic) {
      items = [...items, { text: "Topic", value: item.topic, type: "text" }];
    }
    if (item.author) {
      items = [...items, { text: "Author", value: item.author, type: "text" }];
    }
    if (item.language) {
      items = [
        ...items,
        { text: "Language", value: item.language, type: "text" },
      ];
    }
    if (item.file) {
      items = [
        ...items,
        { text: "File", value: process.env.REACT_APP_BACKEND_URL + 'public/' + item.file, type: "link", download: true, title: "Please click to download" },
      ];
    }
    if (item.filePagesCount) {
      items = [
        ...items,
        { text: "File Pages Count", value: item.filePagesCount, type: "text" },
      ];
    }
    if (item.externalLink) {
      items = [
        ...items,
        { text: "External Link", value: item.externalLink, type: "link", title: "Please click to view" },
      ];
    }
    if (item.externalLinkTitle) {
      items = [
        ...items,
        {
          text: "External Link Title",
          value: item.externalLinkTitle,
          type: "text",
        },
      ];
    }
    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      <i className="fas fa-newspaper position-relative usericon ">
                        {(item.status === "0" || item.status === 0) && (
                          <i className="fas fa-ban position-absolute ms-1  banned2"></i>
                        )}
                      </i>
                    </div>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {item.title}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">
                        {item.date && <ModuleDisplayDate value={item.date} />}
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-1 mt-xl-2 mt-lg-2 mt-md-2 pt-xl-1 pt-lg-1 pt-md-1 pt-0">
                  {item.relevant === "1" && (
                    <span className="badge teal me-1">Relevant</span>
                  )}
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span>

                  <span class="badge badge-light me-1 text-dark">
                    Created Date :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDate value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              <StatsListing items={getItems()} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
