export const getCurrencyCode = (member) => {
  return "$";
};

export const getFullName = (member) => {
  let fullName = "";

  if (member && member.firstName) {
    fullName = member.title ? member.title : "";
    fullName += fullName ? " " + member.firstName : member.firstName;
    fullName += fullName ? " " + member.lastName : member.lastName;
  }

  return fullName;
};
export function getAllNumbersBetween(x, y) {
  var numbers = [];
  // Set a temporary variable i to start at value x.
  // As long as the value of i is less than the value y, increment it.
  // The loop will end when i is equal to y.
  for (var i = x; i >= y; i--) {
    numbers.push(i);
  }
  return numbers;
}
export const handleFormatArray = (item) => {
  const array = [];
  for (let i = 0; i < item.length; i++) {
    array.push({ label: item[i], value: item[i] });
  }

  return array;
};
export const isTrue = (value) => {
  if (value === 1 || value === "1" || value === true || value === "true")
    return true;
  return false;
};

export const isEmpty = (data) => {
  if (
    data === undefined ||
    data === "undefined" ||
    data === null ||
    data === "null" ||
    data === ""
  )
    return true;
  return false;
};

export const isValidEmail = (email) => {
  if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
    return true;
  }
  return false;
};

export function upperCaseFirst(str) {
  if (!str) return "";

  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}
