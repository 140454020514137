import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import axios from "../../../config/axios";
import moduleConfig from "../../modules/subscribers/moduleConfig";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDate from "../modules/fieldsDisplay/ModuleDisplayDate";
function SubscribersContent({ setParent, parent }) {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    setParent(true);
    // const sleep = (ms) => {
    //   return new Promise((resolve) => {
    //     setTimeout(resolve, ms);
    //   });
    // };

    // await sleep(5000);
    try {
      const res = await axios.get(`${moduleConfig.url}?limit=10`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setParent(false);
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setParent(false);
    }
  };
  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      {listingState.hasFetchingError && (
        <Alert variant="warning mb-0 mt-1">
          OOPS!!! Something went wrong, please try again later.
        </Alert>
      )}
      <Row className="mt-2 mb-3">
        {listingState.data.items && listingState.data.items.length > 0 && (
          <Col>
            <h5 className="  text-bold">Recent Subscribers</h5>
          </Col>
        )}
        {listingState.data.items && listingState.data.items.length > 0 && (
          <Col>
            <Button
              title="Please click to view all subscribers"
              variant="primary"
              className="float-end"
              href="/subscribers"
              size="sm"
            >
              View all
            </Button>
          </Col>
        )}
      </Row>

      {listingState.data.items && listingState.data.items.length <= 0 && (
        <Alert variant="warning mb-0 mt-1">No records found.</Alert>
      )}
      {listingState.data.items && listingState.data.items.length > 0 && (
        <>
          <Row>
            <Col className="overflow-auto">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>#</th>

                    <th>Email</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listingState.data &&
                    listingState.data.items &&
                    listingState.data.items.length &&
                    listingState.data.items.map((item, itemIndex) => {
                      return (
                        <tr key={itemIndex}>
                          <td>{itemIndex + 1}</td>

                          <td>
                            <ModuleDisplayEmail value={item.email} />
                          </td>
                          <td>
                            <ModuleDisplayDate value={item.createdAt} />
                          </td>
                          <td>
                            <ModuleDisplayBoolean
                              value={item.status}
                              trueValue="Subscribed"
                              falseValue="Unsubscribed"
                            />
                          </td>

                          <td
                            className={`text-center record-actions  MoreItem`}
                          >
                            <Link
                              title="Please click to view details"
                              to={`/subscribers/${item._id}`}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              <i className="fas fa-eye"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default SubscribersContent;
