import React from "react";

const ModuleDisplayURL = ({ value, download, title }) => {
  return (
    <a
      title={title}
      href={value}
      target="_blank"
      className={`${download ? "text-primaryCream" : ""}`}
      download={download ? true : false}
    >
      {" "}
      {value}
    </a>
  );
};

export default ModuleDisplayURL;
