import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Form } from "react-bootstrap";
import axios from "axios";
const CMSFormTelephone = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  info,
  md,
  rows,
  form,
  controlId,
  options,
  disabled,
  readOnly,
  errorState,
  setErrorState,
  ref,
  ...props
}) => {
  const formatPhoneNumberOutput = (
    isValid,
    newNumber,
    countryData,
    fullNumber,
    isExtension
  ) => {
    return fullNumber.replace(/(\s|-)/g, "");
  };
  const [state, setstate] = useState();
  const [preferredCountries, setPreferredCountries] = useState(["lb"]);
  function fetchDialCode(initialValues, setInitialValues) {
    axios
      .get(
        "https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3"
      )
      .then((response) => {
        let data = response.data;
        setstate(data.country.toLowerCase());
        // setTimeout(() => {
        //   let aaa = (state || "").toLowerCase();
        //   setstate(aaa);
        // }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (!value) {
      fetchDialCode();
    }
  }, []);

  // https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3

  const [telephoneValid, setTelephoneValid] = useState(true);
  const errorMap = [
    "Invalid Phone Number",
    "Invalid Country Code",
    "Please enter complete number",
    "Please enter valid number",
    "Invalid Phone Number",
  ];
  const setValidity = (valid) => {
    setTelephoneValid(valid);
  };
  const setError = (valid, setFieldError) => {
    valid ? setErrorState(false) : setErrorState(true);
    setFieldError(name, "Please enter a valid numberrrrrrrrrrrrr");
  };
  // process number into string with area code for submission
  const processNumber = (isValid, phone, country) => {
    return `${phone}`;
  };
  return (
    <>
      <div className="form-group">
        {label !== "" && (
          <>
            {required === true ? (
              <OverlayTrigger
                key={`tooltip-${name}`}
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-right`}>Required field.</Tooltip>
                }
              >
                <label
                  htmlFor={name}
                  className={`required '  ${
                    !telephoneValid ? "is-invalidText" : ""
                  } ${
                    form.touched[name] && form.errors[name]
                      ? "is-invalidText"
                      : ""
                  }`}
                >
                  {" "}
                  {label}{" "}
                  {info ? <i className="smallInfo">( {info} )</i> : null}
                </label>
              </OverlayTrigger>
            ) : (
              <label
                htmlFor={name}
                className={` ${
                  form.touched[name] && form.errors[name]
                    ? "is-invalidText"
                    : ""
                }`}
              >
                {" "}
                {label} {info ? <i className="smallInfo">( {info} )</i> : null}
              </label>
            )}
          </>
        )}
        <Field name={name}>
          {({
            field: { value },
            form: {
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              setFieldError,
            },
          }) => (
            <Form.Group md={md} controlId={controlId}>
              <IntlTelInput
                {...props}
                containerClassName="intl-tel-input"
                defaultCountry={state ? state : "ae"}
                inputClassName={`form-control ${
                  telephoneValid ? "" : "is-invalid"
                } customInput ${
                  form.touched[name] && form.errors[name] ? "is-invalid" : " "
                }`}
                label="telephone"
                //defaultValue={value}
                value={value}
                //separateDialCode

                nationalMode={false}
                disabled={false}
                autoHideDialCode={false}
                fieldId={name}
                fieldName={name}
                format={true}
                autoPlaceholder
                geoIpLookup={state}
                utilsScript="libphonenumber.js"
                preferredCountries={preferredCountries}
                onPhoneNumberBlur={(isValid, phone, country) => {
                  setFieldTouched(name, true);
                  setValidity(isValid);
                
                   setError(isValid, setFieldError);
                  // console.log("onPhoneNumberChange value", value);
                }}
                onPhoneNumberChange={(isValid, phone, country) => {
                  setFieldValue(name, processNumber(isValid, phone, country));
                   setError(isValid, setFieldError);
                }}
              />
            </Form.Group>
          )}
        </Field>
        {form.touched[name] && form.errors[name] && (
          <span id={`${name}-error"`} className="error errorMessage">
            <ErrorMessage name={name} />
          </span>
        )}
        {form.touched[name] && !form.errors[name] && !telephoneValid && (
          <span id={`${name}-error"`} className="error errorMessage">
            Please enter a valid phone number
          </span>
        )}
      </div>
    </>
  );
};

export default CMSFormTelephone;
