import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  //console.log(appliedFilters);
  return (
    <>
      {(appliedFilters.limit || appliedFilters.q || appliedFilters.status) && (
        <div div className=" text-right">
          <span className="resetFilters" onClick={() => resetFilters()}>
            <i className="fas fa-times" /> Reset Filters
          </span>
        </div>
      )}
      <div className="position-relative">
        <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
          <Card.Body className=" p-0 m-0">
            <Row className="mb-0">
              {moduleConfig.hasPagination === true &&
                moduleConfig.perPageItemsOptions &&
                moduleConfig.perPageItemsOptions.length > 0 && (
                  <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                    <ModuleRecordsPerPageDropdown
                      selectedPerPageItems={moduleConfig.perPageItems}
                      perPageItemsOptions={moduleConfig.perPageItemsOptions}
                      setFilter={setFilter}
                    />
                  </Col>
                )}
              {moduleConfig.hasStatusSearch === true && (
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                  <ModuleStatusDropdown
                    setFilter={setFilter}
                    appliedFilters={appliedFilters}
                    statusOptions={[
                      {
                        label: "Select Status...",
                        value: "all",
                      },
                      {
                        label: "Archived",
                        value: "0",
                      },
                      {
                        label: "Active",
                        value: "1",
                      },
                    ]}
                  />
                </Col>
              )}
              {moduleConfig.hasSearch === true && (
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                  <ModuleSearchBox
                    setFilter={setFilter}
                    appliedFilters={appliedFilters}
                  />
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default SearchArea;
