import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormFile from "../../UI/forms/CMSFormFile";
import { detailsInitialState, detailsReducer } from "./reducers/detailsReducer";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const BannersCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    try {
      const res = await axios.get(`general/lookup-languages`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "11111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    ///  loadData()
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);

    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);
  let imageRequirement = {
    dimension: {
      width: 1366,
      height: 648,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {createState.status !== "fetching" &&
            detailsState.data &&
            detailsState.data.languages && (
              <>
                <ModuleHeader
                  moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                  moduleUrl={moduleConfig.url}
                ></ModuleHeader>

                <section className="content">
                  <Card>
                    <Card.Body>
                      {createState.isLoading && (
                        <div class="overlay position-absolute containerLoading">
                          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                      )}
                      <Formik
                        initialValues={{
                          title: "",
                          mobileTitle: "",
                          link: "",
                          linkTitle: "",
                          type: "",
                          image: "",
                          video: "",
                          sortOrder: 99,
                          status: "1",
                        }}
                        validationSchema={yup
                          .object({
                            title: yup
                              .string()
                              .required("Required")
                              .max(
                                50,
                                "Title should contain at most 50 characters"
                              ),
                            mobileTitle: yup
                              .string()

                              .max(
                                35,
                                "Title should contain at most 35 characters"
                              ),
                            type: yup.string().required("Required"),
                            linkTitle: yup
                              .string()
                              .max(
                                35,
                                "link title should contain at most 35 characters"
                              ),
                            status: yup.string().required("Required"),
                          })
                          .shape(
                            {
                              image: yup.mixed().when("type", {
                                is: "image",
                                then: yup
                                  .mixed()
                                  .required("Please upload image.")
                                  .imageTypeCheck(
                                    imageRequirement.allowedExtensions
                                  )
                                  .imageSizeCheck(imageRequirement.size)
                                  .imageDimensionCheck(
                                    imageRequirement.dimension
                                  ),
                              }),

                              video: yup.mixed().when("type", {
                                is: "video",
                                then: yup
                                  .mixed()
                                  .required("Required")
                                  .test(
                                    "type",
                                    "Please upload a video of format mp4",
                                    function (value) {
                                      if (value == "undefined" || value) {
                                        console.log(value.type, "1111");
                                        return (
                                          value && value.type === "video/mp4"
                                        );
                                      } else {
                                        return true;
                                      }
                                    }
                                  )
                                  .test(
                                    "size",
                                    "File exceeded size of 10mb",
                                    function (value) {
                                      if (value == "undefined" || value) {
                                        console.log(value.size);
                                        return (
                                          value && value.size <= 1024 * 1024 * 10
                                        );
                                      } else {
                                        return true;
                                      }
                                    }
                                  ),
                              }),
                            },
                            [["image", "video"]]
                          )}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={async (values, { resetForm }) => {
                          var newData = values;
                          if (values.type === "video") {
                            newData = { ...values, image: "" };
                          } else if (values.type === "image") {
                            newData = { ...values, video: "" };
                          }
                          const formData = new FormData();
                          for (let name in newData) {
                            if (Array.isArray(newData[name])) {
                              formData.append(
                                name,
                                JSON.stringify(newData[name])
                              );
                            } else {
                              formData.append(name, newData[name]);
                            }
                          }
                          console.log(formData);
                          console.log(newData);
                          onSubmit(formData, resetForm);
                        }}
                      >
                        {(form) => (
                          <Form onSubmit={form.handleSubmit}>
                            {createState.error && (
                              <FormErrorMessage error={createState.error} />
                            )}

                            {/* {console.log(form.errors)} */}
                            <CMSFormInput
                              name="title"
                              label="Title"
                              required={true}
                              form={form}
                            />

                            <CMSFormInput
                              name="mobileTitle"
                              label="Title For Mobile View"
                              required={true}
                              form={form}
                            />

                            <CMSFormSelect
                              name="type"
                              label="Type"
                              required={true}
                              form={form}
                              options={[
                                { value: "image", label: "Image" },
                                {
                                  value: "video",
                                  label: "Video",
                                },
                              ]}
                            />
                            {form.values.type === "image" && (
                              <CMSFormImageWithCrop
                                label="Image"
                                name="image"
                                info="Recommended dimensions 1366*648"
                                required={true}
                                form={form}
                                aspect={683 / 324}
                                outputSize={imageRequirement.dimension}
                              />
                            )}
                            {form.values.type === "video" && (
                              <CMSFormFile
                                name="video"
                                label="Video"
                                info="Recommended dimensions 1366*648"
                                required={true}
                                form={form}
                              />
                            )}

                            <CMSFormInput
                              name="link"
                              label="Link"
                              required={false}
                              form={form}
                            />
                            <CMSFormInput
                              name="linkTitle"
                              label="Link Title"
                              required={false}
                              form={form}
                            />
                            <CMSFormInput
                              name="sortOrder"
                              label="Sort Order"
                              required={false}
                              form={form}
                            />

                            <CMSFormSelect
                              name="status"
                              label="Status"
                              required={true}
                              form={form}
                              options={[
                                { label: "Select Status...", value: "" },
                                { label: "Enabled", value: "1" },
                                { label: "Disabled", value: "0" },
                              ]}
                            />

                            <button
                              type="submit"
                              onClick={() => form.handleSubmit}
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </section>
              </>
            )}
        </>
      )}
    </DashboardLayout>
  );
};

export default BannersCreate;
