import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import LookupValueItem from './LookupValueItem';

const SortableList = (props) => {
    return (
        <ul className="m-0  ps-4 cursor-move">
            {
                props.items.map((item, index) => (
                    <LookupValueItem key={`item-${index}`} index={index} item={item} deleteItem={props.deleteItem} />
                ))
            }
        </ul>
    );
}

export default SortableContainer(SortableList);