import React, { useReducer, useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig"
import { detailsReducer, memberDetailsInitialState } from "./reducers/detailsReducer";
import Details from "./partials/details/Details";



import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { Button } from "react-bootstrap";

import PaymentAcceptModal from "./partials/listing/AddPaymentRequestModal";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "Details",
    }
};

const PaymentRequestView = () => {
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [detailsState, detailsDispatch] = useReducer(detailsReducer, memberDetailsInitialState)
    const [showAcceptPaymentModal, setShowAcceptPaymentModal] = useState(false);
    let { id } = useParams();
    const { confirm } = useConfirm();
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const loadItem = async () => {
        if (id) {
            detailsDispatch({
                type: "FETCH_REQUEST"
            });

            try {
                const res = await axios.get(`${moduleConfig.url}/details/${id}`)//
                detailsDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data
                });
            } catch (error) {
                detailsDispatch({
                    type: "FETCH_FAILURE",
                    payload: error
                });
            }
        }
    }
    const confirmAndSend = async (id) => {
        const isConfirmed = await confirm(
            `Do you really want to send the payment request link?`
        );

        if (isConfirmed) {
            console.log("send")
            onSendLink(id);
        }
    };
    const onSendLink = async (data) => {
        //console.log(data);

        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.get(`payment-requests/send-email/${data}`); //
            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });

            // setShowAddPaymentRequestModal(false);
            if (res.data.emailSent === true) {
                console.log(res.data)
                toast.addNotification({
                    ...toastNotification,
                    title: "Success!",
                    message: `Email sent successfully.`,
                    type: "success",
                });

                loadItem();
            }
            if (res.data.emailSent === false) {

                toast.addNotification({
                    ...toastNotification,
                    title: "Error!",
                    message: `Something went wrong!Try again later.`,
                    type: "danger",
                });
            }
        } catch (error) {
            //console.log(error.message);
            const status = error.response ? error.response.status : 500;
            const errorMessage =
                status === 400 ? error.response.data.errorMessage : error.message;
            console.log(errorMessage);
            toast.addNotification({
                ...toastNotification,
                title: "Error!",
                message: `Something went wrong!Try again later.`,
                type: "danger",
            });
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: errorMessage,
            });
            // setShowAddPaymentRequestModal(false);
            // loadItems();
            setTimeout(() => {
                updateDispatch({
                    type: "FORM_INITIAL_STATE",
                });
            }, 5000);
        }
    };
    useEffect(() => {
        loadItem();
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details
        ])
    }, [id])
    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
            payload: '',
        });
    }, [])

    return (<DashboardLayout >

        {updateState.isLoading && <ContainerLoading />}
        {(detailsState.isFetching)
            ? <ContainerLoading />
            : <>


                {(detailsState.hasError)

                    ? <BlockErrorMessage error={detailsState.error} />
                    : <>

                        {
                            (detailsState.data.item) &&
                            <>

                                <ModuleHeader
                                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                                    moduleUrl={moduleConfig.url}
                                ></ModuleHeader>




                                {detailsState.data.item.paymentStatus === "0" ?
                                    <div className="border mb-1 p-1 bg-light text-white rounded">
                                        <Button onClick={() => confirmAndSend(detailsState.data.item._id)} className={detailsState.data.item.emailSentOnce === "1" ?"btn btn-secondary":"btn btn-primary "}>{detailsState.data.item.emailSentOnce === "1" ? "Resend Link" : "Send Link"}</Button>  </div> : null}

                                <Details
                                    item={detailsState.data.item}
                                    moduleConfig={moduleConfig}

                                />

                            </>
                        }


                    </>
                }

            </>
        }
    </DashboardLayout>);
}

export default PaymentRequestView;


