import React, { useReducer, useEffect, useContext, useState } from "react";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig";
import { detailsReducer, detailsInitialState } from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";

import GalleryPictures from "../../UI/shared/gallery/GalleryPictures";
import RecentComments from "../../UI/shared/recentComments/RecentComments";
import RecentLikes from "../../UI/shared/recentLikes/RecentLikes";
import PaymentAcceptModal from "./partials/details/PaymentAcceptModal";
import OrderUpdateModal from "./partials/details/OrderUpdateModal";
import OrderStatusModal from "./partials/details/OrderStatusModal";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  details: {
    label: "Details",
  },
};

const OrderView = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [showAcceptPaymentModal, setShowAcceptPaymentModal] = useState(false);
  const [showOrderAddressModal, setShowOrderAddressModal] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
  const [status, setStatus] = useState(0);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const navigate = useNavigate();
  let { id } = useParams();
  const { confirm } = useConfirm();
  const loadItem = async () => {
    if (id) {
      detailsDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.details]);
  }, [id]);

  const confirmAndAction = async (id, status) => {
    console.log(status, status === "1" || status === 1 ? "complete" : "cancel");
    const isConfirmed = await confirm(
      `Do you really want to ${
        status === "1" || status === 1 ? "complete" : "cancel"
      } this order?`
    );

    if (isConfirmed) {
      setStatus(status);
      setShowOrderStatusModal(true);
    }
  };

  return (
    <DashboardLayout>
      {detailsState.isFetching ? (
        <ContainerLoading />
      ) : (
        <>
          {detailsState.hasError ? (
            <BlockErrorMessage error={detailsState.error} />
          ) : (
            <>
              {detailsState.data.item && (
                <>
                  <ModuleHeader
                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                    moduleUrl={moduleConfig.url}
                  ></ModuleHeader>
                  {detailsState.data.item.orderStatus !== "2" && (
                    <div className="border mb-1 p-1 bg-light text-white rounded">
                      {detailsState.data.item.payment[0].status === "0" &&
                        detailsState.data.item.orderStatus !== "2" && (
                          <Link
                            title="Accept Payment"
                            onClick={() => setShowAcceptPaymentModal(true)}
                            className="btn btn-success  border me-1"
                          >
                            <i className="fas fa-dollar-sign text-white me-1"></i>{" "}
                            <span className="text-white">Accept Payment</span>
                          </Link>
                        )}

                      <Button
                        className="me-1 "
                        variant="primary"
                        title={`Update order`}
                        onClick={() => setShowOrderAddressModal(true)}
                      >
                        <i class="fas fa-clock me-1" aria-hidden="true"></i>{" "}
                        <span className="">Update Order</span>
                      </Button>

                      {(detailsState.data.item.orderStatus === "0" ||
                        detailsState.data.item.orderStatus === 0) && (
                        <Button
                          className="me-1 "
                          variant="primary"
                          title={`Mark ${moduleConfig.singleItemTitle} as completed`}
                          onClick={() =>
                            confirmAndAction(detailsState.data.item._id, 1)
                          }
                        >
                          <i className="fas fa-check me-1"> </i>{" "}
                          <span className="">Complete Order</span>
                        </Button>
                      )}
                      {(detailsState.data.item.orderStatus === "0" ||
                        detailsState.data.item.orderStatus === 0) && (
                        <Button
                          className="me-1 "
                          variant="danger"
                          title={`Cancel order`}
                          onClick={() =>
                            confirmAndAction(detailsState.data.item._id, 2)
                          }
                        >
                          <i className="fas fa-times me-1"> </i>{" "}
                          <span className="">Cancel Order</span>
                        </Button>
                      )}

                      {/* <Button
                      className="me-1 mot"
                      variant="primary"
                      title={`Enabled ${moduleConfig.singleItemTitle}`}
                      onClick={() => deleteItem(detailsState.data.item._id)}
                    >
                      <i className="fas fa-trash"> </i>{" "}
                      <span className="">Delete</span>
                    </Button> */}
                    </div>
                  )}
                  <Details
                    item={detailsState.data.item}
                    moduleConfig={moduleConfig}
                  />
                  <PaymentAcceptModal
                    buyerId={detailsState.data.item.buyerId}
                    paymentId={detailsState.data.item._id}
                    show={showAcceptPaymentModal}
                    setShow={setShowAcceptPaymentModal}
                    loadItem={loadItem}
                    item={detailsState.data.item}
                  />
                  <OrderUpdateModal
                    show={showOrderAddressModal}
                    setShow={setShowOrderAddressModal}
                    loadItem={loadItem}
                    item={detailsState.data.item}
                  />
                  <OrderStatusModal
                    show={showOrderStatusModal}
                    setShow={setShowOrderStatusModal}
                    loadItem={loadItem}
                    id={detailsState.data.item._id}
                    status={status}
                    item={detailsState.data.item}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default OrderView;
