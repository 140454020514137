import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formReducer, formInitialState } from "../../../reducers/formReducer"
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext';
import moduleConfig from "./moduleConfig"
import DashboardLayout from './../../UI/layouts/DashboardLayout';


import { Card } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from "yup"
import axios from "../../../config/axios";


import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../config/toastNotification'
import FormErrorMessage from '../../UI/errorMessages/FormErrorMessage';
import CMSFormInput from '../../UI/forms/CMSFormInput';
import { ContainerLoading } from '../../UI/loading/ContainerLoading';
import ModuleHeader from '../../UI/modules/views/partials/common/ModuleHeader';
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "",
        url: ""
    },
    changePassword: {
        label: "Change Password",
    }
};

const AdminChangePassword = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    console.log(id);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [formState, formDispatch] = useReducer(formReducer, formInitialState)

    const onSubmit = async (data) => {



        formDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });
        try {
            const res = await axios.post(`${moduleConfig.url}/change-password/${id}`, data)//
            formDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });
            navigate(`/${moduleConfig.url}`)
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `${moduleConfig.singleItemTitle} updated successfully.`,
                type: "success",
            });
        } catch (error) {
            formDispatch({
                type: "FORM_SUBMISSION_FAILED",
                payload: error
            });
            setTimeout(() => {
                formDispatch({
                    type: "FORM_INITIAL_STAGE"
                });
            }, 5000)
        }
    }

    useEffect(() => {
        breadcrumbs.details = {
            label: "Details",
            url: `/${moduleConfig.url}/${id}`
        }
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details,
            breadcrumbs.changePassword
        ])
    }, [])

    return (<DashboardLayout >


        <ModuleHeader
            moduleTitle={`Change Password`}
            moduleUrl={moduleConfig.url}
        >
        </ModuleHeader>



        <Card>
            <Card.Body>
                {(formState.isFormSubmitting) && (
                    <div className="overlay position-absolute containerLoading">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )}
                <Formik
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        confirm_password: '',
                    }}
                    validationSchema={Yup.object({

                        old_password: Yup.string().required("Required").min(6),
                        new_password: Yup.string().required("Required").min(6),
                        confirm_password: Yup.string()
                            .required("Required")
                            .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
                    })}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        console.log(values);
                        onSubmit(values)
                        // const formData = new FormData();
                        // for (let name in values) {
                        //     if (Array.isArray(values[name])) {
                        //         formData.append(name, JSON.stringify(values[name]));
                        //     } else {
                        //         formData.append(name, values[name]);
                        //     }
                        // }

                        //onSubmit(formData)
                    }}
                >
                    {(form) => (
                        <Form onSubmit={form.handleSubmit}>

                            {(formState.hasSubmissionError) &&
                                <FormErrorMessage error={formState.formSubmissionError} />
                            }

                            <CMSFormInput name="old_password" type="password" label="Old Password" required={true} form={form} />
                            <CMSFormInput name="new_password" type="password" label="New Password" required={true} form={form} />
                            <CMSFormInput name="confirm_password" type="password" label="Confirm Password" required={true} form={form} />



                            <button className="btn btn-primary" type="submit">Submit</button>
                        </Form>
                    )}
                </Formik>




            </Card.Body>
        </Card>





    </DashboardLayout >);
}
export default AdminChangePassword;
