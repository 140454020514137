import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Accordion } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import axios from "../../../config/axios";

import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";
import moduleConfig from "../../modules/cart/moduleConfig";
import StatsListing from "../shared/infoListing/StatsListing";
import ModuleDisplayDate from "../modules/fieldsDisplay/ModuleDisplayDate";
function CartContent({ setParent, parent }) {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    setParent(true);
    try {
      const res = await axios.get(`${moduleConfig.url}/stats-for-dashboard`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setParent(false);
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setParent(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);
  const getItems = (item) => {
    let items = [];

    // if (item.cartItemsCount) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Total Item(s) in cart",
    //       value: item.cartItemsCount,
    //     },
    //   ];
    // }

    return items;
  };
  const getBuyer = (item) => {
    let items = [];

    if (item.buyer && item.buyer.fullName) {
      items = [
        ...items,
        {
          title: "Full Name",
          value: item.buyer.fullName,
          type: "text",
          url: `/buyers/${item.buyerId}`,
        },
      ];
    }

    return items;
  };
  const getBuyerEmail = (item) => {
    let items = [];
    if (item.buyer && item.buyer.email) {
      items = [
        ...items,
        { text: "Email", value: item.buyer.email, type: "email" },
      ];
    }

    return items;
  };
  return (
    <>
      <Row className="my-2">
        <Col>
          {listingState.hasFetchingError && (
            <Alert variant="warning mb-0 mt-1">
              OOPS!!! Something went wrong, please try again later.
            </Alert>
          )}
          {listingState.data && listingState.data.summary && (
            <>
              <Row className="my-2">
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a href="/carts" title="Please click to view all carts">
                    <div class="info-box bg-gradient-blue">
                      <span class="info-box-icon">
                        <i class="fa fa-shopping-cart"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Total Carts</span>
                        <span class="info-box-number">
                          {listingState.data.summary.total}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a
                    href="/carts?paymentMethod=Online"
                    title="Please click to view online payment interested carts"
                  >
                    <div class="info-box bg-gradient-pink">
                      <span class="info-box-icon">
                        <i class="fa fa-credit-card"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">
                          Online Payment Interested
                        </span>
                        <span class="info-box-number">
                          {listingState.data.summary.onlinePaymentInterested}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a
                    href="/carts?paymentFailed=1"
                    title="Please click to view payment failed carts"
                  >
                    <div class="info-box bg-gradient-yellow">
                      <span class="info-box-icon">
                        <i class="fa fa-exclamation-circle"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Online Payment Failed</span>
                        <span class="info-box-number">
                          {listingState.data.summary.onlinePaymentFailed}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>
              <Row className="mt-2 mb-3">
                <Col>
                  <h5 className="  text-bold">Recent Cart(s)</h5>
                </Col>
                {listingState.data &&
                  listingState.data.summary &&
                  listingState.data.summary.total > 0 && (
                    <Col>
                      <Button
                        title="Please click to view all carts"
                        variant="primary"
                        className="float-end"
                        href="/carts"
                        size="sm"
                      >
                        View all
                      </Button>
                    </Col>
                  )}
              </Row>
            </>
          )}
          <Row>
            <Col>
              {listingState.data.items &&
                listingState.data.items.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1">No records found.</Alert>
                )}
              {listingState.data.items &&
                listingState.data.items.length > 0 && (
                  <>
                    {listingState.data.items.map((item, index) => {
                      return (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey={index == 0 ? "0" : index}
                            key={item._id}
                          >
                            <Accordion.Header className="accordionBorder">
                              <div className="w-100 dashboardCart">
                                <div>
                                  <p className="mb-1 text-bold ">
                                    <a
                                      title="Please click to view details"
                                      href={`/carts/${item._id}`}
                                      className="hoveredText"
                                    >
                                      {item.cartItemsCount} Item(s) in cart
                                    </a>
                                  </p>
                                  {/* <i className="mb-1 text-red text-bold">
                            {item.cartItemsCount} Item(s) in cart
                          </i> */}
                                </div>
                                <div className="my-2 my-xl-0 my-lg-0 my-md-0">
                                  {(item.paymentFailed === "1" ||
                                    item.paymentFailed === 1) && (
                                      <span className="badge red me-1 text-white">
                                       Online Payment Failed
                                      </span>
                                    )}
                                  {item.paymentMethod && (
                                    <span className="badge badge-success me-1 text-white">
                                     Online Payment Selected
                                    </span>
                                  )}
                                  {item.createdAt && (
                                    <span class="badge badge-light me-1 mt-1 text-dark">
                                      Created Date :{" "}
                                      <span class="text-secondary">
                                        <ModuleDisplayDate
                                          value={item.createdAt}
                                        />{" "}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              <StatsListing items={getItems(item)} />
                              <div className="d-flex">
                                <StatsListing
                                  heading="Buyer"
                                  clickable={true}
                                  items={getBuyer(item)}
                                />
                                <StatsListing items={getBuyerEmail(item)} />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    })}
                  </>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CartContent;
