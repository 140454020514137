import React, { useState, useReducer, useEffect, useContext } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import queryString from "query-string";
import moduleConfig from "./moduleConfig";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import useConfirm from "../../../HOC/custom/useConfirm";
import ListingFooter from "./partials/listing/ListingFooter";
import SearchArea from "./partials/listing/SearchArea";
import { listingReducer, listingInitialState } from "./reducers/listingReducer";
import ModuleDisplayBoolean from "../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDateTime from "../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDate from "../../UI/modules/fieldsDisplay/ModuleDisplayDate";

const customButtonsForItems = [
  // {
  //   label: "Change Password",
  //   className: "fas fa-key",
  //   url: "change-password"
  // }
];

const PaymentListing = () => {
  let { id } = useParams();

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { confirm } = useConfirm();

  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState(
    queryString.parse(search)
  );
  if (appliedFilters.limit) {
    moduleConfig.perPageItems = appliedFilters.limit;
  }

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
  };

  let history = useNavigate();

  const setFilter = (name, value) => {
    console.log(`setFilter called with ${name}, ${value}`);
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    if (value) {
      newAppliedFilters[name] = value;
    } else {
      delete newAppliedFilters[name];
    }

    if (name !== "page") {
      delete newAppliedFilters["page"];
    }

    if (name === "limit" || name === "page") {
      setIsCheck([]);
      setIsCheckAll(false);
    }

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };

  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`${moduleConfig.url}${search}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const confirmAndDisabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to disabled this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id);
    }
  };

  const confirmAndEnabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to enabled this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id, true);
    }
  };

  const changeStatus = async (id, undo = false) => {
    console.log("In block Item Function");
    listingDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/change-status`, {
        id,
        undo: undo === true ? "1" : "0",
      });
      loadItems();
      // listingDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} ${undo ? "restored" : "archived"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      listingDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        listingDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const resetFilters = () => {
    //console.log(`resetFilters called`)
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    moduleConfig.perPageItems = 10;
    delete newAppliedFilters["limit"];
    delete newAppliedFilters["status"];
    delete newAppliedFilters["q"];
    delete newAppliedFilters["method"];
    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    //console.log(newAppliedFilters);
    setAppliedFilters(newAppliedFilters);
  };

  const performBulkAction = async (action) => {
    listingDispatch({
      type: "BULK_ACTION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/bulk-action`, {
        ids: isCheck,
        action,
      });
      setIsCheck([]);
      setIsCheckAll(false);
      loadItems();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Action "${action}" on selected items was performed successfully.`,
        type: "success",
      });
    } catch (error) {
      listingDispatch({
        type: "BULK_ACTION_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        listingDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    loadItems();
  }, [search]);

  useEffect(() => {
    setBreadcrumb([breadcrumbs.listing]);
  }, []);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    if (listingState.data.items && listingState.data.items.length) {
      setIsCheck(listingState.data.items.map((li) => li._id));
    }

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckBoxClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    setIsCheckAll(false);
  };

  const download = (type) => {
    axios
      .post(`/payments/download`, {
        type,
        ids: isCheck,
      })
      .then(function (response) {
        console.log(response);

        let blob = new Blob([response.data], { type: "text/csv" }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = type + "-payments.csv";
        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      });
  };

  return (
    <DashboardLayout>
      {listingState.isLoading && <ContainerLoading />}

      {listingState.status !== "fetching" && (
        <>
          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : (
            <>
              <ModuleHeader
                moduleTitle={moduleConfig.title}
                moduleUrl={moduleConfig.url}
              >
                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 && (
                    <Dropdown className="">
                      <Dropdown.Toggle
                        variant="primary"
                        className="border"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-download"> </i> Download &nbsp;
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => download("all")}>
                          All Payments
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => download("paid")}>
                          Paid Payments
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => download("pending")}>
                          Pending Payments
                        </Dropdown.Item>
                        <Dropdown.Item
                          disabled={
                            isCheck && isCheck.length > 0 ? false : true
                          }
                          onClick={() => download("selected")}
                        >
                          Selected Item(s)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
              </ModuleHeader>

              <div className="listingPage">
                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 ? (
                  <>
                    {/* Search Area */}
                    <SearchArea
                      moduleConfig={moduleConfig}
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      resetFilters={resetFilters}
                    />

                    {/* Listing */}
                    <section className="content listing">
                      {listingState.error && (
                        <FormErrorMessage error={listingState.error} />
                      )}

                      {console.log(listingState.data.items)}

                      {!listingState.data.items ||
                        listingState.data.items.length <= 0 ? (
                        <Alert variant="warning">
                          No records found with selected filters.{" "}
                          <Alert.Link href="#" onClick={() => resetFilters()}>
                            <i className="fas fa-times" /> Reset Filters
                          </Alert.Link>
                          .
                        </Alert>
                      ) : (
                        <>
                          {listingState.data.items &&
                            listingState.data.items.length && (
                              <>
                                <div className="registrationTable m-0 p-0 mt-2 overflow-auto">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr>
                                        <th>
                                          <input
                                            className="selectAll"
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            onChange={handleSelectAll}
                                            checked={isCheckAll}
                                          />
                                        </th>
                                        <th>#</th>
                                        <th>Full Name</th>
                                        <th>order Id</th>
                                        <th>Method</th>
                                        <th className="text-center">Amount</th>

                                        <th className="text-center">
                                          Payment Status
                                        </th>
                                        <th>Payment Date</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listingState.data.items.map(
                                        (item, index) => {
                                          return (
                                            <tr index={index}>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  className="selectAll"
                                                  id={item._id}
                                                  name={item._id}
                                                  onChange={handleCheckBoxClick}
                                                  checked={isCheck.includes(
                                                    item._id
                                                  )}
                                                />
                                              </td>
                                              <td>{index + 1}</td>
                                              <td>
                                                <Button
                                                  className="text-red text-bold"
                                                  title="Please click to view buyer details"
                                                  href={`/buyers/${item.buyer._id}`}
                                                  variant="link"
                                                >
                                                  {" "}
                                                  {item.buyer
                                                    ? getFullName(item.buyer)
                                                    : ""}
                                                </Button>
                                              </td>
                                              <td>{item.orderId}</td>
                                              <td>
                                                {item.method === "COD"
                                                  ? "Cash on Delivery"
                                                  : item.method === "CIS"
                                                    ? "Cash in Store"
                                                    : item.method}
                                              </td>
                                              <td className="text-center">
                                                {" "}
                                                {item.paidAmount}
                                              </td>

                                              <td className="text-center">
                                                <ModuleDisplayBoolean
                                                  value={item.status}
                                                  trueValue="Paid"
                                                  falseValue="Pending"
                                                />
                                              </td>
                                              <td>
                                                <ModuleDisplayDate
                                                  value={item.date}
                                                />
                                              </td>
                                              <td
                                                className={`text-center record-actions  MoreItem`}
                                              >
                                                <Link
                                                  title="View Item"
                                                  to={`/${moduleConfig.url}/${item._id}`}
                                                  className="btn btn-outline-secondary btn-sm"
                                                >
                                                  <i className="fas fa-eye"></i>
                                                </Link>{" "}
                                                <Link
                                                  title="Update Item"
                                                  to={`/${moduleConfig.url}/update/${item._id}`}
                                                  className="btn btn-outline-secondary btn-sm"
                                                >
                                                  <i className="fas fa-pencil-alt"></i>
                                                </Link>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </section>

                    <ListingFooter
                      listingState={listingState}
                      moduleConfig={moduleConfig}
                      isCheck={isCheck}
                      performBulkAction={performBulkAction}
                      setFilter={setFilter}
                    />
                  </>
                ) : (
                  <Alert variant="warning">No records found.</Alert>
                )}
              </div>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default PaymentListing;
