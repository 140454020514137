import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getItems = () => {
    let items = [];


    if (item.mobileTitle) {
      items = [
        ...items,
        {
          text: "Title for Mobile View ",
          value: item.mobileTitle,
          type: "text",
        },
      ];
    }

    if (item.link) {
      items = [
        ...items,
        {
          text: "Link",
          value: item.link,
          type: "link",
          title: "Please click to view",
        },
      ];
    }
    // if (item.linkText) {
    //   items = [
    //     ...items,
    //     { text: "Link Text", value: item.linkText, type: "text" },
    //   ];
    // }
    if (item.linkTitle) {
      items = [
        ...items,
        { text: "Link Title", value: item.linkTitle, type: "text" },
      ];
    }

    // if (item.image) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Image",
    //       value: process.env.REACT_APP_BACKEND_URL + "public/" + item.image,
    //       type: "link",
    //       download: true,
    //       title: "Please click to download",
    //     },
    //   ];
    // }
    // if (item.video) {
    //   items = [
    //     ...items,
    //     {
    //       text: "Video",
    //       value: process.env.REACT_APP_BACKEND_URL + "public/" + item.video,
    //       type: "link",
    //       download: true,
    //       title: "Please click to download",
    //     },
    //   ];
    // }
    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      {item.type === "video" ? (
                        <i className="fas fa-video pt-1 position-relative cartIcon ">
                          {(item.status === "0" || item.status === 0) && (
                            <i className="fas fa-ban position-absolute ms-1 mb-2 banned3"></i>
                          )}
                        </i>
                      ) : (
                        <i className="fas fa-image pt-1 position-relative cartIcon ">
                          {(item.status === "0" || item.status === 0) && (
                            <i className="fas fa-ban position-absolute ms-1 mb-2 banned3"></i>
                          )}
                        </i>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {item.title}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">
                        {item.type === "image"
                          ? "Image"
                          : item.type === "video"
                            ? "Video"
                            : null}
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-1 mt-xl-2 mt-lg-2 mt-md-2 pt-xl-1 pt-lg-1 pt-md-1 pt-0">
                  {item.relevant === "1" && (
                    <span className="badge teal me-1">Relevant</span>
                  )}
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span>
                  {item.createdAt && (
                    <span class="badge badge-light me-1 text-dark">
                      Created Date :{" "}
                      <span class="text-secondary">
                        <ModuleDisplayDate value={item.createdAt} />{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              <Row>
                <Col xl={5} lg={5} md={5} sm={4} xs={12}>
                  {item.type === "video" && (
                    <video
                      width="100%"
                      className="videoThumbnail"
                      height="200"
                      controls
                      autoplay
                    >
                      <source
                        src={
                          process.env.REACT_APP_BACKEND_URL +
                          "public/" +
                          item.video
                        }
                        type="video/mp4"
                      />
                    </video>
                  )}
                  {item.type === "image" && (
                    <Card bg="light" style={{ width: "14rem" }}>
                      <Card.Img
                        variant="top"
                        src={
                          process.env.REACT_APP_BACKEND_URL +
                          "public/" +
                          item.image
                        }
                      />
                      <div className="galleryImageBg">
                        <ReactFancyBox
                          thumbnail={
                            process.env.REACT_APP_BACKEND_URL +
                            "public/" +
                            item.image
                          }
                          image={
                            process.env.REACT_APP_BACKEND_URL +
                            "public/" +
                            item.image
                          }
                          caption="Banner Image"
                        />
                      </div>
                    </Card>
                  )}
                </Col>
              </Row>

              <StatsListing items={getItems()} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
