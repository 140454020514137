import * as yup from "yup"

export const imageSizeCheck = yup.addMethod(yup.mixed, 'imageSizeCheck', function (allowedSizeInBytes) {
    return this.test("image-size-check", async function (value) {
        //console.log("validating imageSizeCheck", value);
        const { path, createError } = this;
        //console.log(typeof value)
        if (!value || typeof value === 'string') {
            return true;
        }

        if (value.size > allowedSizeInBytes) {
            let sizeInMb = allowedSizeInBytes / (1024 * 1024);
            return createError({
                path,
                message: `Uploaded file is too big, only ${sizeInMb} MB allowed.`
            });
        }

        return true;
    });
});

export const imageTypeCheck = yup.addMethod(yup.mixed, 'imageTypeCheck', function (allowedTypes) {
    return this.test("image-type-check", async function (value) {
        //console.log("validating imageTypeCheck");
        console.log(typeof value)
        if (!value || typeof value === 'string') {
            return true;
        }

        const { path, createError } = this;

        if (!value) {
            return;
        }

        if (!allowedTypes.includes(value.type)) {
            let extensions = allowedTypes.map(type => type.replace('image/', ''))
            return createError({
                path,
                message: `Uploaded file extension is not allowed, the allowed extensions are ${extensions.join()}.`
            });
        }


        return true;
    });
});

export const videoTypeCheck = yup.addMethod(yup.mixed, 'videoTypeCheck', function (allowedTypes) {
    return this.test("video-type-check", async function (value) {
        //console.log("validating imageTypeCheck");
        console.log(typeof value)
        if (!value || typeof value === 'string') {
            return true;
        }

        const { path, createError } = this;

        if (!value) {
            return;
        }

        if (!allowedTypes.includes(value.type)) {
            let extensions = allowedTypes.map(type => type.replace('video/', ''))
            return createError({
                path,
                message: `Uploaded file extension is not allowed, the allowed extensions are ${extensions.join()}.`
            });
        }


        return true;
    });
});
export const videoSizeCheck = yup.addMethod(yup.mixed, 'videoSizeCheck', function (allowedSizeInBytes) {
    return this.test("video-size-check", async function (value) {
        //console.log("validating imageSizeCheck", value);
        const { path, createError } = this;
        console.log(typeof value)
        if (!value || typeof value === 'string') {
            return true;
        }

        if (value.size > allowedSizeInBytes) {
            let sizeInMb = allowedSizeInBytes / (1024 * 1024);
            return createError({
                path,
                message: `Uploaded file is too big, only ${sizeInMb} MB allowed.`
            });
        }

        return true;
    });
});

export const imageDimensionCheck = yup.addMethod(yup.mixed, 'imageDimensionCheck', function (requiredDimension) {
    return this.test("image-width-height-check", async function (value) {
        //console.log("validating imageDimensionCheck");
        const { path, createError } = this;
        //console.log(typeof value)
        if (!value || typeof value === 'string') {
            return true;
        }

        const imgDimensions = await imageWidthAndHeight(value);

        if (imgDimensions.width < requiredDimension.width) {
            return createError({
                path,
                message: `The file width needs to be the ${requiredDimension.width}px!`
            });
        }

        if (imgDimensions.height < requiredDimension.height) {
            return createError({
                path,
                message: `The file height needs to be the ${requiredDimension.height}px!`
            });
        }
        return true;
    });
});

const imageWidthAndHeight = (provideFile) => {
    // take the given file (which should be an image) and return the width and height
    const imgDimensions = { width: null, height: null };

    return new Promise(resolve => {
        const reader = new FileReader();
        //console.log(provideFile)
        if (!provideFile || typeof provideFile === 'string') {
            resolve(imgDimensions);
        }
        reader.readAsDataURL(provideFile);
        reader.onload = function () {
            // console.log(33333333333)
            // console.log(reader);
            const img = new Image();
            img.src = reader.result;

            img.onload = function () {
                // console.log(44444444)
                imgDimensions.width = img.width;
                imgDimensions.height = img.height;

                resolve(imgDimensions);
            }
            img.onerror = function () {
                // console.log(5555555555);
            }
        };
    });
}


// export const initThisFieldWith = yup.addMethod(yup.string, 'nullThisField', function (newValue) {
//     return this.transform((value) => newValue);
// });


// export const initThisFieldWith = yup.addMethod(yup.mixed, 'initThisFieldWith', function (newValue) {
//     return this.test("image-width-height-check", async function (value) {
//         console.log("validating imageDimensionCheck");
//         const { path, createError } = this;
//         //console.log(typeof value)
//         if (!value || typeof value === 'string') {
//             return true;
//         }

//         const imgDimensions = await imageWidthAndHeight(value);

//         if (imgDimensions.width !== requiredDimension.width) {
//             return createError({
//                 path,
//                 message: `The file width needs to be the ${requiredDimension.width}px!`
//             });
//         }

//         if (imgDimensions.height !== requiredDimension.height) {
//             return createError({
//                 path,
//                 message: `The file height needs to be the ${requiredDimension.height}px!`
//             });
//         }
//         return true;
//     });
// });

