import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import axios from "../../../config/axios";
import moduleConfig from "../../modules/payments/moduleConfig";
import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";
import ModuleDisplayArray from "../modules/fieldsDisplay/ModuleDisplayArray";
import ModuleDisplayDate from "../modules/fieldsDisplay/ModuleDisplayDate";
function PaymentContent({ setParent, parent }) {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    setParent(true);
    try {
      const res = await axios.get(`${moduleConfig.url}/stats-for-dashboard`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setParent(false);
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setParent(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      <Row className="my-2">
        <Col>
        {listingState.hasFetchingError && (
        <Alert variant="warning mb-0 mt-1">
          OOPS!!! Something went wrong, please try again later.
        </Alert>
      )}
          {listingState.data && listingState.data.summary && (
            <>
              <Row className="my-2">
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a href="/payments" title="Please click to view all payments">
                    <div class="info-box bg-gradient-blue">
                      <span class="info-box-icon">
                        <i class="fa fa-dollar-sign"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Total Payments</span>
                        <span class="info-box-number">
                          {listingState.data.summary.total}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a
                    href="/payments?status=1"
                    title="Please click to view paid payments"
                  >
                    <div class="info-box bg-gradient-success">
                      <span class="info-box-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Paid Payments</span>
                        <span class="info-box-number">
                          {listingState.data.summary.paid}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                  <a
                    href="/payments?status=0"
                    title="Please click to view pending payments"
                  >
                    <div class="info-box bg-gradient-yellow">
                      <span class="info-box-icon">
                        <i class="fas fa-clock"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Pending Payments</span>
                        <span class="info-box-number">
                          {listingState.data.summary.pending}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>
              <Row className="mt-2 mb-3">
                <Col>
                  <h5 className="  text-bold">Recent Payments</h5>
                </Col>
                {listingState.data &&
                  listingState.data.summary &&
                  listingState.data.summary.total > 0 && (
                    <Col>
                      <Button
                        title="Please click to view all payments"
                        variant="primary"
                        className="float-end"
                        href="/payments"
                        size="sm"
                      >
                        View all
                      </Button>
                    </Col>
                  )}
              </Row>
            </>
          )}
          <Row>
            <Col>
              {listingState.data.items &&
                listingState.data.items.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1">No records found.</Alert>
                )}
              {listingState.data.items &&
                listingState.data.items.length > 0 && (
                  <>
                    {console.log(listingState.data.items)}

                    <div className="registrationTable overflow-auto m-0 p-0 mt-2">
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Full Name</th>
                            <th>Order Id</th>
                            <th>Method</th>
                            <th className="text-center"> Amount</th>
                            <th className="text-center">Payment Status</th>
                            <th>Payment Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {listingState.data.items.map((item, index) => {
                            return (
                              <tr index={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Button
                                    title="Please click to view buyer details"
                                    href={`/buyers/${item.buyer._id}`}
                                    variant="link"
                                    className="text-red text-bold"
                                  >
                                    {item.buyer ? getFullName(item.buyer) : ""}
                                  </Button>
                                </td>
                                <td>{item.orderId}</td>
                                <td>{item.method}</td>
                                <td className="text-center">
                                  {item.paidAmount}
                                </td>
                                <td className="text-center">
                                  <ModuleDisplayBoolean
                                    value={item.status}
                                    trueValue="Paid"
                                    falseValue="Pending"
                                  />
                                </td>
                                <td>
                                  <ModuleDisplayDate value={item.date} />
                                </td>
                                <td
                                  className={`text-center record-actions  MoreItem`}
                                >
                                  <Link
                                    title="Please click to view details"
                                    to={`/${moduleConfig.url}/${item._id}`}
                                    className="btn btn-outline-secondary btn-sm"
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default PaymentContent;
