import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { Form, Formik } from 'formik';
import * as yup from 'yup'

import { ContainerLoading } from '../../../UI/loading/ContainerLoading';
import FormErrorMessage from '../../../UI/errorMessages/FormErrorMessage';
import CMSFormHidden from '../../../UI/forms/CMSFormHidden';
import CMSFormInput from '../../../UI/forms/CMSFormInput';


const AddLookup = ({ lookupId, submitLookupValue, lookupState }) => {

    // console.log("lookupState Error", lookupState.error);

    //const [formState, formDispatch] = useReducer(formReducer, formInitialState)

    return (
        <Formik
            initialValues={{
                lookupId: lookupId,
                value: '',
            }}
            validationSchema={yup.object({

                lookupId: yup.string().required("Required"),
                value: (lookupId === "63da0b6c88894488527aae61") ? yup.string().max(25).required("Required") : yup.string().required("Required"),
            })}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm }) => {
                await submitLookupValue(values)
                resetForm()

            }}
        >
            {(form) => (
                <Form onSubmit={form.handleSubmit}>
                    {(lookupState.isSubmitting) && <ContainerLoading />}



                    <CMSFormHidden name="lookupId" type="hidden" value={lookupId} form={form} />
                    <Row className="ps-2 ms-2" >
                        <Col xs={12} md={8} lg={8}>
                            <CMSFormInput name="value" type="text" label="" form={form} disabled={lookupState.isSubmitting} />
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <button className="btn btn-primary " type="submit" disabled={lookupState.isSubmitting}>Add Value</button>
                        </Col>
                    </Row>
                    {(lookupState.hasSubmissionError) &&
                        <FormErrorMessage error={lookupState.error ? lookupState.error : "Action failed, try again later."} />
                    }
                </Form>
            )}
        </Formik>



    );
}
export default AddLookup;
