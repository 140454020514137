import React, { useReducer, useEffect, useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import {
  formInitialState,
  formReducer,
} from "../../../../../reducers/formReducer";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormTextArea from "../../../../UI/forms/CMSFormTextArea";
import { Button } from "react-bootstrap";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";

import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import {
  detailsReducer,
  detailsInitialState,
} from "../../reducers/detailsReducer";
import moduleConfig from "../../moduleConfig";

import axiosInstance from "../../../../../config/axios";
import axios from "axios";
import CMSFormTelephone from "../../../../UI/forms/CMSFormTelephone";

const OrderUpdateModal = ({ show, setShow, loadItem, item }) => {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState(false);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const loadCountries = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(process.env.REACT_APP_BACKEND_URL+"web/countries");

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      console.log(error);
    }
  };
  useEffect(() => {
    loadCountries();
  }, []);
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].name, value: item[i].name });
    }

    return array;
  };
  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    console.log(data);
    try {
      const res = await axiosInstance.post(
        `${moduleConfig.url}/update-order`,
        data
      );
      formDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      handleClose();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Action done successfully.`,
        type: "success",
      });
      resetForm();
      formDispatch({
        type: "FORM_INITIAL_STAGE",
      });
      loadItem();
    } catch (error) {
      console.log(error);
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });

      setTimeout(() => {
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  const getInitialValues = () => {
    let returnObj = {
      id: item._id,
      firstName:
        item.shippingAddress && item.shippingAddress.firstName
          ? item.shippingAddress.firstName
          : "",
      lastName:
        item.shippingAddress && item.shippingAddress.lastName
          ? item.shippingAddress.lastName
          : "",
      email:
        item.shippingAddress && item.shippingAddress.email
          ? item.shippingAddress.email
          : "",
      phone:
        item.shippingAddress && item.shippingAddress.phone
          ? item.shippingAddress.phone
          : "",
      city:
        item.shippingAddress && item.shippingAddress.city
          ? item.shippingAddress.city
          : "",
      state:
        item.shippingAddress && item.shippingAddress.state
          ? item.shippingAddress.state
          : "",
      country:
        item.shippingAddress && item.shippingAddress.country
          ? item.shippingAddress.country
          : "",
      postCode:
        item.shippingAddress && item.shippingAddress.postCode
          ? item.shippingAddress.postCode
          : "",
      addressLine1:
        item.shippingAddress && item.shippingAddress.addressLine1
          ? item.shippingAddress.addressLine1
          : "",
      addressLine2:
        item.shippingAddress && item.shippingAddress.addressLine2
          ? item.shippingAddress.addressLine2
          : "",
      adminComments: item.adminComments ? item.adminComments : "",
      deliveryMethod: item.deliveryMethod ? item.deliveryMethod : "Pickup",
    };

    return returnObj;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={yup
        .object({
          deliveryMethod: yup.string().required("Required"),
          adminComments: yup.string().nullable().max(200),
        })
        .shape(
          {
            firstName: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().required("Required"),
            }),
            lastName: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().required("Required"),
            }),
            email: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().email().required("Required"),
            }),
            phone: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup
                .string()
                .required("Required")
                .min(8, "Please enter complete number"),
            }),
            city: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().required("Required"),
            }),
            state: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string(),
            }),
            country: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().required("Required"),
            }),
            postCode: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().required("Required"),
            }),
            addressLine1: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string().required("Required"),
            }),
            addressLine2: yup.string().when("deliveryMethod", {
              is: "Delivery",
              then: yup.string(),
            }),
          },
          [
            [
              "firstName",
              "lastName",
              "email",
              "phone",
              "city",
              "state",
              "country",
              "postCode",
              "addressLine1",
              "addressLine2",
            ],
          ]
        )}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { resetForm }) => {
        // onSubmit(values, resetForm);
        var newValue = values;
        if (newValue.deliveryMethod === "Pickup") {
          newValue = {
            ...values,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            country: "",
            postCode: "",
            addressLine1: "",
            addressLine2: "",
          };
          onSubmit(newValue, resetForm);
        } else if (newValue.deliveryMethod === "Delivery") {
          onSubmit(newValue, resetForm);
        }
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose}>
          {formState.isFormSubmitting && (
            <div class="overlay">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {/* {(formState.isFormSubmitting) && <ContainerLoading />} */}
          <Form onSubmit={form.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Shipping Details</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {formState.hasSubmissionError && (
                <FormErrorMessage error={formState.formSubmissionError} />
              )}
              <CMSFormSelect
                name="deliveryMethod"
                label="Delivery Method"
                required={true}
                form={form}
                options={[
                  { label: "Pickup from store", value: "Pickup" },
                  { label: "Deliver to address", value: "Delivery" },
                ]}
              />
              {form.values.deliveryMethod === "Delivery" && (
                <>
                  <CMSFormInput
                    name="firstName"
                    label="First Name"
                    required={true}
                    form={form}
                  />
                  <CMSFormInput
                    name="lastName"
                    label="Last Name"
                    required={true}
                    form={form}
                  />
                  <CMSFormInput
                    name="email"
                    label="Email"
                    required={true}
                    form={form}
                  />
                  {console.log(form.errors)}
                  <CMSFormTelephone
                    name="phone"
                    label="Phone"
                    form={form}
                    required={true}
                    errorState={error}
                    setErrorState={setError}
                  />

                  <CMSFormInput
                    name="city"
                    label="City"
                    required={true}
                    form={form}
                  />
                  <CMSFormInput
                    name="state"
                    label="State"
                    required={false}
                    form={form}
                  />

                  <CMSFormSelect
                    name="country"
                    label="Country"
                    form={form}
                    required={true}
                    options={
                      detailsState &&
                      detailsState.data &&
                      detailsState.data.items &&
                      handleFormatArray(detailsState.data.items)
                    }
                  />
                  <CMSFormInput
                    name="postCode"
                    label="Post Code"
                    required={true}
                    form={form}
                  />
                  <CMSFormInput
                    name="addressLine1"
                    label="Address"
                    form={form}
                    required={true}
                  />
                  <CMSFormInput
                    name="addressLine2"
                    label="Address Line 2"
                    form={form}
                    required={false}
                  />
                </>
              )}
              <CMSFormTextArea
                name="adminComments"
                label="Admin Comments"
                form={form}
                required={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default OrderUpdateModal;
