import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig";
import * as Yup from "yup";
let moduleConfig = {};
moduleConfig = {
  ...moduleDefaultConfig,
};

moduleConfig.hasStatusSearch = true;

moduleConfig.title = "Newsletter Submissions";
moduleConfig.singleItemTitle = "Newsletter";
moduleConfig.url = "subscribers";

moduleConfig.buttons = {
  create: false,
  view: true,
  update: false,
  delete: true,
};

moduleConfig.bulkActions = [
  {
    displayName: "Delete Selected",
    value: "Delete",
  },
];

moduleConfig.fields = [
  {
    displayName: "ID",
    fieldName: "_id",
    type: "text",
    control: "hidden",
  },
  {
    displayName: "Email",
    fieldName: "email",
    type: "email",
    control: "input",
    showOnListing: true,
    sortable: true,
  },
  {
    displayName: "Submission Date/Time",
    fieldName: "createdAt",
    type: "dateTime",
    showOnListing: true,
  },
  {
    displayName: "Status",
    fieldName: "status",
    control: "select",
    options: [
      { label: "Subscribed", value: 1 },
      { label: "Unsubscribed", value: 0 },
    ],
    trueValue: "Subscribed",
    falseValue: "Unsubscribed",
    type: "boolean",
    showOnListing: true,
    sortable: true,
  },

  {
    displayName: "Submission Date/Time",
    fieldName: "createdAt",
    type: "dateTime",
  },
  {
    displayName: "Updated Date/Time",
    fieldName: "updatedAt",
    type: "dateTime",
  },
];


moduleConfig.statusOptions = [
  {
    label: "Select Status...",
    value: "",
  },
  {
    label: "Subscribed",
    value: "enabled",
  },
  {
    label: "Unsubscribed",
    value: "disabled",
  },
]
moduleConfig.initialValues = {};
moduleConfig.validationSchema = Yup.object({});

export default moduleConfig;
