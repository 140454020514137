import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayRichText from "../../../../UI/modules/fieldsDisplay/ModuleDisplayRichText";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import parse from "html-react-parser";
const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getItems = () => {
    let items = [];

    if (item.shortDescription) {
      items = [
        ...items,
        {
          text: "Short Description",
          value: item.shortDescription,
          type: "text",
        },
      ];
    }

    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      <i className="fas fa-newspaper position-relative usericon ">
                        {(item.status === "0" || item.status === 0) && (
                          <i className="fas fa-ban position-absolute ms-1  banned2"></i>
                        )}
                      </i>
                    </div>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {item.title}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">{item.date}</span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools endColumn">
                <div className=" text-right  mt-1 mt-xl-2 mt-lg-2 mt-md-2 pt-xl-1 pt-lg-1 pt-md-1 pt-0">
                  {(item.showOnHomePage === "1" ||
                    item.showOnHomePage === 1) && (
                    <span className="badge homePageShow me-1 text-white">
                      Showing on Home Page
                    </span>
                  )}
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span>
                  {item.createdAt && (
                    <span class="badge badge-light me-1 text-dark">
                      Created Date :{" "}
                      <span class="text-secondary">
                        <ModuleDisplayDate value={item.createdAt} />{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              <Row>
              
                  {item.image && (
                    <Col sm={12} xs={12} className="newsImageCol">
                    <div className="newsImage mt-2">
                      <ReactFancyBox
                        thumbnail={
                          process.env.REACT_APP_BACKEND_URL +
                          "public/" +
                          item.image
                        }
                        image={
                          process.env.REACT_APP_BACKEND_URL +
                          "public/" +
                          item.image
                        }
                        caption="Album Cover"
                      />
                    </div>
                    </Col>
                  )}
              
                <Col>
                  {/* <StatsListing items={getItems()} /> */}
                  {item.shortDescription && (
                    <>
                      <Alert variant="light mb-0 pb-1 mb-2 px-2 pt-0 messageWidth">
                        <span className=" m-0 text-bold text-dark px-0 font-small ">
                          Short Description: <span className="font-small text-normal ms-0 me-1 px-1 text-dark mb-0">
                          {parse(item.shortDescription)}
                        </span>
                        </span>
                        
                      </Alert>
                    </>
                  )}
                  {item.description && (
                    <>
                      <Alert variant="secondary lightGray mb-0 pb-1 mt-2 px-1 pt-0 messageWidth">
                      {/* <span className="m-0 text-bold text-dark px-1 font-small">
                          Short Description:
                        </span>
                        <p className="font-small me-1 px-1 text-dark mb-0">
                          {parse(item.shortDescription)}
                        </p> */}
                        <span className="m-0 text-bold text-dark px-1 font-small">
                          Description:
                        </span>
                        <p className="font-small me-1 px-1 text-dark mb-0">
                          {parse(item.description)}
                        </p>
                      </Alert>
                    </>
                  )}
                </Col>
                
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
