import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import orderPending from "../../../../../images/file.png";
import orderCancel from "../../../../../images/cancelOrder.png";
import orderCompleted from "../../../../../images/delivery.png";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import {
  getCurrencyCode,
  getFullName,
} from "../../../../../helpers/CommonHelper";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];

    if (item.numberOfProducts) {
      items = [
        ...items,
        { text: "Number Of Products", value: item.numberOfProducts },
      ];
    }
    if (item.deliveryMethod) {
      items = [
        ...items,
        { text: "Delivery Method", value: item.deliveryMethod, type: "text" },
      ];
    }
    return items;
  };
  const getPrice = () => {
    let items = [];

    if (item.shippingCharges) {
      items = [
        ...items,
        { text: "Shipping Charges", value: item.shippingCharges },
      ];
    }
    if (item.subTotal) {
      items = [...items, { text: "Sub Total", value: item.subTotal }];
    }
    if (item.totalAmount) {
      items = [...items, { text: "Total Amount", value: item.totalAmount }];
    }
    return items;
  };
  const getBuyer = () => {
    let items = [];

    if (item.buyer && item.buyer.fullName) {
      items = [
        ...items,
        {
          title: "Full Name",
          value: item.buyer.fullName,
          type: "text",
          url: `/buyers/${item.buyerId}`,
        },
      ];
    }

    return items;
  };
  const getBuyerEmail = () => {
    let items = [];
    if (item.buyer && item.buyer.email) {
      items = [
        ...items,
        { text: "Email", value: item.buyer.email, type: "email" },
      ];
    }
    if (item.buyer && item.buyer.phone) {
      items = [
        ...items,
        {
          text: "Phone",
          value: item.buyer.phone,
          type: "phone",
        },
      ];
    }
    return items;
  };
  return (
    <Card className="item mb-2 borderCream ">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}

            <div className="ms-1 me-2">
              {item.orderStatus === "0" ? (
                <img src={orderPending} className="orderImage" />
              ) : item.orderStatus === "2" ? (
                <img src={orderCancel} className="orderImage" />
              ) : (
                <img src={orderCompleted} className="orderImage" />
              )}
              {/* <i className="fa fa-shopping-cart pt-1 position-relative cartIcon "></i> */}
            </div>

            <div className="d-flex flex-column  ms-1">
              <span className="m-0 text-bold text-red">
                {" "}
                {/* <a href={`/buyers/${item._id}`} className="hoveredText">
                  {item.buyer.fullName ? item.buyer.fullName : getFullName(item.buyer)}
                </a> */}
                {item.orderId}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">
                  {item.checkoutType === "Online Payment"
                    ? item.checkoutType
                    : item.checkoutType + " Checkout"}
                </span>
              </span>
            </div>
            <div className="d-flex flex-column  ms-1">
              <h4 className="my-0 me-0 ms-1 text-bold text-black">
                {" "}
                ({item.totalAmount} {getCurrencyCode()})
              </h4>
            </div>
          </div>
        </Card.Title>
        {console.log(item)}

        <div class="card-tools endColumn mb-1 pt-1">
          <div>
          {(item.orderStatus === "0" || item.orderStatus === 0) && (
                    <span className="badge badge-warning me-1 text-dark">
                      Processing
                    </span>
                  )}
                  {(item.orderStatus === "1" || item.orderStatus === 1) && (
                    <span className="badge badge-success me-1 text-white">
                      Completed
                    </span>
                  )}
                  {(item.orderStatus === "2" || item.orderStatus === 2) && (
                    <span className="badge badge-danger me-1 text-white">
                      Cancelled
                    </span>
                  )}
            {item.payment && (
              <>
                {item.payment.status && (
                  <span class="badge badge-light me-1 mt-1 text-dark">
                    Payment Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.payment.status}
                        trueValue="Paid"
                        falseValue="Pending"
                        trueColor="info"
                        falseColor="pink"
                      />{" "}
                    </span>
                  </span>
                )}
              </>
            )}

            {item.createdAt && (
              <span class="badge badge-light me-1 mt-1 text-dark">
                Order Submission Date :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="px-1 py-0">
        <Row>
          <Col className="mt-1 mb-1 ">
            {item.orderStatus === "2" && (
              <Alert key="danger" variant="danger" className="px-2 py-0 mb-0">
                <b>
                  Cancellation Reason :{" "}
                  <i className="fs-8 text-normal"> {item.adminComments} </i>
                </b>
              </Alert>
            )}
            <StatsListing items={getItems()} />

            <div className="d-flex">
              <StatsListing
                heading="Buyer"
                clickable={true}
                items={getBuyer()}
              />
              <StatsListing items={getBuyerEmail()} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
