import React from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ReactFancyBox from "react-fancybox";
import parse from "html-react-parser";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import {
  getCurrencyCode,
  getFullName,
} from "../../../../../helpers/CommonHelper";
import orderPending from "../../../../../images/file.png";
import orderCancel from "../../../../../images/cancelOrder.png";
import orderCompleted from "../../../../../images/delivery.png";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getBuyer = () => {
    let items = [];

    if (item.buyer.fullName) {
      items = [
        ...items,
        {
          title: "Full Name",
          value: item.buyer.fullName,
          type: "text",
          url: `/buyers/${item.buyerId}`,
        },
      ];
    }

    return items;
  };
  const getBuyerEmail = () => {
    let items = [];
    if (item.buyer && item.buyer.email) {
      items = [
        ...items,
        { text: "Email", value: item.buyer.email, type: "email" },
      ];
    }

    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      {item.orderStatus === "0" ? (
                        <img src={orderPending} className="orderImage" />
                      ) : item.orderStatus === "2" ? (
                        <img src={orderCancel} className="orderImage" />
                      ) : (
                        <img src={orderCompleted} className="orderImage" />
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column  ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {" "}
                      {item.orderId}
                    </span>
                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">
                        {item.checkoutType === "Online Payment"
                          ? item.checkoutType
                          : item.checkoutType + " Checkout"}
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools endColumn">
                <div className="   mt-xl-2 mt-lg-2 mt-md-2 pt-xl-1 pt-lg-1 pt-md-1">
                  {(item.orderStatus === "0" || item.orderStatus === 0) && (
                    <span className="badge badge-warning me-1 text-dark">
                      Processing
                    </span>
                  )}
                  {(item.orderStatus === "1" || item.orderStatus === 1) && (
                    <span className="badge badge-success me-1 text-white">
                      Completed
                    </span>
                  )}
                  {(item.orderStatus === "2" || item.orderStatus === 2) && (
                    <span className="badge badge-danger me-1 text-white">
                      Cancelled
                    </span>
                  )}

                  {item.deliveryMethod === "Delivery" && (
                    <span class="badge peach me-1">Delivery</span>
                  )}
                  {item.deliveryMethod === "Pickup" && (
                    <span class="badge purple me-1">Pickup</span>
                  )}
                  {item.createdAt && (
                    <span class="badge badge-light me-1 text-dark">
                      Order Submission Date :{" "}
                      <span class="text-secondary">
                        <ModuleDisplayDate value={item.createdAt} />{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              {/* <div className="d-flex mb-2">
                <StatsListing
                  heading="Buyer"
                  clickable={true}
                  items={getBuyer()}
                />
                <StatsListing items={getBuyerEmail()} />
              </div> */}
              {item.orderStatus !== "2" && item.adminComments && (
                <Alert
                  key="danger"
                  variant="secondary"
                  className="px-2 py-0 mb-2"
                >
                  <b>
                    Admin Comments :{" "}
                    <i className="fs-8 text-normal"> {item.adminComments} </i>
                  </b>
                </Alert>
              )}
              {item.orderStatus === "2" && (
                <Alert key="danger" variant="danger" className="px-2 py-0 mb-2">
                  <b>
                    Cancellation Reason :{" "}
                    <i className="fs-8 text-normal"> {item.adminComments} </i>
                  </b>
                </Alert>
              )}
              <Row>
                {item.deliveryMethod === "Delivery" && item.shippingAddress && (
                  <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Card border="primary" className="borderCream">
                      <Card.Header className="py-1 px-2 bgCream">
                        <h6 className="text-bold text-dark mb-0">
                          Shipping Details
                        </h6>
                      </Card.Header>
                      <Card.Body className="p-1">
                        <Row>
                          {item.shippingAddress && (
                            <Col className="mb-xl-0 mb-lg-0 mb-md-2 mb-sm-2 mb-2 py-0 ">
                              <div>
                                <p className="mb-0 fs-5 text-red text-bold">
                                  {/* <i class="fa fa-user ms-1 me-2 text-secondary" aria-hidden="true"></i> */}
                                  {item.shippingAddress.firstName}{" "}
                                  {item.shippingAddress.lastName}
                                </p>

                                <i className="mb-0 text-dark">
                                  {/* <i class="fa fa-envelope me-2 ms-1 text-secondary" aria-hidden="true"></i> */}
                                  <ModuleDisplayEmail
                                    text="dark"
                                    value={item.shippingAddress.email}
                                  />{" "}
                                </i>

                                <p className="mb-0 text-dark">
                                  {/* <i class="fa fa-phone me-2 ms-1 text-secondary" aria-hidden="true"></i> */}

                                  <ModuleDisplayPhone
                                    text="secondary"
                                    value={item.shippingAddress.phone}
                                  />
                                </p>
                                <p className="mb-0 text-dark">
                                  {/* <i class="fa fa-address-card text-secondary ms-1 me-2" aria-hidden="true"></i> */}

                                  <span className="text-primaryCream">
                                    {item.shippingAddress.addressLine1} <br />
                                    {item.shippingAddress.addressLine2}
                                  </span>
                                </p>
                                <p className="mb-0 text-red">
                                  {/* <i class="fa fa-map-pin text-secondary ms-1 me-2" aria-hidden="true"></i> */}

                                  <i>
                                    {item.shippingAddress.city}
                                    {item.shippingAddress.state
                                      ? "," + item.shippingAddress.state
                                      : ""}
                                    {item.shippingAddress.country
                                      ? "," + item.shippingAddress.country
                                      : ""}
                                  </i>
                                </p>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Link
                    title="Please click to view payment details"
                    to={`/payments/${item.payment[0]._id}`}
                    target="_blank"
                  >
                    <Card border="primary" className="borderCream">
                      <Card.Header className="py-1 px-2 bgCream">
                        <h6 className="text-bold text-dark mb-0">Payment</h6>
                      </Card.Header>
                      <Card.Body className="p-1">
                        <Row>
                          {item.payment &&
                            item.payment.map((payment, index) => {
                              return (
                                <Col className="mb-xl-0 mb-lg-0 mb-md-2 mb-sm-2 mb-2 py-0 ">
                                  <div>
                                    {payment.date && (
                                      <p className="mb-1 text-dark">
                                        Date -{" "}
                                        <span className="text-red">
                                          {" "}
                                          <ModuleDisplayDate
                                            value={payment.date}
                                          />
                                        </span>
                                      </p>
                                    )}
                                    {payment.method && (
                                      <p className="mb-1 text-dark">
                                        Payment Method -{" "}
                                        <span className="text-red">
                                          {" "}
                                          {payment.method}{" "}
                                        </span>
                                      </p>
                                    )}
                                    {payment.transactionId && (
                                      <p className="mb-1 text-dark">
                                        Transaction ID-{" "}
                                        <span className="text-red">
                                          {" "}
                                          {payment.transactionId}{" "}
                                        </span>
                                      </p>
                                    )}
                                    {payment.paidAmount && (
                                      <p className="mb-1 text-dark">
                                        {payment.status === "0"
                                          ? "Payable Amount"
                                          : "Paid Amount"}{" "}
                                        -{" "}
                                        <span className="text-red">
                                          {" "}
                                          {payment.paidAmount} $
                                        </span>
                                      </p>
                                    )}
                                    {payment.status && (
                                      <p className="mb-1 text-dark">
                                        Status -
                                        <span className="text-red ms-2">
                                          <ModuleDisplayBoolean
                                            value={payment.status}
                                            trueValue="Paid"
                                            falseValue="Pending"
                                            trueColor="info"
                                            falseColor="pink"
                                          />{" "}
                                        </span>
                                      </p>
                                    )}
                                    {item.totalAmount && (
                                      <h4 className="mb-1 text-bold text-dark">
                                        Total -
                                        <span className="text-red ms-2">
                                          {item.totalAmount} {getCurrencyCode()}
                                        </span>
                                      </h4>
                                    )}
                                    {payment.adminComments && (
                                      <Alert
                                        variant="secondary"
                                        className="mb-0 mt-2 p-1 lightGray"
                                      >
                                        <span className="text-normal mb-0 adminCommentsHead">
                                          Admin Comments -{" "}
                                        </span>
                                        <span className="text-dark adminComments">
                                          {" "}
                                          {payment.adminComments}{" "}
                                        </span>
                                      </Alert>
                                    )}
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Link>

                  {/* <a href="#" class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a> */}
                </Col>
                <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                  <div class="info-box bgCream">
                    <span class="info-box-icon">
                      <Link
                        to={`/buyers/${item.buyer._id}`}
                        title="Please click to view buyer details"
                      >
                        <i class="fas fa-user text-dark"></i>
                      </Link>
                    </span>

                    <div class="info-box-content">
                      <Link
                        to={`/buyers/${item.buyer._id}`}
                        title="Please click to view buyer details"
                      >
                        <span class="info-box-number text-dark">Buyer</span>
                      </Link>
                      <div class="progress">
                        <div class="progress-bar"></div>
                      </div>
                      {console.log(item)}
                      <span class="info-box-text">
                        {" "}
                        <Link
                          to={`/buyers/${item.buyer._id}`}
                          title="Please click to view buyer details"
                          className="text-red text-bold mt-1"
                        >
                          <span>{item.buyer && item.buyer.fullName}</span>
                        </Link>
                      </span>
                      <i class="info-box-text ">
                        {" "}
                        <ModuleDisplayEmail
                          value={item.buyer && item.buyer.email}
                          text="dark"
                        />
                      </i>
                      <span class="info-box-text">
                        {" "}
                        <ModuleDisplayPhone
                          value={item.buyer && item.buyer.phone}
                          text="dark"
                        />
                      </span>
                      <span class="info-box-text">
                        {" "}
                        {item.buyer && item.buyer.country}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <h6 className="text-bold ms-1 mb-3 mt-2">
                Ordered Item(s) - {item.orderedItems.length}
              </h6>

              <Row>
                {item.orderedItems &&
                  item.orderedItems.length > 0 &&
                  item.orderedItems.map((item, index) => {
                    return (
                      <>
                        {item.product &&
                          item.product.length > 0 &&
                          item.product.map((product, index) => {
                            return (
                              <Col xl={4} lg={6} md={6} sm={6} xs={12}>
                                <div class="info-box borderCream">
                                  <span class="info-box-icon">
                                    <div className="smallImage">
                                      <ReactFancyBox
                                        thumbnail={
                                          process.env.REACT_APP_BACKEND_URL +
                                          "public/" +
                                          product.image
                                        }
                                        image={
                                          process.env.REACT_APP_BACKEND_URL +
                                          "public/" +
                                          product.image
                                        }
                                        caption={product.genus}
                                      />
                                    </div>
                                  </span>
                                  <div class="info-box-content ps-4">
                                  {console.log(product)}
                                    <Link
                                      to={`/fossils-saleable/${product._id}`}
                                      title="Please click to view fossil details"
                                    >
                                      <h6 class="info-box-number mb-0 text-red">
                                        {product.genus}
                                      </h6>
                                    </Link>

                                    <span class="info-box-text">
                                      {product.category}
                                    </span>
                                    <i class="info-box-text text-primaryCream">
                                      {product.subCategory}
                                    </i>
                                    {item.quantity > 1 && (
                                      <span class="info-box-text text-secondary">
                                        Quantity - {item.quantity}
                                      </span>
                                    )}

                                    <span class="info-box-number">
                                      {item.price} $
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </>
                    );
                  })}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
