import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'

const ModuleSearchBox = ({ setFilter, appliedFilters }) => {
    const [searchQuery, setSearchQuery] = useState('')
    useEffect(() => {
        setSearchQuery(((appliedFilters.q) ? appliedFilters.q : ''))
    }, [appliedFilters])

    //console.log(searchQuery);
    return <div className="searchbox m-0 p-0">
        <form onSubmit={e => {
            console.log('submit');
            e.preventDefault()
            setFilter('q', searchQuery);
        }}>
            <div className="input-group " >
                <input
                    type="text"
                    name="table_search"
                    className="form-control float-right moduleSearch"
                    placeholder="Search Here"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    onBlur={() => setFilter('q', searchQuery)}
                />

            </div>
        </form>
    </div>;
};

export default ModuleSearchBox;

