import React, { useReducer, useEffect } from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";
import ModuleDynamicFilterDropdown from "../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown";
import axios from "../../../../../config/axios";
import {
  detailsInitialState,
  detailsReducer,
} from "../../../publications/reducers/detailsReducer";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i], value: item[i] });
    }

    return array;
  };
  return (
    <>
      {(appliedFilters.limit ||
        appliedFilters.q ||
        appliedFilters.category ||
        appliedFilters.status) && (
        <div div className=" text-right ">
          <span className="resetFilters" onClick={() => resetFilters()}>
            <i className="fas fa-times" /> Reset Filters
          </span>
        </div>
      )}

      <div className="position-relative">
        <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
          <Card.Body className=" p-0 m-0">
            <Row className="mb-0">
              {moduleConfig.hasPagination === true &&
                moduleConfig.perPageItemsOptions &&
                moduleConfig.perPageItemsOptions.length > 0 && (
                  <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                    <ModuleRecordsPerPageDropdown
                      selectedPerPageItems={moduleConfig.perPageItems}
                      perPageItemsOptions={moduleConfig.perPageItemsOptions}
                      setFilter={setFilter}
                    />
                  </Col>
                )}
              <Col className="col-xl-6 col-lg-6 col-md-6  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                <ModuleDynamicFilterDropdown
                  filterField="category"
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                  options={[
                    {
                      label: "Select category",
                      value: "",
                    },
                    {
                      label: "Plants",
                      value: "Plants",
                    },
                    {
                      label: "Invertebrates",
                      value: "Invertebrates",
                    },
                    {
                      label: "Vertebrates",
                      value: "Vertebrates",
                    },
                  ]}
                />
              </Col>

              {moduleConfig.hasStatusSearch === true && (
                <Col className="col-xl-6 col-lg-6 col-md-6  col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                  <ModuleStatusDropdown
                    setFilter={setFilter}
                    appliedFilters={appliedFilters}
                    statusOptions={[
                      {
                        label: "Select Status...",
                        value: "all",
                      },
                      {
                        label: "Enabled",
                        value: "1",
                      },
                      {
                        label: "Disabled",
                        value: "0",
                      },
                    ]}
                  />
                </Col>
              )}
              {moduleConfig.hasSearch === true && (
                <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                  <ModuleSearchBox
                    setFilter={setFilter}
                    appliedFilters={appliedFilters}
                  />
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default SearchArea;
