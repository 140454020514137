import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Admins';
moduleConfig.singleItemTitle = 'Admin';
moduleConfig.url = 'admins';

moduleConfig.fields = [
    {
        displayName: "ID",
        fieldName: "_id",
        type: "text",
        control: "hidden",
    },
    {
        displayName: "First Name",
        fieldName: "firstName",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
        required:true,
    },
    {
        displayName: "Last Name",
        fieldName: "lastName",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
    
    },
    {
        displayName: "Email",
        fieldName: "email",
        type: "email",
        control: "input",
        showOnListing: true,
        sortable: true,
        required:true,
    },
    {
        displayName: "Password",
        fieldName: "password",
        type: "password",
        control: "input",
        hideOnUpdate: true,
        sortable: true,
        required:true,
    },
    {
        displayName: "Confirm Password",
        fieldName: "confirm_password",
        type: "password",
        control: "input",
        hideOnDetails: true,
        hideOnUpdate: true,
        required:true,
    },
    {
        displayName: "Status",
        fieldName: "status",
        control: "select",
        required:true,
        options: [
            { label: "Enabled", value: 1 },
            { label: "Disabled", value: 0 },
        ],
        type: "boolean",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Created Date/Time",
        fieldName: "createdAt",
        type: "dateTime",
    },
    {
        displayName: "Updated Date/Time",
        fieldName: "updatedAt",
        type: "dateTime",
    }
];
moduleConfig.initialValues = {
    _id: '',
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
    status: 1
}
moduleConfig.validationSchema = yup.object(
    {
        confirm_password: yup.string()
            .when('_id', {
                is: (_id) => {
                    return _id === '' || _id === undefined
                },
                then: yup.string().required("Required")
            })
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        password: yup.string()
            .when('_id', {
                is: (_id) => {
                    return _id === '' || _id === undefined
                },
                then: yup.string().required("Required")
            }),
        status: yup.number().required("Required"),
        email: yup.string().required("Required").email(),
        lastName: yup.string().max(30,"Last name should contain at most 30 characters"),
        firstName: yup.string().required("Required").max(30,"First name should contain at most 30 characters"),
    }
)


export default moduleConfig;