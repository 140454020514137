import React, { useState } from "react";
import { Alert, Card, Col, ListGroup, Row } from "react-bootstrap";
import ReactFancyBox from "react-fancybox";
import UpdateGalleryItem from "./UpdateGalleryItem";
import videoDummy from "../../../../../images/videoThumb.png";
import VideoExpanded from "./VideoExpanded";
const GalleryPicture = ({
  picture,
  loadItem,
  confirmAndRemovePicture,
  confirmAndArchivePicture,
  confirmAndRestorePicture,
}) => {
  const [show, setShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  return (
    <div className="card overflow-hidden ">
      {console.log(picture)}
      <div
        className={`card-header px-2 py-1 text-center ${
          picture.type === "image" ? "bgCream" : "bgOrange"
        }`}
      >
        {picture.type === "image" ? "Image" : "Video"}
      </div>

      <div className="card-body d-flex align-items-center justify-content-center p-0">
        <div className="galleryImageFull photoInsideAlbum">
          {picture.type === "image" && (
            <ReactFancyBox
              thumbnail={
                process.env.REACT_APP_BACKEND_URL + "public/" + picture.image
              }
              image={
                process.env.REACT_APP_BACKEND_URL + "public/" + picture.image
              }
              caption={picture.title}
            />
          )}
          {/* {picture.type === "video" && picture.video !== "video" && (
            <div class="holdsTheIframe">
              <iframe width="100%" height="100%" src={picture.video}></iframe>
            </div>
          )} */}
           {picture.type === "video" && picture.video !== "video" && (
            <div class="holdsTheIframe" onClick={() => setVideoShow(true)}>
             <img src={videoDummy} onClick={() => setVideoShow(true)} className="galleryImageVideo"/>
            </div>
          )}
        </div>

        {(picture.status === "0" || picture.status === 0) && (
          <div class="ribbon-wrapper">
            <div class="ribbon bg-warning">Archived</div>
          </div>
        )}
      </div>
      <div className="card-header py-1 d-flex flex-column">
        <h5 className="card-title text-boldItalic pictureTitle">{picture.title}</h5>
      </div>

      <div className="card-footer text-center">
        <span
          title="Update Item"
          onClick={() => setShow(true)}
          className="btn btn-outline-secondary mx-2 "
        >
          <i className="fas fa-pencil-alt"></i>
        </span>
        <span
          title="Delete Item"
          onClick={() => {
            confirmAndRemovePicture(picture._id);
          }}
          className="btn btn-outline-secondary mx-2 "
        >
          <i className="fas fa-trash"></i>
        </span>

        {(picture.status === "1" || picture.status === 1) && (
          <span
            title="Archive Item"
            onClick={() => {
              confirmAndArchivePicture(picture._id);
            }}
            className="btn btn-outline-secondary  listingActionIcon mx-2 "
          >
            <i className="fas fa-ban"></i>
          </span>
        )}

        {(picture.status === "0" || picture.status === 0) && (
          <span
            title="Restore Item"
            onClick={() => {
              confirmAndRestorePicture(picture._id);
            }}
            className="btn btn-outline-secondary listingActionIcon mx-2 "
          >
            <i className="fas fa-undo"></i>
          </span>
        )}
      </div>
      {show && (
        <UpdateGalleryItem
          show={show}
          setShow={setShow}
          ItemId={picture._id}
          loadItem={loadItem}
        />
      )}
      {videoShow && (
        <VideoExpanded
          videoShow={videoShow}
          setVideoShow={setVideoShow}
          src={picture.video}
        />
      )}
    </div>
  );
};

export default GalleryPicture;
