import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayRichText from "../../../../UI/modules/fieldsDisplay/ModuleDisplayRichText";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { getFullName } from "../../../../../helpers/CommonHelper";
import ReactFancyBox from "react-fancybox";
const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getItems = () => {
    let items = [];
    if (item.email) {
      items = [...items, { text: "Email", value: item.email, type: "email" }];
    }
    if (item.company) {
      items = [
        ...items,
        { text: "Company", value: item.company, type: "text" },
      ];
    }
    if (item.designation) {
      items = [
        ...items,
        { text: "Designation", value: item.designation, type: "text" },
      ];
    }

    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      {item.image ? (
                        <Image
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            "public/" +
                            item.image
                          }
                          width="45px"
                          className="border rounded-circle"
                        />
                      ) : (
                        <Image
                          src={memberDummyImage}
                          width="42px"
                          className="border rounded-circle"
                        />
                      )}
                      {(item.status === "0" || item.status === 0) && (
                        <i className="fas fa-ban position-absolute ms-1  banned"></i>
                      )}
                    </div>
                 
                  </div>

                  <div className="d-flex flex-column align-items-center justify-content-center ms-1">
                    <span className="m-0 text-bold memberName text-red">
                      {getFullName(item)}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">
                        <ModuleDisplayDate value={item.date} />
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-1">
                  {(item.showOnHomePage === "1" ||
                    item.showOnHomePage === 1) && (
                    <span className="badge homePageShow me-1 text-white">
                      Showing on Home Page
                    </span>
                  )}
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span>
                  {console.log(item)}
                  <span class="badge badge-light me-1 text-dark">
                    Created Date :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDate value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
                <div className=" text-right ">
                <span class="badge badge-light me-1 text-dark">
                    Updated Date :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDate value={item.updatedAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              <StatsListing items={getItems()} />
              {item.message && (
                <>
                  <Alert
                    className="lightGray"
                    variant="secondary mb-0 pb-1 px-1 pt-0 messageWidth"
                  >
                    <span className="m-0 text-bold text-dark font-small">
                      Message:
                    </span>
                    <p className="font-small me-1 text-dark mb-0">
                      {item.message}
                    </p>
                  </Alert>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
