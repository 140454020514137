import React, { useState, useReducer, useEffect, useContext } from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import queryString from "query-string";
import moduleConfig from "./moduleConfig";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import useConfirm from "../../../HOC/custom/useConfirm";
import ListingFooter from "./partials/listing/ListingFooter";
import SearchArea from "./partials/listing/SearchArea";
import Item from "./partials/listing/Item";
import { listingReducer, listingInitialState } from "./reducers/listingReducer";
import { OverlayLoading } from "../../UI/loading/OverlayLoading";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
};

const customButtonsForItems = [
  // {
  //   label: "Change Password",
  //   className: "fas fa-key",
  //   url: "change-password"
  // }
];

const NewsEventsListing = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { confirm } = useConfirm();

  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState(
    queryString.parse(search)
  );
  if (appliedFilters.limit) {
    moduleConfig.perPageItems = appliedFilters.limit;
  }

  let history = useNavigate();

  const setFilter = (name, value) => {
    console.log(`setFilter called with ${name}, ${value}`);
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    if (value) {
      newAppliedFilters[name] = value;
    } else {
      delete newAppliedFilters[name];
    }

    if (name !== "page") {
      delete newAppliedFilters["page"];
    }

    if (name === "limit" || name === "page") {
      setIsCheck([]);
      setIsCheckAll(false);
    }

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };

  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`${moduleConfig.url}${search}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const resetFilters = () => {
    //console.log(`resetFilters called`)
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    moduleConfig.perPageItems = 10;
    delete newAppliedFilters["limit"];
    delete newAppliedFilters["status"];
    delete newAppliedFilters["showOnHomePage"];
    delete newAppliedFilters["source"];
    delete newAppliedFilters["q"];

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    //console.log(newAppliedFilters);
    setAppliedFilters(newAppliedFilters);
  };

  const performBulkAction = async (action) => {
    listingDispatch({
      type: "BULK_ACTION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/bulk-action`, {
        ids: isCheck,
        action,
      });
      setIsCheck([]);
      setIsCheckAll(false);
      loadItems();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Action "${action}" on selected items was performed successfully.`,
        type: "success",
      });
    } catch (error) {
      listingDispatch({
        type: "BULK_ACTION_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        listingDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    loadItems();
  }, [search]);

  useEffect(() => {
    setBreadcrumb([breadcrumbs.listing]);
  }, []);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    if (listingState.data.items && listingState.data.items.length) {
      setIsCheck(listingState.data.items.map((li) => li._id));
    }

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const confirmAndDisabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to disable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id);
    }
  };

  const confirmAndDelete = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to delete this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await deleteItem(id);
    }
  };
  const confirmAndEnabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to enable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id, true);
    }
  };
  const deleteItem = async (id) => {
    listingDispatch({
      type: "DELETE_REQUEST",
    });
    try {
      const res = await axios.delete(`${moduleConfig.url}/${id}`);
      loadItems();
      // listingDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} deleted successfully.`,
        type: "success",
      });
    } catch (error) {
      listingDispatch({
        type: "DELETE_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        listingDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };
  const changeStatus = async (id, undo = false) => {
    console.log("In block Item Function");
    listingDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/change-status`, {
        id,
        status: undo === true ? "1" : "0",
      });
      loadItems();
      // listingDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} ${
          undo ? "enabled" : "disabled"
        } successfully.`,
        type: "success",
      });
    } catch (error) {
      listingDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        listingDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const handleCheckBoxClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    setIsCheckAll(false);
  };

  return (
    <DashboardLayout>
      {listingState.isLoading && <OverlayLoading />}

      {listingState.status !== "fetching" && (
        <>
          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : (
            <>
              <ModuleHeader
                moduleTitle={moduleConfig.title}
                moduleUrl={moduleConfig.url}
              >
                {moduleConfig.buttons &&
                  moduleConfig.buttons.create === true && (
                    <Link
                      to={`/${moduleConfig.url}/create`}
                      className="btn btn-primary"
                    >
                      Create {moduleConfig.singleItemTitle}
                    </Link>
                  )}
              </ModuleHeader>

              <div className="listingPage">
                {listingState.data &&
                listingState.data.pagination &&
                listingState.data.pagination.totalItems > 0 ? (
                  <>
                    {/* Search Area */}
                    <SearchArea
                      moduleConfig={moduleConfig}
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      resetFilters={resetFilters}
                    />

                    {/* Listing */}
                    <section className="content listing ">
                      {listingState.error && (
                        <FormErrorMessage error={listingState.error} />
                      )}

                      {!listingState.data.items ||
                      listingState.data.items.length <= 0 ? (
                        <Alert variant="warning">
                          No records found with selected filters.{" "}
                          <Alert.Link href="#" onClick={() => resetFilters()}>
                            <i className="fas fa-times" /> Reset Filters
                          </Alert.Link>
                          .
                        </Alert>
                      ) : (
                        <>
                          {listingState.data.items &&
                            listingState.data.items.length && (
                              <>
                                <Row className="m-0 p-0 mt-2">
                                  <Col className="m-0 mb-1">
                                    <input
                                      className="selectAll"
                                      type="checkbox"
                                      name="selectAll"
                                      id="selectAll"
                                      onChange={handleSelectAll}
                                      checked={isCheckAll}
                                    />
                                    <label
                                      for="selectAll"
                                      className="m-0 p-0 ml-2"
                                    >
                                      Select All
                                    </label>
                                  </Col>
                                </Row>
                                {console.log(listingState.data.items)}
                                {listingState.data.items.map(
                                  (item, itemIndex) => {
                                    return (
                                      <Item
                                        moduleConfig={moduleConfig}
                                        item={item}
                                        handleCheckBoxClick={
                                          handleCheckBoxClick
                                        }
                                        isCheck={isCheck}
                                        confirmAndDelete={confirmAndDelete}
                                        confirmAndDisabled={confirmAndDisabled}
                                        confirmAndEnabled={confirmAndEnabled}
                                        customButtonsForItems={
                                          customButtonsForItems
                                        }
                                      />
                                    );
                                  }
                                )}
                              </>
                            )}
                        </>
                      )}
                    </section>

                    <ListingFooter
                      listingState={listingState}
                      moduleConfig={moduleConfig}
                      isCheck={isCheck}
                      performBulkAction={performBulkAction}
                      setFilter={setFilter}
                    />
                  </>
                ) : (
                  <Alert variant="warning">No records found.</Alert>
                )}
              </div>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default NewsEventsListing;
