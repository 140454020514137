import React, { useState, useContext, useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import { Store as toast } from "react-notifications-component";
import { BreadcrumbContext } from "../../../../../contexts/BreadcrumbContext";
import axios from "../../../../../config/axios";
import { toastNotification } from "../../../../../config/toastNotification";
import DashboardLayout from "../../../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";

import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import {
  detailsInitialState,
  detailsReducer,
} from "../../reducers/detailsReducer";
import {
  updateInitialState,
  updateReducer,
} from "../../reducers/updateReducer";
import moduleConfig from "../../moduleConfig";
import CMSFormRichText from "../../../../UI/forms/CMSFormRichText";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import CMSFormImage from "../../../../UI/forms/CMSFormImage";
import CMSFormFile from "../../../../UI/forms/CMSFormFile";
import { isEmpty } from "../../../../../helpers/CommonHelper";
import ModuleHeader from "../../../../UI/modules/views/partials/common/ModuleHeader";

const SaleableDetails = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [sitesState, sitesDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: "Fossils Saleable",
      url: `/fossils-saleable`,
    },
    details: {
      label: "Details",
      url: `/fossils-saleable/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `fossils/saleable-attributes/${id}`,
        data
      ); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      //navigate(`/fossils-saleable`);
      navigate(-1);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`fossils/saleable-attributes/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data);
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      let obj = {
        code: updateState.data.item.code ? updateState.data.item.code : "",
        exclusiveItem: updateState.data.item.exclusiveItem
          ? updateState.data.item.exclusiveItem
          : "",
        oldPrice: updateState.data.item.oldPrice
          ? updateState.data.item.oldPrice
          : null,
        discounted: updateState.data.item.oldPrice ? "1" : "0",
        price: updateState.data.item.price ? updateState.data.item.price : "",
        showOnHomePage: updateState.data.item.showOnHomePage
          ? updateState.data.item.showOnHomePage
          : "",
      };
      console.log(updateState.data.item);
      setFormInitialValues(obj);
      //};
    }
  }, [updateState.data]);
  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 2,
  };
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update Saleable Details`}
            moduleUrl={moduleConfig.url}
          >
            <Link to={`/fossils-saleable/update/${id}`} className="btn btn-primary" title={`Update Basic Fossil Details`}>
              <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
              <span className="text-white">Update Basic Details</span>
            </Link>
          </ModuleHeader>
          {console.log(formInitialValues)}
          {Object.keys(formInitialValues).length > 0 && (
            <section className="content">
              <Card>
                <Card.Body>
                  {updateState.isLoading && (
                    <div class="overlay position-absolute containerLoading">
                      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                  )}

                  <Formik
                    initialValues={formInitialValues}
                    validationSchema={yup.object({
                      exclusiveItem: yup.string(),
                      discounted: yup.string(),
                      code: yup.string().required("Required"),
                      price: yup.number().required("Required"),
                      oldPrice: yup
                        .string()
                        .nullable()
                        .when("discounted", {
                          is: (discounted) => discounted === "1",
                          then: yup.string().required("Required"),
                        }),
                      showOnHomePage: yup.string(),
                    })}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values, { resetForm }) => {
                      //onSubmit(values);

                      var newValue = values;
                      if (values.discounted === "0") {
                        newValue = { ...values, oldPrice: 0 };
                        console.log(newValue);
                        delete newValue.discounted;
                        onSubmit(newValue);
                      } else {
                        onSubmit(newValue);
                      }
                    }}
                  >
                    {(form) => (
                      <Form onSubmit={form.handleSubmit}>
                        {updateState.error && (
                          <FormErrorMessage error={updateState.error} />
                        )}
                        <CMSFormInput
                          name="code"
                          label="Item code"
                          required={true}
                          form={form}
                        />
                        <CMSFormInput
                          name="price"
                          label="Price"
                          required={true}
                          form={form}
                        />
                        <CMSFormSelect
                          name="discounted"
                          label="Discounted"
                          form={form}
                          options={[
                            { label: "Yes", value: "1" },
                            { label: "No", value: "0" },
                          ]}
                        />
                        {form.values.discounted === "1" && (
                          <CMSFormInput
                            name="oldPrice"
                            label="OldPrice"
                            required={
                              form.values.discounted === "1" ? true : false
                            }
                            form={form}
                          />
                        )}

                        <CMSFormSelect
                          name="exclusiveItem"
                          label="Exclusive Item"
                          form={form}
                          options={[
                            { label: "Yes", value: "1" },
                            { label: "No", value: "0" },
                          ]}
                        />

                        <CMSFormSelect
                          name="showOnHomePage"
                          label="Show On Home Page"
                          form={form}
                          options={[
                            { label: "Yes", value: "1" },
                            { label: "No", value: "0" },
                          ]}
                        />

                        <button
                          type="submit"
                          onClick={() => form.handleSubmit}
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </section>
          )}
        </>
      )}
    </DashboardLayout>
  );
};
export default SaleableDetails;
