import React, { useReducer, useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import moduleConfig from "../../moduleConfig";
import axios from "../../../../../config/axios";
import {
  addGalleryInitialState,
  addGalleryItemReducer,
} from "../../reducers/addGalleryItemReducer";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import CMSFormImageWithCrop from "../../../../UI/forms/CMSFormImageWithCrop";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import CMSFormImage from "../../../../UI/forms/CMSFormImage";

const AddPagePicture = ({ show, setShow, id, loadItem }) => {
  const [state, dispatch] = useReducer(
    addGalleryItemReducer,
    addGalleryInitialState
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: {},
    });
  }, [show]);

  const onSubmit = async (data, resetForm) => {
    dispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`page-gallery/add-item/${id}`, data); //

      resetForm();
      handleClose();
      dispatch({
        type: "FORM_INITIAL_STAGE",
      });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Media added successfully.`,
        type: "success",
      });
      loadItem();
    } catch (error) {
      dispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        dispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  return (
    <Formik
      initialValues={{
        title: "",
        type: "",
        image: "",
        video: "",
        sortOrder: "99",
        status: "1",
      }}
      validationSchema={yup
        .object({
          title: yup
            .string()
            .required("Required")
            .max(70, "Caption should contain at most 70 characters"),
          type: yup.string().required("Required"),
          sortOrder: yup.string().required("Required"),
          status: yup.string().required("Required"),
        })
        .shape(
          {
            image: yup.mixed().when("video", {
              is: "",
              then: yup
                .mixed()
                .required("Please upload image.")
                .imageTypeCheck(imageRequirement.allowedExtensions)
                .imageSizeCheck(imageRequirement.size)
                .imageDimensionCheck(imageRequirement.dimension),
            }),
            video: yup
              .string()
              .ensure()
              .when("image", {
                is: (image) => !image,
                then: yup.string().url().required("Required"),
              }),
          },
          [["image", "video"]]
        )}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        ///onSubmit(values, resetForm)

        const formData = new FormData();
        for (let name in values) {
          if (Array.isArray(values[name])) {
            formData.append(name, JSON.stringify(values[name]));
          } else {
            formData.append(name, values[name]);
          }
        }
        console.log(formData);
        onSubmit(formData, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose} size="lg">
          {console.log(form.errors)}
          {state.isLoading && (
            <div class="overlay ">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {state.error && state.status === "fetchFailed" ? (
            <BlockErrorMessage error={state.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Media in Page Gallery </Modal.Title>
                </Modal.Header>
                {state.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Picture added to gallery successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>
                      {state.error && <FormErrorMessage error={state.error} />}
                      <CMSFormInput
                        name="title"
                        label="Caption"
                        form={form}
                        required={true}
                      />
                      <CMSFormSelect
                        name="type"
                        label="Type"
                        required={true}
                        form={form}
                        options={[
                          { value: "image", label: "Image" },
                          {
                            value: "video",
                            label: "Video",
                          },
                        ]}
                      />
                      {form.values.type === "video" && (
                        <CMSFormInput
                          name="video"
                          label="Video URL"
                          info="Recommended to add iframe URL for video"
                          form={form}
                          // helpMessage={{ message: "Please add iframe URL for video" }}
                          required={true}
                        />
                      )}
                      {form.values.type === "image" && (
                        <CMSFormImageWithCrop
                          label="Image"
                          name="image"
                          info="Recommended dimensions 500*500"
                          required={true}
                          form={form}
                          aspect={4 / 3}
                          outputSize={imageRequirement.dimension}
                        />
                      )}
                      <CMSFormInput
                        name="sortOrder"
                        label="Sort Order"
                        form={form}
                        required={true}
                      />
                      <CMSFormSelect
                        name="status"
                        label="Status"
                        required={true}
                        form={form}
                        options={[
                          { value: "1", label: "Active" },
                          {
                            value: "0",
                            label: "Archived",
                          },
                        ]}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default AddPagePicture;
