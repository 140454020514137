import React, { useState, useReducer,useEffect } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import {
  formInitialState,
  formReducer,
} from "../../../../../reducers/formReducer";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormTextArea from "../../../../UI/forms/CMSFormTextArea";
import { Alert, Button } from "react-bootstrap";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import axios from "../../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import CMSFormCheckBoxes from "../../../../UI/forms/CMSFormCheckBoxes";
import { getFullName } from "../../../../../helpers/CommonHelper";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import { updateInitialState, updateReducer } from "../../reducers/updateReducer";


const AddPaymentRequestModal = ({

  show,
  setShow,
  loadItems,
  updateStateValue,

}) => {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [linkId, setLinkId] = useState("")
  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`payment-requests`, data);
      formDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      setLinkId(res.data.id)
      console.log(res.data);
   
      resetForm();
      
    } catch (error) {
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
     
      setTimeout(() => {
        formDispatch({
          type: "FORM_REMOVE_ERROR",
          payload: '',
        });
      }, 5000);
    }
  };
  const onSendLink = async (data) => {


    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.get(`payment-requests/send-email/${data}`); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      if (res.data.emailSent === true) {
        console.log(res.data)
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `Email sent successfully.`,
          type: "success",
        });
        loadItems();
        handleClose();
      }
      if (res.data.emailSent === false) {
        toast.addNotification({
          ...toastNotification,
          title: "Error!",
          message: `Something went wrong!Try again later.`,
          type: "danger",
        });
      }
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);
      toast.addNotification({
        ...toastNotification,
        title: "Error!",
        message: `Something went wrong!Try again later.`,
        type: "danger",
      });
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      // setShowAddPaymentRequestModal(false);
       //loadItems();
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const getValidationSchema = () => {
    return yup.object({
      firstName: yup.string(),
      lastName: yup.string(),
      phone: yup.string(),
      country: yup.string(),
      email: yup.string().email().required("Required"),
      adminComments: yup.string().max(200),
      amount: yup
        .number("Amount should be a number")
        .transform((cv) => (isNaN(cv) ? undefined : cv))
        .positive()
        .integer()
        .required("Amount is required."),
    });
  };

  const getInitialValues = () => {
    let returnObj = {

      firstName: "",
      lastName: "",
      phone: "",
      country: "",
      email: "",
      adminComments: "",
      amount: "",
    };

    return returnObj;
  };
  useEffect(() => {
    updateDispatch({
      type: "FETCH_SUCCESS",
      payload: '',
    });
  }, [])

  return (
    <>

      {formState.isFormSubmitted ? <Modal show={show} onHide={() => { handleClose(); loadItems(); }}>
        {updateState.isLoading && (
          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Send payment link to user</Modal.Title>
        </Modal.Header>
      
        <Modal.Body className="px-3 py-4">
        {updateState.status === 'submitFailed' && (
          <FormErrorMessage error={updateStateValue.formSubmissionError} />
        )}
          <p>Payment link has been created. <strong className="text-bold">Do you want to send email now?</strong></p>
          <div className="d-flex align-items-center justify-content-end mt-4">
            <Button variant="secondary" className="me-3" onClick={() => { handleClose(); loadItems(); }}>
              May be later
            </Button>
            <Button onClick={() => onSendLink(linkId)}>
              Send Now
            </Button>
          </div>

        </Modal.Body> </Modal> :
        <Modal Modal show={show} onHide={handleClose}>
          {formState.isFormSubmitting && (
            <div class="overlay">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}
          <Modal.Header closeButton>
            <Modal.Title>Generate Payment Link</Modal.Title>
          </Modal.Header>
          <Alert className="m-1 py-2 px-4" variant="warning">
            <small>
             <b>Please Note:- </b> This form is only to generate the payment link, after generating the payment link you can send the email separately.
            </small>

          </Alert>
          <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values, { resetForm }) => {
              console.log(values);
              onSubmit(values, resetForm);
            }}
          >
            {(form) => (
              <>

                {/* {(formState.isFormSubmitting) && <ContainerLoading />} */}
                <Form onSubmit={form.handleSubmit}>


                  <Modal.Body>
                    {formState.hasSubmissionError && (
                      <FormErrorMessage error={formState.formSubmissionError} />
                    )}


                    <CMSFormInput
                      name="firstName"
                      label="Full Name"
                      form={form}
                      type="text"
                    />

                    <CMSFormInput
                      name="email"
                      label="Email"
                      required={true}
                      form={form}
                      type="email"
                    />

                    <CMSFormInput
                      name="amount"
                      label="Amount"
                      required={true}
                      form={form}
                      type="number"
                    />

                    <CMSFormTextArea
                      name="adminComments"
                      label="Admin Comments"
                      form={form}
                      required={false}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <button
                      type="submit"
                      onClick={() => form.handleSubmit}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            )}
          </Formik>
        </Modal>

      }
    </>
  );
};

export default AddPaymentRequestModal;
