import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div>

            <div className="ms-1 me-2">
              <i className="fas fa-folder-open mt-1 position-relative cartIcon">
                {(item.status === "0" || item.status === 0) && (
                  <i className="fas fa-ban position-absolute banned3"></i>
                )}
              </i>
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName text-red">
                {item.title}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">{item.date}</span>
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools endColumn pt-1">
          <div>
            <span className="badge badge-light me-1 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Active"
                  falseValue="Archived"
                />{" "}
              </span>
            </span>
            <span class="badge badge-light me-1 text-dark">
              Created Date :{" "}
              <span class="text-secondary">
                <ModuleDisplayDate value={item.createdAt} />{" "}
              </span>
            </span>
          </div>

          <div className=" text-right me-xl-2  mt-1 mb-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title={`Archive ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndBlock(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Restore ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndUnblock(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
            <span
              title={`Delete ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span>
          </div>
        </div>
      </Card.Header>

      <Card.Body className="p-1">
        <StatsListing
          heading="Stats"
          clickable={true}
          items={[
            {
              title: "Pictures Count",
              value: item.stats.picturesCount,
              url: `/${moduleConfig.url}/${item._id}`,
            },
            {
              title: "Videos Count",
              value: item.stats.videosCount,
              url: `/${moduleConfig.url}/${item._id}`,
            },
          ]}
        />
      </Card.Body>
    </Card>
  );
};

export default Item;
