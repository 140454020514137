import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CMSFormDate = (props) => {
  const {
    name,
    label,
    form,
    value,
    minDate,
    maxDate,
    disabled,
    required,
    helpMessage,
    ...rest
  } = props;
  let startYear = minDate ? minDate.getFullYear() : new Date().getFullYear();
  let endYear = maxDate ? maxDate.getFullYear() : startYear + 50;
  //console.log(form.touched, form.errors);

  //console.log(minDate, maxDate)
  ///console.log(startYear, maxDate.getFullYear())
  const [currentDate, setCurrentDate] = useState(value);
  const years = range(startYear, endYear);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function range(startYear, endYear) {
    let years = [];
    for (let i = startYear; i <= endYear; i++) years.push(i);

    return years;
  }

  const handleOnBlur = ({ target: { value } }) => {
    //console.log(value)
    const date = new Date(value);
    if (isValidDate(date)) {
      //console.log(date, "is valid date");
    } else {
      //console.log(date, "is not a valid date");
    }
  };

  const handleOnChange = async (date) => {
    //console.log(date)
    if (isValidDate(date)) {
      //console.log(date, "is valid date");
      let stringFormatDate = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
      await form.setFieldValue(name, stringFormatDate);
      setCurrentDate(date);
      form.setFieldTouched(name, true);
    } else {
      //console.log(date, "is not a valid date");
      await form.setFieldValue(name, "");
      setCurrentDate("");
      form.setFieldTouched(name, true);
    }
    // form.setFieldValue(name, date)
    //
  };

  function isValidDate(date) {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      if (!isNaN(date.getTime())) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  return (
    <div className="form-group ">
      {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${form.touched[name] && form.errors[name]
                  ? "is-invalidText"
                  : ""
                  }`}
              >
                {" "}
                {label}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${form.touched[name] && form.errors[name] ? "is-invalidText" : ""
                }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}

      <div
        className={`form-control ${form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
      >
        <DatePicker
          className="customDatePicker border-0"
          selected={currentDate}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat="dd MMMM yyyy"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          disabled={disabled}
          isClearable={!disabled ? (currentDate ? true : false) : false}
          closeOnScroll={false}
          {...rest}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
        />
      </div>

      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormDate;
