import React from "react";
import { Badge, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModuleDisplayBoolean from "../../modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayEmail from "../../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../../modules/fieldsDisplay/ModuleDisplayPhone";
import ModuleDisplayURL from "../../modules/fieldsDisplay/ModuleDisplayURL";
export default function StatsListing(props) {
  const { heading, items, clickable, className, border, style, highlight } =
    props;
  const colors = ["teal", "pink", "info", "green", "orange", "yellow", "dark"];
  return (
    <div className={`mb-0 details  ${className ? "" : "details"} ${border}`}>
      {heading && (
        <span class="badge headerText badge-light bgCream text-dark  p-2  font-small d-flex">
          {heading}
        </span>
      )}
      {clickable ? (
        <>
          {items.map((item, index) => {
            return (
              <>
                <Link
                  title="Please click to view"
                  to={item.url}
                  class="badge badge-light me-1 ms-1 mt-1 text-dark text-decoration-none p-2 font-small"
                >
                  {item.title} :&nbsp;
                  {item.type === "text" ? (
                    <span class="text-secondary font-small ms-2">
                      {item.value}
                    </span>
                  ) : (
                    <Badge bg={colors[index]} className="ms-2" pill>
                      {item.value}
                    </Badge>
                  )}
                </Link>
              </>
            );
          })}
        </>
      ) : (
        items.map((item, index) => {
          return (
            <>
              {item.hobby ? (
                <Badge
                  bg="light"
                  className=" ms-1 text-dark mt-1 mb-1 p-2 font-small d-flex cursor-normal"
                >
                  <span class="text-secondary font-small">{item.hobby}</span>
                </Badge>
              ) : (
                <Badge
                  bg={item.highlight === true ? "yellow" : "light"}
                  className=" me-1 text-dark mt-1 mb-1 p-2 font-small d-flex cursor-normal"
                >
                  {item.text}
                  {item.text ? <>{"\t"}:</> : null}

                  {item.type === "boolean" ? (
                    <span class="text-secondary font-small ms-2 cursor-normal">
                      <ModuleDisplayBoolean value={item.value} />
                    </span>
                  ) : item.type === "text" ? (
                    <span class="text-secondary font-small ms-2 cursor-normal">
                      {item.value}
                    </span>
                  ) : item.type === "email" ? (
                    <span class="text-secondary font-small ms-2 cursor-normal">
                      <ModuleDisplayEmail value={item.value} />
                    </span>
                  ) : item.type === "phone" ? (
                    <span class="text-secondary font-small ms-2 cursor-normal">
                      <ModuleDisplayPhone value={item.value} />
                    </span>
                  ) : item.type === "link" ? (
                    <span class="text-secondary font-small ms-2 cursor-normal">
                      <ModuleDisplayURL
                        value={item.value}
                        download={item.download ? true : false}
                        title={item.title}
                      />
                    </span>
                  ) : (
                    <Badge
                      bg={colors[index]}
                      className="ms-2 cursor-normal"
                      pill
                    >
                      {item.value}
                    </Badge>
                  )}
                </Badge>
              )}
            </>
          );
        })
      )}
    </div>
  );
}
