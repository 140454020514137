import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { getFullName } from "../../../../../helpers/CommonHelper";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getCart = () => {
    let items = [];

    if (item.hasCart) {
      items = [
        ...items,
        { title: "", value: "View Cart", url: `/carts/${item.cartId}` },
      ];
    }

    return items;
  };
  const getBuyer = () => {
    let items = [];
    if (item.email) {
      items = [...items, { text: "Email", value: item.email, type: "email" }];
    }
    if (item.phone) {
      items = [
        ...items,
        {
          text: "Phone",
          value: item.phone,
          type: "phone",
        },
      ];
    }
    if (item.country) {
      items = [
        ...items,
        {
          text: "country",
          value: item.country,
          type: "text",
        },
      ];
    }
    return items;
  };

  return (
    <Card className="item mb-2 ">
      {console.log(item)}
      <Card.Header className="ps-1 pe-2 py-0 ">
        <div className="card-tools endColumn mb-1 pt-1">
          <div>
            {(item.hasCart === "1" || item.hasCart === 1) && (
              <Link
                to={`/carts/${item.cartId}`}
                className="pt-2 listingActionIcon"
                title={`View cart`}
              >
                <span className="badge red me-1 text-white">Has Cart</span>
              </Link>
            )}
            {item.paymentMethod && (
              <span className="badge badge-success me-1 text-white">
                Online Payment Selected
              </span>
            )}

            {item.createdAt && (
              <span className="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span className="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            {(item.hasCart === "1" || item.hasCart === 1) && (
              <Link
                to={`/carts/${item.cartId}`}
                className="btn btn-outline-secondary  listingActionIcon"
                title={`View cart`}
              >
                <i className="fas fa-shopping-cart"></i>
              </Link>
            )}
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>
            {/* <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disabled ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enabled ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
            <span
              title={`Enabled ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span> */}
          </div>
        </div>
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            <div className="ms-1 mt-1 me-2">
              <i className="fa fa-user pt-1 position-relative cartIcon "></i>
            </div>

            <div className="d-flex  align-items-center justify-content-center ms-1">
              {item.firstName && (
                <span className="ms-0 me-2 mt-1 text-bold memberName text-red ">
                  {" "}
                  {item.firstName} {item.lastName}
                </span>
              )}
              <span className=" mt-1 text-bold text-green">
                {" "}
                ({item.ordersCount}{" "}
                {item.ordersCount === 1 ? "Order" : "Orders"})
              </span>
            </div>
          </div>
        </Card.Title>
      </Card.Header>
      <Card.Body className="px-1 py-0">
        <Row>
          <Col className="mt-1 mb-1 ">
            {/* <StatsListing heading="Cart" clickable items={getCart()} /> */}
            <StatsListing items={getBuyer()} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
