import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];

    if (item.mobileTitle) {
      items = [
        ...items,
        {
          text: "Title for Mobile View ",
          value: item.mobileTitle,
          type: "text",
        },
      ];
    }


    if (item.link) {
      items = [
        ...items,
        {
          text: "Link",
          value: item.link,
          type: "link",
          title: "Please click to view",
        },
      ];
    }
    // if (item.linkText) {
    //   items = [
    //     ...items,
    //     { text: "Link Text", value: item.linkText, type: "text" },
    //   ];
    // }
    if (item.linkTitle) {
      items = [
        ...items,
        { text: "Link Title", value: item.linkTitle, type: "text" },
      ];
    }

    // if (item.image) {
    //   items = [
    //     ...items,
    //     { text: "Image", value: process.env.REACT_APP_BACKEND_URL + "public/" + item.image, type: "link", download: true,title:"Please click to download" },
    //   ];
    // }
    // if (item.video) {
    //   items = [
    //     ...items,
    //     { text: "Video", value:process.env.REACT_APP_BACKEND_URL + "public/" + item.video, type: "link", download: true,title:"Please click to download" },
    //   ];
    // }



    return items;
  };
  return (
    <Card className="item mb-2 borderCream">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing mt-1">
            <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div>

            <div className="ms-1 me-2">
              {console.log(item)}
              {item.type === "video" ? (
                <i className="fas fa-video pt-1 position-relative cartIcon ">
                  {(item.status === "0" || item.status === 0) && (
                    <i className="fas fa-ban position-absolute ms-1 mb-2 banned3"></i>
                  )}
                </i>
              ) : (
                <i className="fas fa-image pt-1 position-relative cartIcon ">
                  {(item.status === "0" || item.status === 0) && (
                    <i className="fas fa-ban position-absolute ms-1 mb-2 banned3"></i>
                  )}
                </i>
              )}
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName text-red">
                {item.title}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8">
                <span className="membershipId">
                  {item.type === "image"
                    ? "Image"
                    : item.type === "video"
                      ? "Video"
                      : null}
                </span>
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools endColumn mb-1 pt-1">
          <div>
            {item.relevant === "1" && (
              <span className="badge teal me-1 ">Relevant</span>
            )}
            <span className="badge badge-light me-1 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Enabled"
                  falseValue="Disabled"
                />{" "}
              </span>
            </span>
            {item.createdAt && (
              <span class="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDate value={item.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {/* {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enable ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )} */}
            <span
              title={`Delete ${moduleConfig.singleItemTitle}`}
              onClick={() => confirmAndDelete(item._id)}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-trash"></i>
            </span>
          </div>
        </div>
      </Card.Header>
      {getItems().length > 0 && (
        <Card.Body className="px-1 py-0">
          <Row>
            <Col className="mt-1 mb-1 ">
              <StatsListing items={getItems()} />
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default Item;
